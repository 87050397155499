import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  '/sso/login/*',
  '/admin/index',
  '/index',
  '/auth-redirect',
  // '/front/home',
  // '/CheckCertificate',
  // '/CertificateVerification',
  // '/front/course-selection',
] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    // determine whether the user has obtained his permission roles through getInfo
    const hasRoles = store.getters.roles && store.getters.roles.length > 0
    if (hasRoles) {
      next()
    } else {
      try {
        // get user info
        // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
        const userRightsList = await store.dispatch('user/getInfo')

        // generate accessible routes map based on roles
        const accessRoutes = await store.dispatch('permission/generateRoutes', userRightsList)

        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // hack method to ensure that addRoutes is complete
        // set the replace: true, so the navigation will not leave a history record
        next({ ...to, replace: true })
      } catch (error) {
        console.log(error)
        // remove token and go to login page to re-login
        await store.dispatch('user/resetToken')
        Message.error(error || 'Has Error')
        next(`/index?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } else {
    /* has no token*/
    let _isWhiteSite = false
    const _checkUrls = to.path.split('/')
    whiteList.forEach(item => {
      const _paths = item.split('/')
      if (!_isWhiteSite && _paths.length === _checkUrls.length) {
        _isWhiteSite = true
        for (let i = 0; i < _paths.length; i++) {
          if (_paths[i] !== '*' && _paths[i] !== _checkUrls[i]) {
            _isWhiteSite = false
            break
          }
        }
      }
    })
    if (_isWhiteSite) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

import Layout from '@/layout'
// 系统菜单内首页
const adminIndexRouter = {
  path: '/admin/home',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '',
    icon: null,
  },
  children: [

    {
      path: '',
      component: () => import('@/views/admin/home'),
      name: 'adminIndex',
      meta: {
        title: '首页',
        icon: null,
      },
    },
  ],
}

export default adminIndexRouter

<template>
  <div class="text-center footer">Copyright © 2021 Zhongde Inc. 保留所有权利。鄂ICP备2021018676号-1</div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
.footer {
  font-size: 26px;
  color: #5B9ECF;
  padding: 70px 0 60px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "header-box flex flex-row align-center justify-between",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("详情")]),
          _c("div", { staticClass: "mouse-style", on: { click: _vm.goBack } }, [
            _vm._v("返回"),
          ]),
        ]
      ),
      _c("myDetail", {
        attrs: {
          "item-width": _vm.itemWidth,
          list: _vm.list,
          "list-config": _vm.listConfig,
          "list-handles": _vm.listHandles,
          that: _vm.that,
        },
      }),
      _c(
        "div",
        { staticClass: "mt12 ml12 mr12 bgc-white br2" },
        [
          _vm._m(0),
          _c("HSearch", {
            ref: "searchForm",
            attrs: {
              "search-data": _vm.searchData,
              "search-form": _vm.searchForm,
              "search-handle": _vm.searchHandle,
              "search-rules": _vm.searchRules,
              that: _vm.that,
              "label-width": "80px",
              size: "medium",
            },
          }),
          _vm.userInfo.userType === _vm.USER_TYPE.organization.val &&
          _vm.list.auditStatus === null
            ? _c(
                "div",
                {
                  staticClass:
                    "flex flex-row justify-end c-0067C0 pr30 fs14 pointer",
                  on: { click: _vm.allotStuClick },
                },
                [_vm._v("添加考生 ")]
              )
            : _vm._e(),
          _c("HTable", {
            attrs: {
              "is-handle": true,
              "is-index": true,
              loading: _vm.tableLoading,
              "table-cols": _vm.tableCols,
              "table-data": _vm.tableData,
              "table-page": _vm.tablePage,
              that: _vm.that,
            },
          }),
        ],
        1
      ),
      _c("OrgLearningDialog", {
        attrs: {
          "flag-visible": _vm.studyDetailsDialogShow,
          "is-show-fun": _vm.isShowFun,
          "stu-info": _vm.stuInfo,
        },
        on: {
          "update:flagVisible": function ($event) {
            _vm.studyDetailsDialogShow = $event
          },
          "update:flag-visible": function ($event) {
            _vm.studyDetailsDialogShow = $event
          },
        },
      }),
      _c("AllotStuDialog", {
        attrs: {
          "flag-visible": _vm.allotStuDialogShow,
          "plan-info": _vm.list,
        },
        on: {
          "update:flagVisible": function ($event) {
            _vm.allotStuDialogShow = $event
          },
          "update:flag-visible": function ($event) {
            _vm.allotStuDialogShow = $event
          },
        },
      }),
      _c("AddPlanDialog", {
        attrs: {
          "flag-visible": _vm.dialogShow,
          "plan-info": _vm.planInfo,
          title: _vm.Title,
        },
        on: {
          "update:flagVisible": function ($event) {
            _vm.dialogShow = $event
          },
          "update:flag-visible": function ($event) {
            _vm.dialogShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-row justify-between align-center pt12 pb12 pl16 pr16 bb-d9",
      },
      [_c("div", { staticClass: "fs20 c-181818" }, [_vm._v("考生管理")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
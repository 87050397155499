import request from '@/utils/request'

// 获取七牛云token
export function getUploadToken(params) {
  return request({
    url: '/qiniu/getUploadFileToken',
    method: 'get',
    params,
  })
}

// 获取人脸比对token
export function getFaceCompareToken(params) {
  return request({
    url: '/education-work-biz/qiniu/faceCompareToken',
    method: 'get',
    params,
  })
}

/**
 * 获取七牛人脸比对效验值
 * @param {data_uri_a:'',data_uri_b:''}
 * @returns {AxiosPromise}
 */
export function qiniuFaceCompare(data) {
  return request({
    url: '/qiniu/faceCompare',
    method: 'post',
    data,
  })
}

<template>
  <div>
    <el-upload
      ref="upload"
      class="avatar-uploader"
      action="https://upload-z2.qiniup.com"
      :data="uploadData"
      :show-file-list="true"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-remove="remove"
      :on-exceed="handleExceed"
      :on-error="uploadError"
      :limit="limit"
      :file-list="fileList"
      :disabled="disabled"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="c-7b7b7b">只能上传{{ limit }}个文件，且单个不超过150mb，多文件时建议上传压缩包！</div>
    </el-upload>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getUploadToken } from '@/api/qiniu'
import { defaultImgPath } from '@/const/config'

export default {
  name: '',
  components: {},
  props: { // 接收到的数据属于“静态数据”，是单向数据，不能反向修改
    fileArr: {
      type: [Array, Object, String],
      default: null,
    },
    limit: {
      type: [String, Number],
      default: 5,
    },
    requestFlag: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: '',
    },
    // 是否允许查看大图
    isPriview: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: [Array, Object, String],
      default: null,
    },
    filePath: {
      type: String,
      default: defaultImgPath,
    },
  },
  data() {
    return {
      previewVisible: false,
      fileList: [],
      uploadData: {},
    }
  },
  computed: {
    ...mapGetters([
      'upload_token',
    ]),
  },
  watch: {
    fileArr: {
      handler: function(newValue, oldValue) {
        if (newValue) {
          this.fileList = newValue
        } else {
          this.fileList = []
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.requestFlag && this.getQiniuToken()
  },
  methods: {
    ...mapMutations('common', ['SET_UPLOAD_TOKEN']),
    getQiniuToken() {
      if (!this.upload_token) {
        getUploadToken().then(response => {
          const { token } = response.data
          this.SET_UPLOAD_TOKEN(token)
        })
      }
    },
    uploadError() {
      this.$message.info('上传失败，请重试')
      getUploadToken().then(response => {
        const { token } = response.data
        this.SET_UPLOAD_TOKEN(token)
      })
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    handleSuccess(res, file) {
      this.fileList.push({ name: file.name, url: `${process.env.VUE_APP_IMG}${res.key}` })
      this.$emit('update:value', this.fileList)
      this.$emit('validateField', this.field)
    },
    // 上传之前
    beforeUpload(file) {
      // 当前不限制类型
      // const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      // const isPNG = file.type === 'image/png'
      // if (!isJPGorPNG && !isPNG) {
      //   this.$message.error('上传图片需是 JPG或PNG 格式!')
      // }
      const isLt150M = file.size / 1024 / 1024 < 150
      if (!isLt150M) {
        this.$message.error('上传头像图片大小不能超过 150MB!')
      }
      const fileName = file.name
      this.uploadData.token = this.upload_token
      this.uploadData.key = (this.filePath + new Date().getTime() + '-' + fileName)
      this.uploadData.data = file
      return isLt150M
    },
    remove(file, fileList) {
      this.fileList = fileList
      this.$emit('update:value', this.fileList)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    clearFiles() {
      this.$refs.upload.clearFiles()
    },
    imagePreview() {
      this.previewVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
///deep/ .el-upload-list {
//  position: absolute;
//  top: 0
//}
//
///deep/ .el-upload-list__item:first-child {
//  margin-top: 0px;
//  margin-bottom: 10px;
//}
/deep/ .avatar-uploader .el-upload {
  border: none;
}

/deep/ .avatar-uploader {
  min-height: 120px;
  height: 100%;
}
</style>

<template>
  <Chart
    :id="id"
    :height="height"
    :options="options"
    :width="width"
  />
</template>

<script>
import Chart from '@/components/Charts/common'

export default {
  name: 'BarChart',
  components: {
    Chart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cdata: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: '500px',
    },
    height: {
      type: String,
      default: '300px',
    },
  },
  data() {
    return {
      options: {},
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          color: newData.color,
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          grid: {
            top: 18,
            left: 0,
            right: 0,
            bottom: 0,
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: newData.xAxisData,
              axisTick: {
                alignWithLabel: true,
              },
              splitLine: {
                show: false, // 显示横轴的线
                lineStyle: {
                  color: ['#fff'],
                  type: 'dashed',
                },
              },
              axisLine: {
                lineStyle: {
                  color: ['#7B7B7B'],
                  type: 'dashed',
                },
              },
              axisLabel: {
                textStyle: {
                  color: ['#b2b2b2'],
                },
                interval: 0,
                formatter: function(val) {
                  const value = val.split(' ')
                  return [`{a|${value[0]}}`, `{b|${value[1]}}`].join(' ')
                },
                rich: {
                  a: {
                    color: '#b2b2b2',
                  },
                  b: {
                    color: '#3779FD',
                    fontWeight: 'bold',
                  },
                },
              },
            },
          ],
          yAxis: [
            {
              axisLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              minInterval: 1,
              splitLine: {
                show: true, // 显示横轴的线
                lineStyle: {
                  color: ['#7B7B7B'],
                  type: 'dashed',
                },
              },
              type: 'value',
            },
          ],
          series: [
            {
              type: 'bar',
              barWidth: 32,
              barMaxHeight: '80%',
              data: newData.seriesData,
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(255, 255, 255, 0.1)',
              },
              label: {
                fontWeight: 'bold',
                color: '#fff',
                show: true,
                fontSize: 12,
                position: 'top',
                formatter: '{c}人',
              },
            },
          ],
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style>
.dawda {
  color: red;
}
</style>

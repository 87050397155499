<template>
  <div class="app-container">
    <div class="header-box flex flex-row align-center justify-between">
      <div class="title">详情</div>
      <div class="mouse-style" @click="goBack">返回</div>
    </div>
    <myDetail :item-width="itemWidth" :list="list" :list-config="listConfig" :list-handles="listHandles" :that="that" />
    <div class="mt12 ml12 mr12 bgc-white br2">
      <div class="flex flex-row justify-between align-center pt12 pb12 pl16 pr16 bb-d9">
        <div class="fs20 c-181818">考生管理</div>
      </div>
      <HSearch
        ref="searchForm"
        :search-data="searchData"
        :search-form="searchForm"
        :search-handle="searchHandle"
        :search-rules="searchRules"
        :that="that"
        label-width="80px"
        size="medium"
      />
      <HTable
        :is-handle="true"
        :is-index="true"
        :is-selection="true"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-page="tablePage"
        :that="that"
      />
    </div>
  </div>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
import { mapState } from 'vuex'
import myDetail from '@/components/MyDetail'
import Tools from '@/utils/tools'
import { EXAM_RESULT_TYPE, ORG_USER_TYPE, USER_TYPE } from '@/const/sys'
import { getUserInfo } from '@/utils/auth'
import { examListDetail } from '@/api/company/examPlan'
import { getExamResultStuList } from '@/api/company/examResult'

export default {
  name: 'ExamResultsDetails',
  components: {
    myDetail,
    HSearch,
    HTable,
    // stuRecordDialog,
  },
  data() {
    const _examResultType = Tools.mapToSelctList(EXAM_RESULT_TYPE)
    return {
      USER_TYPE,
      ORG_USER_TYPE,
      that: this,
      list: {},
      stuObj: {},
      disable: true,
      isShowStuEdit: false,
      itemWidth: { width: '31%', marginBottom: '1%' },
      listHandles: [],
      listConfig: [
        {
          prop: 'examSerial',
          placeholder: '考试计划编号',
        },
        {
          prop: 'examStartTime',
          placeholder: '考试开始时间',
        },
        {
          prop: 'examEndTime',
          placeholder: '考试结束时间',
        },
        {
          prop: 'examSessionAddr',
          placeholder: '考试地点',
        },
        {
          prop: 'paperTitle',
          placeholder: '考试试卷',
        },
      ],
      tableLoading: false,
      searchData: {
        examResult: '',
      },
      searchForm: [
        {
          type: 'select',
          label: '',
          prop: 'examResult',
          placeholder: '请选择考试结果',
          options: _examResultType,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '身份证号',
          prop: 'idCard',
        },
        {
          label: '手机号码',
          prop: 'phone',
        },
        {
          label: '分数',
          prop: 'examScore',
        },
        {
          label: '考试结果',
          prop: 'examResult',
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
      userInfo: {},
    }
  },
  computed: {
    ...mapState('plan', {
      examSessionsId: state => state.examSessionsId,
      examSessionAddr: state => state.examSessionAddr,
    }),
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.userInfo = getUserInfo()
    this.getPlanData()
    this.getTableData()
  },
  methods: {
    async getPlanData() {
      const { data } = await examListDetail(this.examSessionsId)
      this.list = data
    },
    fomatCategory(row) {
      const _names = Tools.listStrToName(row.categoryChildId, this.categoryAllMap)
      return _names.toString()
    },
    // 获取表格数据
    async getTableData() {
      this.tableLoading = true
      const _searchData = JSON.parse(JSON.stringify(this.searchData))
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _searchData,
      }
      query.bizCondition.examSessionsId = this.examSessionsId
      const { data } = await getExamResultStuList(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 搜索按钮
    searchFormClick() {
      this.getTableData()
    },
    // 重置按钮
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 点击返回 返回计划列表页
    goBack() {
      this.$router.go(-1)
      // this.$emit('goListPage')
    },
  },
}
</script>
<style scoped>
/deep/ .ces-handle {
  margin: 0;
  padding: 0;
}
</style>

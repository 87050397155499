var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "destroy-on-close": true,
        modal: false,
        visible: _vm.dialogVisible,
        title: "学习详情",
        width: "1366px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "box-top" }, [
            _c("div", { staticClass: "box-top-first" }, [
              _vm.stuDetails.headImg
                ? _c("img", { attrs: { src: _vm.stuDetails.headImg, alt: "" } })
                : _c("img", {
                    attrs: { src: require("@/assets/images/no-data.png") },
                  }),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _c("span", [
                    _vm._v("学员姓名："),
                    _c("strong", [_vm._v(_vm._s(_vm.stuDetails.realName))]),
                  ]),
                ]),
                _c("div", [
                  _c("span", [
                    _vm._v("手机号："),
                    _c("strong", [_vm._v(_vm._s(_vm.stuDetails.phone))]),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" 身份证号："),
                  _c("strong", [_vm._v(_vm._s(_vm.stuDetails.idCard))]),
                ]),
              ]),
            ]),
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("span", { staticStyle: { width: "100px" } }, [
                    _vm._v("身份证人像面："),
                  ]),
                  _vm.stuDetails.frontOfIdCard
                    ? _c("el-image", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          "preview-src-list": [_vm.stuDetails.frontOfIdCard],
                          src: _vm.stuDetails.frontOfIdCard,
                        },
                      })
                    : _c("img", {
                        staticStyle: { width: "140px" },
                        attrs: { src: require("@/assets/images/no-data.png") },
                      }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "span",
                    { staticStyle: { width: "100px", "margin-left": "20px" } },
                    [_vm._v("身份证国徽面：")]
                  ),
                  _vm.stuDetails.obverseOfIdCard
                    ? _c("el-image", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          "preview-src-list": [_vm.stuDetails.obverseOfIdCard],
                          src: _vm.stuDetails.obverseOfIdCard,
                        },
                      })
                    : _c("img", {
                        staticStyle: { width: "140px" },
                        attrs: { src: require("@/assets/images/no-data.png") },
                      }),
                ],
                1
              ),
            ]),
          ]),
          _vm.tabActiveName === _vm.TAB_TYPE.theory.val
            ? _c("h-table", {
                attrs: {
                  "is-handle": true,
                  "is-index": true,
                  "is-pagination": false,
                  loading: _vm.tableLoading,
                  "table-cols": _vm.tableCols,
                  "table-data": _vm.tableData,
                  "table-handles": _vm.tableHandles,
                  that: _vm.that,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <StuCourseList v-if="showPage === 'list'" @goDetailsPage="goDetailsPage" />
    <StuCourseDetail v-if="showPage === 'details'" @goListPage="goListPage" @goVideoPage="goVideoPage" />
    <StuCourseVideo v-if="showPage === 'video'" :video-info="selCourseInfo" @goDetailsPage="goDetailsPage" />
  </div>
</template>

<script>
import StuCourseList from './components/page/course-list'
import StuCourseDetail from './components/page/course-details'
import StuCourseVideo from './components/page/course-video'
export default {
  name: 'StuMyCoursesComp',
  components: {
    StuCourseList,
    StuCourseDetail,
    StuCourseVideo,
  },
  data() {
    return {
      // 显示的页面 list列表页 details详情页
      showPage: 'list',
      selCourseInfo: {}, // 当前选择的课程对象
    }
  },
  mounted() {
    const { courseInfo } = this.$route.params
    if (courseInfo) {
      this.goVideoPage(courseInfo)
    }
  },
  methods: {
    // 切换页面
    // 切换页面
    goListPage() {
      this.showPage = 'list'
    },
    goDetailsPage() {
      this.showPage = 'details'
    },
    goVideoPage(info) {
      this.showPage = 'video'
      this.selCourseInfo = info
    },
  },
}
</script>

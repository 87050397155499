// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#A5ACB3",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#0067C0",
	"sideBarWidth": "210px",
	"bigScreenBackground": "#04093e",
	"bigScreenBorderPrimary": "#3185d3",
	"bigScreenBorderPrimarySimple": "#3779FD",
	"bigScreenBorderSecond": "#2e6eb3",
	"bigScreenBorderTitle": "#00e4ff"
};
module.exports = exports;

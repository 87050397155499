<template>
  <div
    :style="comStyle"
    class="scale-box"
  >
    <Header />
    <router-view :key="key" />
    <!--    <Footer />-->
  </div>
</template>

<script>
import Header from '@/layout/fronts/components/Header'
import Footer from '@/layout/fronts/components/Footer'
import _ from 'lodash'
import { getScrollbarWidth } from '@/utils'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      scale: this.getScale(),
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
    comStyle() {
      const { width = 1920, height = 1080 } = this
      return {
        transform: `scale(${this.scale}) translate(-50%, -50%)`,
        WebkitTransform: `scale(${this.scale}) translate(-50%, -50%)`,
        width: width - getScrollbarWidth() + 'px',
        height: height + 'px',
      }
    },
  },
  mounted() {
    window.onresize = () => {
      this.setScale()
    }
  },
  destroyed() {
    // window.removeEventListener('resize', this.setScale)
  },
  methods: {
    getScale() {
      const { width = 1920, height = 1080 } = this
      const ww = window.innerWidth / width
      // const ww = document.body.clientWidth / width
      const wh = window.innerHeight / height
      // const wh = document.body.clientHeight / height
      return ww < wh ? ww : wh
    },
    setScale: _.debounce(function() {
      // console.log('debounce')
      this.scale = this.getScale()
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
.scale-box {
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 0.3s;
}
</style>

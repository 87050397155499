var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "avatar-uploader",
          attrs: {
            action: "https://upload-z2.qiniup.com",
            data: _vm.uploadData,
            "show-file-list": true,
            "on-success": _vm.handleSuccess,
            "before-upload": _vm.beforeUpload,
            "on-remove": _vm.remove,
            "on-error": _vm.uploadError,
            limit: 1,
            disabled: _vm.disabled,
          },
        },
        [
          _vm._l(_vm.tips, function (item) {
            return _vm.tips
              ? _c(
                  "div",
                  {
                    key: item,
                    staticClass: "el-upload__tip",
                    staticStyle: { "line-height": "10px" },
                    attrs: { slot: "tip" },
                    slot: "tip",
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              : _vm._e()
          }),
          _vm.imageUrl
            ? _c("img", {
                staticClass: "avatar",
                attrs: { src: _vm.imageUrl, alt: "" },
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
        ],
        2
      ),
      _vm.isPriview && _vm.imageUrl
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.imagePreview },
            },
            [_vm._v("预览")]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "查看大图",
            visible: _vm.previewVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewVisible = $event
            },
          },
        },
        [_c("img", { attrs: { width: "100%", src: _vm.imageUrl, alt: "" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
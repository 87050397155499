<template>
  <div class="app-container">
    <MyForm
      ref="editForm"
      :double-row-style="true"
      :edit-cfg="editForm"
      :edit-data="editData"
      :edit-rules="editRules"
      :that="that"
    />
    <div class="buttons">
      <div class="buttons-right">
        <div class="buttons-right-center">
          <el-button class="mr10" type="primary" @click="formValidate">保存</el-button>
          <el-button @click="goBack">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyForm from '@/components/MyForm'
import Tools from '@/utils/tools'
import { EDUCATION, GENDER, JOB_POSITION } from '@/const/sys'
import { studentImgPath } from '@/const/config'
import { getStuInfo } from '@/api/Organization/common'
import { upStudent } from '@/api/company/students'

export default {
  name: 'ExaminerAdd',
  components: {
    MyForm,
  },
  data() {
    const _gender = Tools.mapToSelctList(GENDER)
    const _JOB_POSITION = Tools.mapToSelctList(JOB_POSITION)
    const _education = Tools.mapToSelctList(EDUCATION)
    return {
      that: this,
      edit: '',
      isShowRepeater: false, // 是否显示踏步对数
      editForm: [
        {
          type: 'input',
          label: '姓名',
          prop: 'realName',
          placeholder: '请输入',
          isClearable: true,
          disabled: () => true,
        },
        {
          type: 'input',
          label: '身份证号',
          prop: 'idCard',
          placeholder: '请输入',
          isClearable: true,
          maxLength: 18,
          disabled: () => true,
        },
        {
          type: 'select',
          label: '性别',
          prop: 'gender',
          placeholder: '请选择性别',
          options: _gender,
          isClearable: true,
          disabled: () => true,
        },
        {
          type: 'input',
          label: '电话号码',
          prop: 'phone',
          placeholder: '请输入',
          isClearable: true,
          disabled: () => true,
        },
        {
          type: 'select',
          label: '工作岗位',
          prop: 'job',
          placeholder: '请选择',
          options: _JOB_POSITION,
          isClearable: true,
          disabled: () => true,
        },
        {
          type: 'select',
          label: '学历',
          prop: 'education',
          placeholder: '请选择',
          options: _education,
          isClearable: true,
          disabled: () => true,
        },
        {
          type: 'multipleImgUpload',
          label: '个人证件照',
          prop: 'headImg',
          requestFlag: true,
          isPriview: true,
          limit: 1,
          imgSize: '2',
          uploadPath: studentImgPath.headImgPath,
        },
        {
          type: 'multipleImgUpload',
          label: '身份证人像面',
          prop: 'frontOfIdCard',
          requestFlag: true,
          isPriview: true,
          limit: 1,
          imgSize: '2',
          uploadPath: studentImgPath.idCardImgPath,
        },
        {
          type: 'multipleImgUpload',
          label: '身份证国徽面',
          prop: 'obverseOfIdCard',
          requestFlag: true,
          isPriview: true,
          limit: 1,
          imgSize: '2',
          uploadPath: studentImgPath.idCardImgPath,
        },
      ],
      editData: {
        stuType: '',
        realName: '',
        phone: '',
        idCard: '',
        job: '',
        education: '',
        gender: '',
        headImg: [],
        frontOfIdCard: [],
        obverseOfIdCard: [],
      },
      editRules: {
        realName: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        idCard: [{ required: true, message: '请输入', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择', trigger: 'change' }],
        education: [{ required: true, message: '请输入', trigger: 'blur' }],
        job: [{ required: true, message: '请选择', trigger: 'change' }],
        headImg: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        frontOfIdCard: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        obverseOfIdCard: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },

  created() {
    // 编辑信息数据回显
    this.getInfo()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getInfo() {
      const { data } = await getStuInfo()
      for (const key in data) {
        if (data[key]) {
          if (key === 'headImg' || key === 'frontOfIdCard' || key === 'obverseOfIdCard') {
            this.editData[key] = [data[key]]
          } else {
            this.editData[key] = data[key]
            this.editData[key] = data[key]
            this.editData[key] = data[key]
          }
        }
      }
    },
    formValidate() {
      this.$refs.editForm.$refs.editForm.validate(async valid => {
        if (valid) {
          const _data = JSON.parse(JSON.stringify(this.editData))
          _data.headImg = _data.headImg.join()
          _data.frontOfIdCard = _data.frontOfIdCard.join()
          _data.obverseOfIdCard = _data.obverseOfIdCard.join()
          const { success, msg } = await upStudent(_data)
          this.submitLoading = false
          if (success) {
            this.$message.success('操作成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 2000)
          } else {
            this.$message.error(msg)
          }
        } else {
          this.$message.warning('请完善信息')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "multipleImgUpload" },
      [
        _c(
          "div",
          { staticStyle: { display: "inline-block" } },
          [
            _c(
              "el-upload",
              {
                ref: "upload",
                attrs: {
                  "before-upload": _vm.beforeUpload,
                  data: _vm.uploadData,
                  "on-success": _vm.handleSuccess,
                  "show-file-list": false,
                  action: "https://upload-z2.qiniup.com",
                  "list-type": "picture-card",
                },
              },
              [_c("i", { staticClass: "el-icon-plus" })]
            ),
          ],
          1
        ),
        _vm._l(_vm.sonFileList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "color-item",
              on: {
                dblclick: function ($event) {
                  return _vm.dblclick(index)
                },
              },
            },
            [_c("img", { attrs: { src: item, alt: "" } })]
          )
        }),
      ],
      2
    ),
    _vm.customTips
      ? _c("div", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.customTips))])
      : _c("div", { staticClass: "tips" }, [
          _vm._v(" 最多可以上传 "),
          _c("span", { staticClass: "color-red" }, [_vm._v(_vm._s(_vm.limit))]),
          _vm._v(" 张图片，且单张照片不超过" + _vm._s(_vm.imgSize) + "M，"),
          _c("span", { staticClass: "color-red" }, [_vm._v("双击")]),
          _vm._v("可删除 "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
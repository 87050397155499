<template>
  <div>
    <el-upload
      ref="upload"
      class="avatar-uploader"
      action="https://upload-z2.qiniup.com"
      :data="uploadData"
      :show-file-list="true"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      :on-remove="remove"
      :on-error="uploadError"
      :limit="1"
      :disabled="disabled"
    >
      <div v-for="item in tips" v-if="tips" slot="tip" :key="item" class="el-upload__tip" style="line-height: 10px">
        {{ item }}
      </div>
      <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="">
      <i v-else class="el-icon-plus avatar-uploader-icon"/>
    </el-upload>
    <el-button v-if="isPriview&&imageUrl" size="mini" type="primary" @click="imagePreview">预览</el-button>
    <el-dialog
      width="30%"
      title="查看大图"
      :visible.sync="previewVisible"
      append-to-body
    >
      <img width="100%" :src="imageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getUploadToken } from '@/api/qiniu'
import { defaultImgPath } from '@/const/config'

export default {
  name: '',
  components: {},
  props: { // 接收到的数据属于“静态数据”，是单向数据，不能反向修改
    imgUrl: {
      type: String,
      default: '',
    },
    requestFlag: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: '',
    },
    // 是否允许查看大图
    isPriview: {
      type: Boolean,
      default: false,
    },
    // 是否允许查看大图
    filePath: {
      type: String,
      default: defaultImgPath,
    },
    tips: {
      type: [Array, Object, String],
      default: null,
    },
  },
  data() {
    return {
      previewVisible: false,
      imageUrl: '',
      uploadData: {},
    }
  },
  computed: {
    ...mapGetters([
      'upload_token',
    ]),
  },
  watch: {
    imgUrl: {
      handler: function(newValue, oldValue) {
        this.imageUrl = newValue
      },
      immediate: true,
    },
  },
  beforeMount() {
    // console.log(this.imgUrl)
    // this.requestFlag && this.getQiniuToken()
    this.requestFlag && this.getQiniuToken()
  },
  methods: {
    ...mapMutations('common', ['SET_UPLOAD_TOKEN']),
    getQiniuToken() {
      if (!this.upload_token) {
        getUploadToken().then(response => {
          const { token } = response.data
          this.SET_UPLOAD_TOKEN(token)
        })
      }
    },
    uploadError() {
      this.$message.info('上传失败，请重试')
      getUploadToken().then(response => {
        const { token } = response.data
        this.SET_UPLOAD_TOKEN(token)
      })
    },
    handleSuccess(res, file) {
      // this.imageUrl = `http://wy-file.cyedtech.cn/${res.key}`
      this.imageUrl = `${process.env.VUE_APP_IMG}${res.key}`
      this.$emit('update:value', this.imageUrl)
      this.$emit('validateField', this.field)
    },
    // 上传之前
    beforeUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isPNG = file.type === 'image/png'
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isJPGorPNG && !isPNG) {
        this.$message.error('上传图片需是 JPG或PNG 格式!')
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!')
      }
      const fileName = file.name
      // const key = encodeURI(fileName)
      // this.uploadData.token = this.$store.state.common.upload_token
      // const { userId } = getUserInfo()
      this.uploadData.token = this.upload_token
      this.uploadData.key = (this.filePath + new Date().getTime() + '.' + (fileName.split('.')[1]))
      this.uploadData.data = file
      return isJPGorPNG && isLt10M
    },
    remove(file, fileList) {
      this.imageUrl = ''
      this.$emit('update:value', this.imageUrl)
    },
    clearFiles() {
      this.$refs.upload.clearFiles()
    },
    imagePreview() {
      this.previewVisible = true
    },
  },
}
</script>
<style lang="scss">
</style>

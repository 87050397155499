<template>
  <div class="app-container">
    <div class="header-box flex flex-row align-center justify-between">
      <div class="title">详情</div>
      <div class="mouse-style" @click="goBack">返回</div>
    </div>
    <myDetail :item-width="itemWidth" :list="list" :list-config="listConfig" :list-handles="listHandles" :that="that"/>
    <div class="mt12 ml12 mr12 bgc-white br2">
      <div class="flex flex-row justify-between align-center pt12 pb12 pl16 pr16 bb-d9">
        <div class="fs20 c-181818">员工管理</div>
      </div>
      <HSearch
        ref="searchForm"
        :search-data="searchData"
        :search-form="searchForm"
        :search-handle="searchHandle"
        :search-rules="searchRules"
        :that="that"
        label-width="80px"
        size="medium"
      />
      <div
        v-if="userInfo.userType===USER_TYPE.organization.val&&list.trainningStatus !== PLAN_STATES.commit.val"
        class="flex flex-row justify-end c-0067C0 pr30 fs14 pointer"
        @click="allotStuClick"
      >添加培训员工
      </div>
      <HTable
        :is-handle="true"
        :is-index="true"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-page="tablePage"
        :that="that"
      />
    </div>
    <AllotStuDialog
      :flag-visible.sync="allotStuDialogShow"
      :plan-info="list"
    />
    <AddPlanDialog
      :flag-visible.sync="dialogShow"
      :plan-info="planInfo"
      :title="Title"
    />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch/index.vue'
import HTable from '@/components/MyTable/index.vue'
import AddPlanDialog from '../components/addPlanDialog.vue'
import AllotStuDialog from '../components/allotStuDialog.vue'
import {mapState} from 'vuex'
import {
  deleteTrainningEnrollIds,
  deleteTrainningPlan,
  getListTrainningEnroll,
  getPlanInfo,
  trainningPlanReport,
} from '@/api/company/trainningPlan'
import myDetail from '@/components/MyDetail/index.vue'
import Tools from '@/utils/tools'
import {JOB_POSITION, PLAN_STATES, STU_TYPE, TRAINNING_TYPE, USER_TYPE} from '@/const/sys'

import {getUserInfo} from '@/utils/auth'

export default {
  name: 'TrainingPlanDetails',
  components: {
    myDetail,
    HSearch,
    HTable,
    AllotStuDialog,
    AddPlanDialog,
  },
  data() {
    const _stuType = Tools.mapToSelctList(STU_TYPE)
    return {
      USER_TYPE,
      PLAN_STATES,
      userInfo: {},
      planInfo: {},
      isShowFun: false, // 隐藏功能
      stuInfo: {},
      that: this,
      list: {},
      itemWidth: {width: '31%', marginBottom: '1%'},
      listHandles: [
        {
          label: '发布培训计划',
          type: '',
          classStyle: 'c-0067C0',
          handle: that => that.commitClick(that),
          isShow: () => this.list.trainningStatus !== PLAN_STATES.commit.val,
        },
        {
          label: '编辑',
          type: '',
          classStyle: 'c-0067C0',
          handle: that => that.editsClick(that),
          isShow: () => this.list.trainningStatus !== PLAN_STATES.commit.val,
        },
        {
          label: '删除',
          type: '',
          classStyle: 'c-F40606',
          handle: that => that.delClick(that),
          isShow: () => this.list.trainningStatus !== PLAN_STATES.commit.val,
        },
      ],
      listConfig: [
        {
          prop: 'trainningName',
          placeholder: '培训计划名称',
        },
        {
          prop: 'startTime',
          placeholder: '培训开始时间',
        },
        {
          prop: 'endTime',
          placeholder: '培训结束时间',
        },
        {
          prop: 'trainningAddress',
          placeholder: '培训地点',
        },
        {
          prop: 'teacher',
          placeholder: '授课人',
        },
        {
          prop: 'trainningType',
          placeholder: '培训方式',
          formatter: row => Tools.formatDict(TRAINNING_TYPE, row.trainningType),
        },
        {
          prop: 'courseName',
          placeholder: '培训课程',
        },
      ],
      tableLoading: false,
      // 分配学员弹框显示影藏
      allotStuDialogShow: false,
      // 审核学员资料弹框
      dialogShow: false,
      // 查看资料弹框标题
      Title: '',
      searchData: {
        realName: '',
        phone: '',
        stuType: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'realName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'phone',
          placeholder: '请输入手机号',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'stuType',
          placeholder: '请选择人员类别',
          options: _stuType,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '人员类别', // 内部员工/外部员工
          prop: 'stuType',
          formatter: row => Tools.formatDict(STU_TYPE, row.stuType),
        },
        {
          label: '身份证号',
          prop: 'idCard',
        },
        {
          label: '手机号',
          prop: 'phone',
        },
        {
          label: '所属单位',
          prop: 'organization',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        {
          label: '操作列',
          type: 'button',
          width: '100px',
          isShow: () => this.list.trainningStatus !== PLAN_STATES.commit.val,
          btnList: [
            {
              label: '移除',
              type: 'text',
              style: row => this.list.trainningStatus === PLAN_STATES.commit.val ? '' : ({color: '#F40606'}),
              handle: (that, row) => that.checkInfo(row),
              //isShow: () => this.list.trainningStatus !== PLAN_STATES.commit.val,
            },
          ],
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
    }
  },
  computed: {
    ...mapState('plan', {
      planId: state => state.planId,
      bizLine: state => state.bizLine,
    }),
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.userInfo = getUserInfo()
    this.getPlanData()
    this.getTableData()
  },
  methods: {
    async getPlanData() {
      const {data} = await getPlanInfo(this.planId)
      this.list = data
    },
    // 分配学员
    allotStuClick() {
      this.allotStuDialogShow = true
    },
    editsClick() {
      this.planInfo = this.list
      this.Title = '编辑培训计划'
      this.dialogShow = true
    },
    // 获取表格数据
    async getTableData() {
      this.tableLoading = true
      const _searchData = JSON.parse(JSON.stringify(this.searchData))
      _searchData.stuType = parseInt(_searchData.stuType)  //数据库存储的为数值类型
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _searchData,
      }
      query.bizCondition.trainningId = this.planId

      const {data} = await getListTrainningEnroll(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 搜索按钮
    searchFormClick() {
      this.getTableData()
    },
    // 重置按钮
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 提报按钮
    commitClick(that) {
      this.$confirm('是否发布培训计划', '发布培训计划', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const {success} = await trainningPlanReport(this.list.planId)
        if (success) {
          this.$message.success('提报成功')
          await this.getTableData()
          await this.getPlanData()
        } else {
          this.$message.warning('提报失败')
          await this.getTableData()
          await this.getPlanData()
        }
      })
    },
    // 删除按钮
    delClick(row) {
      this.$confirm('是否删除培训计划', '删除', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const {success} = await deleteTrainningPlan(this.list.planId)
        if (success) {
          this.$message.success('删除成功')
          // this.$emit('goListPage')
          this.goBack()
          await this.$parent.getTableData()
        } else {
          this.$message.warning('删除失败')
        }
      })
    },
    // 点击返回 返回计划列表页
    goBack() {
      // this.$emit('goListPage')
      this.$router.go(-1)
    },
    // 移除
    async checkInfo(row) {
      const {success, data} = await deleteTrainningEnrollIds([row.trainningEnrollId])
      if (success) {
        this.$message.success('移除成功')
        await this.getTableData()
      } else {
        this.$message.warning(data)
        await this.getTableData()
      }
    },
  },
}
</script>
<style scoped>
/deep/ .ces-handle {
  margin: 0;
  padding: 0;
}
</style>

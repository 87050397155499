var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "avatar-uploader",
          attrs: {
            action: "https://upload-z2.qiniup.com",
            data: _vm.uploadData,
            "show-file-list": true,
            "on-success": _vm.handleSuccess,
            "before-upload": _vm.beforeUpload,
            "before-remove": _vm.beforeRemove,
            "on-remove": _vm.remove,
            "on-exceed": _vm.handleExceed,
            "on-error": _vm.uploadError,
            limit: _vm.limit,
            "file-list": _vm.fileList,
            disabled: _vm.disabled,
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("点击上传"),
          ]),
          _c(
            "div",
            { staticClass: "c-7b7b7b", attrs: { slot: "tip" }, slot: "tip" },
            [
              _vm._v(
                "只能上传" +
                  _vm._s(_vm.limit) +
                  "个文件，且单个不超过150mb，多文件时建议上传压缩包！"
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dialog
    title="温馨提示"
    :visible.sync="dialogVisible"
    destroy-on-close
    @close="close"
    @open="open"
  >
    <p>为了您能顺利进行学习，并确保取证时的信息无误，需要您通过微信扫一扫下面的二维码，根据提示进行实名认证。</p>
    <img class="qrcode" :src="authQrcode" alt="">
    <div class="text">扫一扫，进行实名认证</div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sure">已完成实名认证</el-button>
      <el-button @click="close">跳过</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { getUserInfo, setUserInfo } from '@/utils/auth'
import { checkVerifyResult } from '@/api/admin/student/common'
import { SYS } from '@/const/sys'

export default {
  name: 'ModalAuth',
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    authQrcode: {
      type: String,
      default: '',
    },
    // enrollId: {
    //   type: Number,
    //   default: 0
    // }
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
  },
  created() {
  },
  methods: {
    open() {
    },
    close() {
      this.dialogVisible = false
      this.$emit('closeAuth')
    },
    sure() {
      const localUserInfo = getUserInfo().studentInfo
      return checkVerifyResult(localUserInfo.stuId).then(res => {
        // console.log(res)
        if (res.success) {
          localUserInfo.frontOfIdCard = res.data.frontOfIdCard
          localUserInfo.obverseOfIdCard = res.data.obverseOfIdCard
          localUserInfo.isRealNameAuth = res.data.isRealNameAuth
          localUserInfo.realName = res.data.realName
          localUserInfo.realNameAuthTime = res.data.realNameAuthTime
          localUserInfo.idCard = res.data.idCard
          setUserInfo(localUserInfo)
          // sessionStorage.setItem(
          //   SYS.userInfo,
          //   JSON.stringify(localUserInfo),
          // )
          // window.location.reload()
        }
      }).finally(() => {
        this.dialogVisible = false
        this.$emit('closeAuth')
      })
    },
  },
}
</script>

<style scoped lang="scss">
.code-wrapper {
  margin: 20px auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  .qrcode {
    display: inline-block;
    margin: 0 auto;
  }
}
.qrcode {
  width: 340px;
  display: block;
  margin: 24px auto;
}
.text {
  text-align: center;
  color: #46A0FC;
}

::v-deep .el-dialog__footer {
  text-align: center;
}
</style>

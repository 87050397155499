<template>
  <Chart
    :id="id"
    :height="height"
    :options="options"
    :width="width"
  />
</template>

<script>
import Chart from '@/components/Charts/common'
import echarts from 'echarts'

export default {
  name: 'LineChart',
  components: {
    Chart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cdata: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: '400px',
    },
    height: {
      type: String,
      default: '200px',
    },
  },
  data() {
    return {
      options: {},
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        const { xAxisData, data } = newData
        if (!data) return

        this.options = {
          xAxis: {
            type: 'category',
            data: xAxisData,
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: ['#c1c1c1'],
                type: 'solid',
              },
            },
            axisLabel: {
              textStyle: {
                color: ['#f5f5f5'],
              },
            },

          },
          yAxis: {
            name: '人次',
            type: 'value',
            axisLine: {
              lineStyle: {
                color: ['#c1c1c1'],
                type: 'solid',
              },
            },
            axisLabel: {
              textStyle: {
                color: ['#f5f5f5'],
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#c1c1c1',
                type: 'dashed',
              },
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
            },
            // transitionDuration: 0.4,
            padding: [5, 10],
            className: 'echarts-tooltip echarts-tooltip-white',
            // formatter: _formatFun,
          },
          series: [
            {
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: '#24BBF5',
              },
              showSymbol: false,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(36, 187, 245,0.8)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(36, 187, 245,0)',
                  },
                ]),
              },
              emphasis: {
                focus: 'series',
              },
              data: data,
            },
          ],
          grid: {
            top: 40,
            left: 20,
            right: 25,
            bottom: 0,
            containLabel: true,
          },
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style>

</style>

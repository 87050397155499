import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 学生端 学习中心
const stuLearningCenterRouter = {
  path: '/student/learningCenter',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '学习中心',
    icon: null,
    roles: [USER_TYPE.student.val],
  },
  alwaysShow: true,
  children: [
    {
      path: 'myCourses',
      component: () => import('@/views/student/my-courses'),
      name: 'StuMyCourses',
      meta: { title: '我的课程', icon: null },
    },
    {
      path: 'myPractice',
      component: () => import('@/views/student/my-practice'),
      name: 'StuMyPractice',
      meta: { title: '我的练习', icon: null },
    },
    {
      path: 'EditStudentInfoPage',
      component: () => import('@/views/components/dialog/EditStudentInfoPage'),
      name: 'EditStudentInfoPage',
      hidden: true,
      meta: { title: '我的信息', icon: null },
    },
  ],
}

export default stuLearningCenterRouter

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "map",
        style: { height: _vm.height, width: _vm.width },
        attrs: { id: "container" },
      },
      [
        _c("div", {
          staticClass: "mask",
          style: { height: _vm.height, width: _vm.width },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "question" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "warning" },
            on: { click: _vm.toBack },
          },
          [_vm._v("退出测试")]
        ),
        _c("span", [_vm._v(_vm._s(_vm.paperEntity))]),
        _c("div"),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _vm.active <= _vm.singleChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "单选题（共" +
                    _vm._s(_vm.singleChoiceList.length) +
                    "题，" +
                    _vm._s(
                      _vm.singleChoiceList.length > 0
                        ? _vm.singleChoiceList[0].point
                        : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.singleChoiceList, function (item, index) {
                  return _vm.active === index + 1
                    ? _c("li", { key: index }, [
                        _c("div", { staticClass: "second" }, [
                          _vm._v(_vm._s(_vm.active) + "." + _vm._s(item.title)),
                        ]),
                        item.titleImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "200px",
                                  height: "200px",
                                  "margin-bottom": "30px",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.titleImg },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "third" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.changeChiose(item, index)
                                  },
                                },
                                model: {
                                  value: item.own,
                                  callback: function ($$v) {
                                    _vm.$set(item, "own", $$v)
                                  },
                                  expression: "item.own",
                                },
                              },
                              _vm._l(item.answerOptions, function (val, index) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: index,
                                    attrs: {
                                      disabled:
                                        item.own !== null && item.own !== "",
                                      label: val.option,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(val.option) +
                                        "." +
                                        _vm._s(val.value) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        item.seeFlag === true
                          ? _c("div", { staticClass: "fity" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("答案解析"),
                              ]),
                              _c("div", { staticClass: "isCorret" }, [
                                item.expectedAnswer === item.own
                                  ? _c("div", { staticClass: "sure" }, [
                                      _vm._v("回答正确"),
                                    ])
                                  : _c("div", { staticClass: "error" }, [
                                      _c("span", [_vm._v("回答错误")]),
                                      _c("span", [_vm._v("正确答案")]),
                                      _c("span", [
                                        _vm._v(_vm._s(item.expectedAnswer)),
                                      ]),
                                    ]),
                              ]),
                              _c("div", { staticClass: "analysis" }, [
                                _vm._v(_vm._s(item.description)),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "fourth" },
                          [
                            _vm.active > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: { click: _vm.backClick },
                                  },
                                  [_vm._v("上一题")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.nextClick },
                              },
                              [_vm._v("下一题")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm.active <=
              _vm.singleChoiceList.length + _vm.TrueFalseList.length &&
            _vm.active > _vm.singleChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "判断题（共" +
                    _vm._s(_vm.TrueFalseList.length) +
                    "题，" +
                    _vm._s(
                      _vm.TrueFalseList.length > 0
                        ? _vm.TrueFalseList[0].point
                        : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.TrueFalseList, function (item, index) {
                  return _vm.active === _vm.singleChoiceList.length + index + 1
                    ? _c("li", { key: index }, [
                        _c("div", { staticClass: "second" }, [
                          _vm._v(_vm._s(_vm.active) + "." + _vm._s(item.title)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "third" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  disabled:
                                    item.own !== null && item.own !== "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.trueFalseChiose(item, index)
                                  },
                                },
                                model: {
                                  value: item.own,
                                  callback: function ($$v) {
                                    _vm.$set(item, "own", $$v)
                                  },
                                  expression: "item.own",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "true" } }, [
                                  _vm._v("正确"),
                                ]),
                                _c("el-radio", { attrs: { label: "false" } }, [
                                  _vm._v("错误"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        item.seeFlag === true
                          ? _c("div", { staticClass: "fity" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("答案解析"),
                              ]),
                              _c("div", { staticClass: "isCorret" }, [
                                item.expectedAnswer === item.own
                                  ? _c("div", { staticClass: "sure" }, [
                                      _vm._v("回答正确"),
                                    ])
                                  : _c("div", { staticClass: "error" }, [
                                      _c("span", [_vm._v("回答错误")]),
                                      _c("span"),
                                    ]),
                              ]),
                              _c("div", { staticClass: "analysis" }, [
                                _vm._v(_vm._s(item.description)),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "fourth" },
                          [
                            _vm.active > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: { click: _vm.backClick },
                                  },
                                  [_vm._v("上一题")]
                                )
                              : _vm._e(),
                            _vm.active <
                            _vm.singleChoiceList.length +
                              _vm.TrueFalseList.length +
                              _vm.MultiChoiceList.length
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: { click: _vm.nextClick },
                                  },
                                  [_vm._v("下一题 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm.active <=
              _vm.singleChoiceList.length +
                _vm.TrueFalseList.length +
                _vm.MultiChoiceList.length &&
            _vm.active > _vm.singleChoiceList.length + _vm.TrueFalseList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "多选题（共" +
                    _vm._s(_vm.MultiChoiceList.length) +
                    "题，" +
                    _vm._s(
                      _vm.MultiChoiceList.length > 0
                        ? _vm.MultiChoiceList[0].point
                        : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.MultiChoiceList, function (item, index) {
                  return _vm.active ===
                    _vm.TrueFalseList.length +
                      _vm.singleChoiceList.length +
                      index +
                      1
                    ? _c("li", { key: index }, [
                        _c("div", { staticClass: "second" }, [
                          _vm._v(_vm._s(_vm.active) + "." + _vm._s(item.title)),
                        ]),
                        item.titleImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "200px",
                                  height: "200px",
                                  "margin-bottom": "30px",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.titleImg },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "third" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: item.own,
                                  callback: function ($$v) {
                                    _vm.$set(item, "own", $$v)
                                  },
                                  expression: "item.own",
                                },
                              },
                              _vm._l(item.answerOptions, function (val, index) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: index,
                                    attrs: {
                                      disabled: item.seeFlag === true,
                                      label: val.option,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(val.option) +
                                        "." +
                                        _vm._s(val.value) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        item.seeAnalysis === true
                          ? _c("div", { staticClass: "fity" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("答案解析"),
                              ]),
                              _c("div", { staticClass: "isCorret" }, [
                                item.expectedAnswer ===
                                item.own.join("#").toUpperCase()
                                  ? _c("div", { staticClass: "sure" }, [
                                      _vm._v("回答正确"),
                                    ])
                                  : _c("div", { staticClass: "error" }, [
                                      _c("span", [_vm._v("回答错误")]),
                                      _c("span", [_vm._v("正确答案")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.expectedAnswer
                                              .split("#")
                                              .join(",")
                                          )
                                        ),
                                      ]),
                                    ]),
                              ]),
                              _c("div", { staticClass: "analysis" }, [
                                _vm._v(_vm._s(item.description)),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "fourth" },
                          [
                            _vm.active > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: { click: _vm.backClick },
                                  },
                                  [_vm._v("上一题")]
                                )
                              : _vm._e(),
                            _vm.active <
                            _vm.singleChoiceList.length +
                              _vm.TrueFalseList.length +
                              _vm.MultiChoiceList.length +
                              _vm.exampleList.length
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.nextClick(item, index)
                                      },
                                    },
                                  },
                                  [_vm._v("下一题 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sureMulClick(item, index)
                                  },
                                },
                              },
                              [_vm._v("查看解析")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm.active <=
              _vm.singleChoiceList.length +
                _vm.TrueFalseList.length +
                _vm.MultiChoiceList.length +
                _vm.exampleList.length &&
            _vm.active >
              _vm.singleChoiceList.length +
                _vm.TrueFalseList.length +
                _vm.MultiChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "案例题（共" +
                    _vm._s(_vm.exampleList.length) +
                    "题，" +
                    _vm._s(
                      _vm.exampleList.length > 0 ? _vm.exampleList[0].point : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.exampleList, function (item, index) {
                  return _vm.active ===
                    _vm.MultiChoiceList.length +
                      _vm.TrueFalseList.length +
                      _vm.singleChoiceList.length +
                      index +
                      1
                    ? _c(
                        "li",
                        { key: index },
                        [
                          _c("div", { staticClass: "second" }, [
                            _vm._v(
                              _vm._s(_vm.active) + "." + _vm._s(item.title)
                            ),
                          ]),
                          _vm._l(item.children, function (val, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "third" },
                              [
                                _c("div", { staticClass: "second" }, [
                                  _vm._v(
                                    _vm._s(i + 1) + "." + _vm._s(val.title)
                                  ),
                                ]),
                                val.type === "SingleChoice"
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChiose(val, index)
                                          },
                                        },
                                        model: {
                                          value: val.own,
                                          callback: function ($$v) {
                                            _vm.$set(val, "own", $$v)
                                          },
                                          expression: "val.own",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          val.answerOptions,
                                          function (a, b) {
                                            return _c(
                                              "el-radio",
                                              {
                                                key: b,
                                                attrs: {
                                                  disabled:
                                                    val.own !== null &&
                                                    val.own !== "",
                                                  label: a.option,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(a.option) +
                                                    "." +
                                                    _vm._s(a.value) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        val.seeFlag === true
                                          ? _c(
                                              "div",
                                              { staticClass: "isCorret" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [_vm._v("答案解析")]
                                                ),
                                                val.own === val.expectedAnswer
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "sure" },
                                                      [_vm._v("回答正确")]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "error" },
                                                      [
                                                        _c("span", [
                                                          _vm._v("回答错误"),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v("正确答案"),
                                                        ]),
                                                        val.expectedAnswer ===
                                                          "true" ||
                                                        val.expectedAnswer ===
                                                          "false"
                                                          ? _c("span")
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  val.expectedAnswer
                                                                )
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                                _c(
                                                  "div",
                                                  { staticClass: "analysis" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(val.description)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                val.type === "TrueFalse"
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                        attrs: {
                                          disabled:
                                            val.own !== null && val.own !== "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.trueFalseChiose(
                                              val,
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: val.own,
                                          callback: function ($$v) {
                                            _vm.$set(val, "own", $$v)
                                          },
                                          expression: "val.own",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "true" } },
                                          [_vm._v("正确")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "false" } },
                                          [_vm._v("错误")]
                                        ),
                                        val.seeFlag === true
                                          ? _c(
                                              "div",
                                              { staticClass: "isCorret" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [_vm._v("答案解析")]
                                                ),
                                                val.own === val.expectedAnswer
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "sure" },
                                                      [_vm._v("回答正确")]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "error" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#F43114",
                                                            },
                                                          },
                                                          [_vm._v("回答错误")]
                                                        ),
                                                      ]
                                                    ),
                                                _c(
                                                  "div",
                                                  { staticClass: "analysis" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(val.description)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                val.type === "MultiChoice"
                                  ? _c(
                                      "el-checkbox-group",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                        model: {
                                          value: val.own,
                                          callback: function ($$v) {
                                            _vm.$set(val, "own", $$v)
                                          },
                                          expression: "val.own",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          val.answerOptions,
                                          function (a, b) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: b,
                                                attrs: {
                                                  disabled:
                                                    val.seeFlag === true,
                                                  label: a.option,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(a.option) +
                                                    "." +
                                                    _vm._s(a.value) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        val.seeFlag === true
                                          ? _c(
                                              "div",
                                              { staticClass: "isCorret" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [_vm._v("答案解析")]
                                                ),
                                                val.expectedAnswer ===
                                                val.own.join("#").toUpperCase()
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "sure" },
                                                      [_vm._v("回答正确")]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "error" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [_vm._v("回答错误")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v("正确答案"),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              val.expectedAnswer
                                                                .split("#")
                                                                .join(",")
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                _c(
                                                  "div",
                                                  { staticClass: "analysis" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(val.description)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                val.type === "MultiChoice"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                        attrs: {
                                          disabled: val.seeFlag === true,
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sureMulClick(val, index)
                                          },
                                        },
                                      },
                                      [_vm._v("确认答案 ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e()
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "fourth" },
                [
                  _vm.active > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.backClick },
                        },
                        [_vm._v("上一题")]
                      )
                    : _vm._e(),
                  _vm.active <
                  _vm.singleChoiceList.length +
                    _vm.TrueFalseList.length +
                    _vm.MultiChoiceList.length +
                    _vm.exampleList.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.nextClick },
                        },
                        [_vm._v("下一题 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "warning" },
                on: { click: _vm.submitClick },
              },
              [_vm._v("交卷")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "number" }, [
          _c("div", { staticStyle: { "margin-left": "40px" } }, [
            _c("span", { staticStyle: { "font-size": "24px" } }, [
              _vm._v("答题卡"),
            ]),
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v(
                "(共" +
                  _vm._s(
                    _vm.singleChoiceList.length +
                      _vm.TrueFalseList.length +
                      _vm.MultiChoiceList.length +
                      _vm.exampleList.length
                  ) +
                  "题，总分100分，" +
                  _vm._s(_vm.passScore) +
                  "分合格)"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-left": "40px", "margin-top": "20px" } },
            [_vm._v("单选题")]
          ),
          _c(
            "ul",
            _vm._l(_vm.singleChoiceList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.class,
                  on: {
                    click: function ($event) {
                      return _vm.singleChoiceChange(index)
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "40px", "margin-top": "20px" } },
            [_vm._v("判断题")]
          ),
          _c(
            "ul",
            _vm._l(_vm.TrueFalseList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.class,
                  on: {
                    click: function ($event) {
                      return _vm.trueFalseChange(index)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(index + 1) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "40px", "margin-top": "20px" } },
            [_vm._v("多选题")]
          ),
          _c(
            "ul",
            _vm._l(_vm.MultiChoiceList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.class,
                  on: {
                    click: function ($event) {
                      return _vm.multiChoiceChange(index)
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "40px", "margin-top": "20px" } },
            [_vm._v("案例题")]
          ),
          _c(
            "ul",
            _vm._l(_vm.exampleList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.class,
                  on: {
                    click: function ($event) {
                      return _vm.exampleChange(index)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(index + 1) + " ")]
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "温馨提示",
        visible: _vm.dialogVisible,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
        open: _vm.open,
      },
    },
    [
      _c("p", [
        _vm._v(
          "为了您能顺利进行学习，并确保取证时的信息无误，需要您通过微信扫一扫下面的二维码，根据提示进行实名认证。"
        ),
      ]),
      _c("img", {
        staticClass: "qrcode",
        attrs: { src: _vm.authQrcode, alt: "" },
      }),
      _c("div", { staticClass: "text" }, [_vm._v("扫一扫，进行实名认证")]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sure } },
            [_vm._v("已完成实名认证")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("跳过")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const state = {
  // 缓存业务类型（大类别单一是可用）
  categoryAllMap: null, // 用于根据子级id转化成'金属工-高级技师(一级)'-----列表展示
  categoryChildMap: null, // 用于根据子级id转化成'高级技师(一级)'-----列表展示
  categoryAllArr: null, // 用于父子级别勾选 -----选择下拉框
  categoryParentMap: null, // 用于根据父级id转化成'金属工'-----列表展示
  categoryParentArr: null, // 用于父级勾选 -----选择下拉框
}
const mutations = {
  saveCategoryAllMap: (state, categoryAllMap) => {
    state.categoryAllMap = categoryAllMap
  },
  saveCategoryChildMap: (state, categoryChildMap) => {
    state.categoryChildMap = categoryChildMap
  },
  saveCategoryAllArr: (state, categoryAllArr) => {
    state.categoryAllArr = categoryAllArr
  },
  saveCategoryParentMap: (state, categoryParentMap) => {
    state.categoryParentMap = categoryParentMap
  },
  saveCategoryParentArr: (state, categoryParentArr) => {
    state.categoryParentArr = categoryParentArr
  },

}
const getter = {
  count: state => state,
}
export default {
  namespaced: true,
  state,
  mutations,
  getter,
}

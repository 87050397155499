<template>
  <div ref="editorDom" />
</template>

<script>
// 富文本编辑器
import Editor from 'wangeditor'
export default {
  name: '',
  components: {},
  props: {
    isClear: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    isClear: {
      handler() {
        this.editor.txt.clear()
      }
    },
    value: {
      handler(newValue) {
        // this.editor.txt.html(newValue)
        if (newValue !== this.editor.txt.html()) {
          this.editor.txt.html(newValue)
        }
      }
    }
    // value: {
    //   handler(newValue) {
    //     // this.editor.txt.html(newValue)
    //     if (newValue !== this.editor.txt.html()) {
    //       this.editor.txt.html(this.value)
    //     }
    //   }
    // }
  },
  mounted() {
    this.editor = new Editor(this.$refs.editorDom)
    this.editor.customConfig.onchange = html => {
      this.$emit('update:content', html)
    }
    this.editor.customConfig.menus = [
      'head', // 标题
      'bold', // 粗体
      'fontSize', // 字号
      'fontName', // 字体
      'italic', // 斜体
      'underline', // 下划线
      'strikeThrough', // 删除线
      'foreColor', // 文字颜色
      'backColor', // 背景颜色
      'link', // 插入链接
      'list', // 列表
      'justify', // 对齐方式
      'quote', // 引用
      'emoticon', // 表情
      'table', // 表格
      'code', // 插入代码
      'undo', // 撤销
      'redo' // 重复
    ]
    this.editor.customConfig.zIndex = 100
    this.editor.create() // 创建富文本实例
    // 配置菜单
    this.editor.txt.html(this.value)
  }
}
</script>
<style lang='scss' scoped>
</style>

<template>
  <div>
    <div v-if="!isOrg" class="flex flex-column justify-center align-center wrap">
      <img class="img" src="../../assets/images/index-logo.png">
      <div class="c-7b7b7b fs30 mt30">欢迎使用化工培训管理系统</div>
    </div>
    <div v-if="isOrg" class="org-wrap">
      <div v-for="(item,key,index) in courseObj" :key="index" class="mb12 bgc-white">
        <div class="c-181818 fs20 title">{{ BIZLINE[key].txt }}</div>
        <div class="flex flex-wrap">
          <div v-for="(item_2,index_2) in item" :key="index_2" class="flex item pointer" @click="clickFun(item_2)">
            <div class="img_wrap flex align-center justify-center">
              <img src="../../assets/images/curriculum-icon.png">
            </div>
            <div class="fs14">{{ item_2.courseName }}</div>
          </div>
        </div>
      </div>
    </div>
    <AddTrainPlanDialog
      :course-id="courseId"
      :flag-visible.sync="dialogShow"
      :title="dialogTitle"
    />
  </div>
</template>
<script>

import { getUserInfo } from '@/utils/auth'
import { BIZLINE, EXAM_ROOM_STATUS, ORG_USER_TYPE } from '@/const/sys'
import { listGroupByDicKey } from '@/api/common'
import AddTrainPlanDialog from '@/views/company/trainning-plan/components/addPlanDialog.vue'

export default {
  name: 'HomeIndex',
  components: {
    AddTrainPlanDialog,
  },
  data() {
    return {
      BIZLINE,
      isOrg: false,
      courseObj: {},
      courseId: 0,
      // 新增计划
      dialogShow: false,
      dialogTitle: '新增培训计划',
    }
  },
  created() {
    if (getUserInfo().orgInfo && getUserInfo().orgInfo.orgUserType === ORG_USER_TYPE.admin.val) {
      // 机构端
      this.isOrg = true
      this.info()
    }
  },
  methods: {
    clickFun(item) {
      if (item.cateStatus !== EXAM_ROOM_STATUS.active.val) {
        this.$message.info('课程上架中。。。')
        return
      }
      this.courseId = item.courseId
      this.dialogShow = true
    },
    info() {
      listGroupByDicKey().then(res => {
        this.courseObj = {}
        const _objSort = {}
        for (const key in Object.keys(BIZLINE)) {
          _objSort[Object.keys(BIZLINE)[key]] = key
        }
        const sortedObj = Object.keys(res.data)
          .sort((key1, key2) => _objSort[key1] - _objSort[key2])
          .reduce((acc, key) => {
            acc[key] = res.data[key]
            return acc
          }, {})
        this.courseObj = sortedObj
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.org-wrap {
  margin: 12px;

}

.title {
  margin-bottom: 16px;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;

}

.item {
  width: 318px;
  margin: 0 0 16px 16px;
  padding: 16px;
  border: 1px solid #EFF2F7;

}

.img_wrap {
  width: 70px;
  height: 113px;
  margin-right: 16px;
  background-color: #F2F6FC;

}

.img {
  width: 178px;
  height: 152px;

}

.wrap {
  height: calc(100vh - 200px);

}
</style>


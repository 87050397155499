import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 学习管理
const learningManagementRouter = {
  path: '/learning-management',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '学习管理',
    icon: null,
    roles: [USER_TYPE.organization.val, USER_TYPE.government.val],
  },
  alwaysShow: true,
  children: [
    {
      path: 'online',
      component: () => import('@/views/company/learning-management/online'),
      name: 'onlineLearningManagement',
      meta: { title: '线上学习列表', icon: null },
    },
    {
      path: 'offline',
      component: () => import('@/views/company/learning-management/offline'),
      name: 'offlineLearningManagement',
      meta: { title: '线下学习列表', icon: null },
    },
  ],
}

export default learningManagementRouter

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/authLogin_bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".noData_model[data-v-8ec7b382] {\n  width: 100vw;\n  height: 100vh;\n}\n.login_img_noData[data-v-8ec7b382] {\n  width: 240px;\n  height: 274px;\n}\n.login_img[data-v-8ec7b382] {\n  width: 367px;\n  height: 460px;\n  cursor: pointer;\n}\n.select_model[data-v-8ec7b382] {\n  height: 100vh;\n  margin-left: 22vw;\n  margin-right: 22vw;\n}\n.login-wrap[data-v-8ec7b382] {\n  height: 100vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n", ""]);
// Exports
module.exports = exports;

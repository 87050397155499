import request from '@/utils/request'

/**
 * 理论考试计划和实操考试计划相关接口（监管端和机构段）
 */
// 创建考试计划
export function createExam(data) {
  return request({
    url: '/sessions/create',
    method: 'post',
    data,
  })
}

// 考试计划详情
export function papaerListAll(paperTitle) {
  return request({
    url: '/paper/papaerListAll?paperTitle=' + paperTitle,
    method: 'get',
  })
}

// 修改考试计划
export function updateExam(data) {
  return request({
    url: '/sessions/update',
    method: 'post',
    data,
  })
}

// 考试计划列表
export function examList(data) {
  return request({
    url: '/sessions/list',
    method: 'post',
    data: data,
  })
}

// 考试计划详情
export function examListDetail(examSessionsId) {
  return request({
    url: '/sessions/detail?examSessionsId=' + examSessionsId,
    method: 'get',
    // data: data,
  })
}

// 创建实操考试计划
export function sessionsOperationCreates(data) {
  return request({
    url: '/exsystem/sessions/operation/create',
    method: 'post',
    data,
  })
}

// 修改理论考试计划
export function sessionsUpdate(data) {
  return request({
    url: '/exsystem/sessions/theory/update',
    method: 'post',
    data,
  })
}

// 修改实操考试计划
export function sessionsOperationUpdates(data) {
  return request({
    url: '/exsystem/sessions/operation/update',
    method: 'post',
    data,
  })
}

// 理论考试计划列表
export function sessionsSuperviseList(data) {
  return request({
    url: '/exsystem/sessions/theory/list',
    method: 'post',
    data: data,
  })
}

// 实操考试计划列表
export function sessionsOperationLists(data) {
  return request({
    url: '/exsystem/sessions/operation/list',
    method: 'post',
    data: data,
  })
}

// 提交考试计划
export function sessionsActive(examSessionsId) {
  return request({
    url: '/sessions/active',
    method: 'get',
    params: {
      examSessionsId,
    },
  })
}

// 审批考试计划
export function sessionsAudit(data) {
  return request({
    url: '/exsystem/sessions/audit',
    method: 'post',
    data,
  })
}

// 删除考试计划
export function sessionsDelete(examSessionsId) {
  return request({
    url: '/sessions/delete',
    method: 'get',
    params: {
      examSessionsId,
    },
  })
}

//  机构端获取考核点列表
export function getExamPointList(dictKey) {
  return request({
    url: '/exsystem/sessions/getExamPointList',
    method: 'get',
    params: {
      dictKey,
    },
  })
}

// 查询可分配学员
export function queryAssignStu(data) {
  return request({
    url: '/enrollExam/queryEnrollExamUnAssign',
    method: 'post',
    data,
  })
}

// 查询已分配学员
export function queryUnAssignStu(data) {
  return request({
    url: '/enrollExam/queryEnrollExamAssign',
    method: 'post',
    data,
  })
}

// 分配学员
export function enrollExamBind(data) {
  return request({
    url: '/enrollExam/bind',
    method: 'post',
    data,
  })
}

// 计划移除
export function enrollExamUnBind(data) {
  return request({
    url: '/enrollExam/unbind',
    method: 'post',
    data,
  })
}

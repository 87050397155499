import request from '@/utils/request'

// 机构端 学习管理表格数据
export function getOrgLearTableData(data) {
  return request({
    url: '/record/queryOrgCourseDetails',
    method: 'post',
    data,
  })
}

// 批量更新考试结果状态
export function batchUpdateExamResult(data) {
  return request({
    url: 'record/updateEnrollExamResult',
    method: 'post',
    data,
  })
}

// 机构端 学习管理 学习详情
export function getOrgRecordDetails(courseId, enrollId) {
  return request({
    url: `/record/orgRecordDetails`,
    method: 'get',
    params: {
      courseId,
      enrollId,
    },
  })
}

// 机构端 学习管理 学习详情-实操
export function getOptRecordDetails(enrollId) {
  return request({
    url: `/recordOpt/optRecordDetails?enrollId=` + enrollId,
    method: 'get',
  })
}

import request from '@/utils/request'

// 获取理论考试结果列表
export function getExamResultList(data) {
  return request({
    url: '/sessions/statistics/pcResult/list',
    method: 'post',
    data: data,
  })
}

// 获取单个考试计划下的理论考试学员列表
export function getExamResultStuList(data) {
  return request({
    url: '/sessions/result/stuList',
    method: 'post',
    data: data,
  })
}

// 获取实操考试结果列表
export function getOprExamResultList(data) {
  return request({
    url: '/exsystem/sessions/statistics/optResult/optList',
    method: 'post',
    data: data,
  })
}

// 获取单个考试计划下的实操考试学员列表
export function getOprExamResultStuList(data) {
  return request({
    url: '/exsystem/sessions/statistics/optResult/stuList',
    method: 'post',
    data: data,
  })
}

// 获取考试结果详情
export function getOprResultDetail(params) {
  return request({
    url: '/exsystem/sessions/statistics/optResult/detail',
    method: 'get',
    params,
  })
}

// 审核考试结果状态
export function auditResultStatus(data) {
  return request({
    url: '/exsystem/sessions/updateResultAuditStatus',
    method: 'post',
    data: data,
  })
}

// 获取考试结果附件
export function getExamSessionsAttach(examSessionsId) {
  return request({
    url: '/exsystem/sessions/getExamSessionsAttach',
    method: 'get',
    params: {
      examSessionsId,
    },
  })
}

// 保存考试结果附件
export function saveExamSessionsAttach(data) {
  return request({
    url: '/exsystem/sessions/saveExamSessionsAttach',
    method: 'post',
    data,
  })
}

// 删除考试结果附件
export function delExamSessionsAttach(data) {
  return request({
    url: '/exsystem/sessions/delExamSessionsAttach',
    method: 'post',
    data,
  })
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/banner-1.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".new-img {\n  width: 570px;\n  height: 378px;\n}\n.txt-wrap-content {\n  border-bottom: 3px solid #1C54C2;\n}\n.new-txt {\n  padding-bottom: 14px;\n  border-bottom: 1px dashed #d9d9d9;\n}\n.txt-wrap {\n  border-bottom: 1px solid #d9d9d9;\n}\n.children-model {\n  width: 432px;\n  height: 300px;\n  border-radius: 10px;\n  border: 1px solid #D9D9D9;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n.title-content {\n  letter-spacing: 30px;\n}\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n  -webkit-transition-delay: 111111s;\n  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;\n}\n.icon-style {\n  color: #7b7b7b;\n}\n.svg-container {\n  display: inline-block;\n  padding-left: 20px;\n}\n.input-bg {\n  background-color: #F5F6F8;\n  margin-bottom: 30px;\n}\n.login .el-tabs__item {\n  padding: 0 !important;\n}\n.login .el-tabs__item.is-active {\n  color: #0486FF !important;\n}\n.login .el-tabs__active-bar {\n  background-color: #0486FF !important;\n}\n.login .el-input--medium {\n  width: 240px;\n  text-align: right;\n}\n.login .el-input--medium .el-input__inner {\n    font-size: 14px;\n    border: none;\n    background-color: transparent;\n}\n.login-wrap {\n  height: 400px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n.login {\n  width: 360px;\n  height: 340px;\n  border-radius: 10px;\n  padding-left: 30px;\n  padding-right: 30px;\n}\n.home-center {\n  width: 1400px;\n  margin: 0 auto;\n}\n.vjs-big-play-button {\n  position: absolute !important;\n  top: 50% !important;\n  left: 50% !important;\n  -webkit-transform: translate(-50%) !important;\n          transform: translate(-50%) !important;\n}\n", ""]);
// Exports
module.exports = exports;

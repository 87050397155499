<template>
  <el-dialog title="我的信息" width="800px" :visible.sync="dialogVisible" destroy-on-close @close="close" @open="open">
    <div style="padding: 0 20px">
      <h-form
        ref="editForm"
        :that="that"
        :edit-cfg="editForm"
        :edit-data="editData"
        :edit-rules="editRules"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="loading" @click="saveClick">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import HForm from '@/components/MyForm'
import { getUserInfo, setUserInfo } from '@/utils/auth'
import { EDUCATION, GENDER, USER_TYPE } from '@/const/sys'
import { saveStudentInfo } from '@/api/admin/student/common'
import Tools from '@/utils/tools'

export default {
  name: 'EditStudentInfo',
  components: {
    HForm,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const _education = Tools.mapToSelctList(EDUCATION)
    const _gender = Tools.mapToSelectList(GENDER)
    return {
      that: this,
      dialogVisible: false,
      editForm: [
        {
          label: '姓名',
          prop: 'realName',
          type: 'input',
          placeholder: '请输入',
          disabled: () => !this.isCanEdit,
        },
        {
          label: '手机号码',
          prop: 'phone',
          type: 'input',
          placeholder: '请输入',
          disabled: () => true,
        },
        {
          type: 'radio',
          label: '性别',
          prop: 'gender',
          radios: _gender,
        },
        {
          label: '身份证号',
          prop: 'idCard',
          type: 'input',
          placeholder: '请输入',
          disabled: () => !this.isCanEdit,
        },
        {
          label: '学历',
          prop: 'education',
          type: 'select',
          placeholder: '请选择',
          options: _education,
        },
        {
          label: '工作单位',
          prop: 'organization',
          type: 'input',
          placeholder: '请输入',
        },
        {
          type: 'upload',
          label: '证件照',
          placeholder: '请上传缩略图',
          prop: 'headImg',
          // width: '300px',
          requestFlag: true,
          tips: ['请上传符合要求的头像照片：',
            '（1）本人近三个月内的正面、清晰、免冠、彩色（限蓝底）电子证件登记照片；',
            '（2）照片类型为jpg、png格式，照片大小不超过80K；',
            '（3）不得上传全身照、半身照、风景照、生活照、不得过度PS处理。'],
        },
        {
          type: 'upload',
          label: '身份证人像面',
          placeholder: '请上传缩略图',
          prop: 'frontOfIdCard',
          width: '300px',
        },
        {
          type: 'upload',
          label: '身份证国徽面',
          placeholder: '请上传缩略图',
          prop: 'obverseOfIdCard',
          width: '300px',
        },
        {
          type: 'upload',
          label: '学历照片',
          placeholder: '请上传缩略图',
          prop: 'educationImg',
          width: '300px',
        },
      ],
      // editData: {
      //   realName: '',
      //   phone: '',
      //   gender: '',
      //   idCard: '',
      //   education: '',
      //   organization: '',
      //   headImg: '',
      //   frontOfIdCard: '',
      //   obverseOfIdCard: '',
      //   educationImg: '',
      // },
      editData: {},
      editRules: {
        gender: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        education: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        organization: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        headImg: [
          { required: true, message: '请选择配图', trigger: 'change' },
        ],
        frontOfIdCard: [
          { required: true, message: '请选择配图', trigger: 'change' },
        ],
        obverseOfIdCard: [
          { required: true, message: '请选择配图', trigger: 'change' },
        ],
        // educationImg: [
        //   { required: true, message: '请选择配图', trigger: 'change' },
        // ],
      },
      loading: false,
    }
  },
  computed: {
    isCanEdit: function() {
      let _isCanEdit = false
      const localUsrInfo = getUserInfo()
      if (localUsrInfo.userType === USER_TYPE.student.val) {
        _isCanEdit = true
        if (localUsrInfo.studentInfo.isRealNameAuth) {
          _isCanEdit = false
        }
      }
      return _isCanEdit
    },
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
  },
  methods: {
    open() {
      this.editData = getUserInfo() && getUserInfo().studentInfo
    },
    close() {
      this.$refs['editForm'].$refs.editForm.resetFields()
      this.dialogVisible = false
      this.$emit('closeEditStudentInfo')
    },
    saveClick() {
      this.$refs.editForm.$refs.editForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const data = JSON.parse(JSON.stringify(this.editData))
            const { success } = await saveStudentInfo(data)
            if (success) {
              const localUsrInfo = getUserInfo()
              if (localUsrInfo) {
                localUsrInfo.studentInfo = data
                setUserInfo(localUsrInfo)
              }
              this.$message.success('更新成功')
              this.$emit('closeEditStudentInfo')
            }
            this.loading = false
          } catch (e) {
            this.loading = false
            console.log(e)
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <PracticeList v-if="isTab === 1" @toTest="toTest" @toDetail="toDetail" @toResult="toResult" />
    <ExaminationTestTab v-if="isTab === 2" @toList="toList" @toResult="toResult" />
    <ExaminationDetailTab v-if="isTab === 3" @toList="toList" @toResult="toResult" />
    <!--    <ExaminationResultTab v-if="isTab === 4" @toList="toList" @toResult="toResult" />-->
    <NewResultPage v-if="isTab === 4" @toTest="toTest" @toList="toList" @toResult="toResult" />
  </div>
</template>

<script>
import PracticeList from './components/practice-list'
import ExaminationTestTab from './components/examination-test'
import ExaminationDetailTab from './components/examination-detail'
import ExaminationResultTab from './components/examination-result'
import NewResultPage from './components/new-result'
export default {
  name: 'StuMyPracticeComp',
  components: {
    PracticeList,
    ExaminationTestTab,
    ExaminationDetailTab,
    ExaminationResultTab,
    NewResultPage,
  },
  data() {
    return {
      // 显示页面
      isTab: 1,
    }
  },
  methods: {
    // 切换组件
    toTest() {
      this.isTab = 2
    },
    toList() {
      this.isTab = 1
    },
    toDetail() {
      this.isTab = 3
    },
    toResult() {
      this.isTab = 4
    },
  },
}
</script>

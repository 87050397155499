var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isTab === 1
        ? _c("PracticeList", {
            on: {
              toTest: _vm.toTest,
              toDetail: _vm.toDetail,
              toResult: _vm.toResult,
            },
          })
        : _vm._e(),
      _vm.isTab === 2
        ? _c("ExaminationTestTab", {
            on: { toList: _vm.toList, toResult: _vm.toResult },
          })
        : _vm._e(),
      _vm.isTab === 3
        ? _c("ExaminationDetailTab", {
            on: { toList: _vm.toList, toResult: _vm.toResult },
          })
        : _vm._e(),
      _vm.isTab === 4
        ? _c("NewResultPage", {
            on: {
              toTest: _vm.toTest,
              toList: _vm.toList,
              toResult: _vm.toResult,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
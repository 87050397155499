import { cloneDeep, forOwn, isArray, isObject, omit } from 'lodash'
import { BIZLINE } from '@/const/sys'

class Tools {
  constructor() {
    this.code = 0
    this.message = 'not initialized'
  }

  static mapToList(map) {
    const output = []
    for (const i in map) {
      output.push({
        value: map[i].val || map[i].key || map[i].value,
        text: map[i].txt || map[i].text,
      })
    }
    return output
  }

  // 重置当前对象的属性值
  static resetObject(obj) {
    for (const i in obj) {
      if (Array.isArray(obj[i])) {
        obj[i] = []
      } else {
        obj[i] = ''
      }
    }
  }

  static mapToCustomList(map) {
    const output = []
    for (const i in map) {
      output.push({
        key: i,
        val: map[i],
      })
    }
    return output
  }

  static dropCategoryToStr(arr) {
    const output = []
    if (arr) {
      arr.map(item => {
        output.push(...item.slice(-1))
      })
    }
    return output
  }

  static listStrToName(str, arr) {
    const _names = []
    if (typeof (str) === 'number') {
      _names.push(arr[str])
    } else {
      if (str) {
        const _categoryIdList = str.split(',')
        _categoryIdList.forEach(key => {
          _names.push(arr[key])
        })
      }
    }
    return _names
  }

  static filterCategory(str, arr) {
    const _arr = []
    arr.forEach(f => {
      const _children = f.children.filter(item => {
        if (str.indexOf(item.value) >= 0) {
          return f.children
        }
      })
      if (_children.length) {
        _arr.push({
          label: f.label,
          value: f.value,
          children: _children,
        })
      }
    })
    return _arr
  }

  static filterCategoryAllArrToCascader(arr) {
    const _arr = []
    arr.map(item => {
      item.children.map(m => {
        _arr.push([item.value, m.value])
      })
    })
    return _arr
  }

  static mapToListChildren(map) {
    const output = []
    for (const i in map) {
      const _children = map[i].children
      output.push({
        value: map[i].val || map[i].key || map[i].value,
        text: map[i].txt || map[i].text,
        children: _children,
      })
    }
    return output
  }

  static getRandomNum(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  /**
   * 将对象转成下拉框需要的数组
   * @param map
   * @returns {*[]}
   */
  static mapToSelectList(map) {
    const output = []
    for (const i in map) {
      output.push({
        value: map[i].val || map[i].key || map[i].value,
        label: map[i].txt || map[i].text,
      })
    }
    return output
  }

  static listToMapReplace(list, keyField, txtField) {
    if (!list || !list instanceof Array) {
      throw new Error('list is not an array')
    }
    const output = {}
    list.forEach(item => {
      const key = item[keyField] || item._id
      if (!key) {
        throw new Error('Invalid key field')
      }
      const value = txtField === undefined ? item : item[txtField]
      output[key] = value
    })
    return output
  }

  static listToMapAggregate(list, keyField, txtField) {
    if (!list || !list instanceof Array) {
      throw new Error('list is not an array')
    }
    const output = {}
    list.forEach(item => {
      const key = item[keyField] || item._id
      if (!key) {
        throw new Error('Invalid key field')
      }

      const value = txtField === undefined ? item : item[txtField]
      if (!output[key]) {
        output[key] = [value]
      } else {
        output[key].push(value)
      }
    })
    return output
  }

  /**
   * 将对象数组转成数组
   * @param map
   * @param keyField
   * @param txtField
   * @returns {{}}
   */
  static mapToListAggregate(map, keyField, txtField) {
    const output = []
    for (const i in map) {
      output.push({
        value: map[i][keyField] || map[i].key || map[i].value,
        label: map[i][txtField] || map[i].text,
      })
    }
    return output
  }

  static replaceAll(str, oldSubStr, newSubStr) {
    if (str != null && oldSubStr != null && newSubStr != null) {
      return str.replace(new RegExp(oldSubStr, 'g'), newSubStr)
    }
    return str
  }

  // 数组去重
  static distinctArray(list) {
    const _list = new Set()
    list.map(item => {
      _list.add(item)
    })
    return [..._list]
  }

  /**
   * 把后台反的数据，加入到data中
   */
  static findSelect(arr, form) {
    const result = []
    arr.forEach(prop => {
      const v = form.find(item => item.prop === prop)
      result.push(v)
    })
    return result
  }

  static formatOpt(arr, keyValueArr) {
    const result = []
    arr.forEach((item, index) => {
      const newArr = item.map(i => {
        const {
          [keyValueArr[index].value]: value,
          [keyValueArr[index].label]: label,
          ...rest
        } = i
        const newObj = {
          value,
          label,
        }
        return { ...rest, ...newObj }
      })
      result.push(newArr)
    })
    return result
  }

  static assignValue(a, b) {
    for (let i = 0; i < a.length; i++) {
      a[i].options = b[i]
    }
  }

  // 字典表格式化
  static formatDict(obj, key) {
    if (key && obj[key]) {
      return obj[key].txt
    } else if (obj['DEFAULT']) {
      return obj['DEFAULT'].txt
    } else {
      return ''
    }
  }

  /**
   * 将对象转成下拉框需要的数组
   * @param map
   * @returns {*[]}
   */
  static mapToSelctList(map) {
    const output = []
    for (const i in map) {
      output.push({
        value: map[i].val || map[i].key || map[i].value,
        label: map[i].txt || map[i].text,
      })
    }
    return output
  }

  // 把汉字转成字典
  static formatTxtToDict(obj, txt) {
    const arr = this.mapToList(obj)
    const target = arr.find(i => i.text === txt)
    if (target) return target.value
    return null
  }

  static deleteParams(data, props) {
    return omitDeepLodash(data, props)
  }

  // 格式化数字，变成百分比,默认格式化成两位数
  static formatCount(num, digits) {
    if (num) {
      if (!digits) digits = 2
      return (num * 100).toFixed(digits) + '%'
    } else {
      return '0%'
    }
  }

  // 获取Excel文件时间数据
  static formatExcelDate(serial) {
    var utc_days = Math.floor(serial - 25569)
    var utc_value = utc_days * 86400
    var date_info = new Date(utc_value * 1000)
    var fractional_day = serial - Math.floor(serial) + 0.0000001
    var total_seconds = Math.floor(86400 * fractional_day)
    var seconds = total_seconds % 60
    total_seconds -= seconds
    var hours = Math.floor(total_seconds / (60 * 60))
    var minutes = Math.floor(total_seconds / 60) % 60
    var d = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds)

    return d
  }

  static formatTime(second_time) {
    let time = parseInt(second_time) + '秒'
    if (parseInt(second_time) > 60) {
      const second = parseInt(second_time) % 60
      let min = parseInt(second_time / 60)
      time = min + '分' + second + '秒'
      if (min > 60) {
        min = parseInt(second_time / 60) % 60
        let hour = parseInt(parseInt(second_time / 60) / 60)
        time = hour + '小时' + min + '分' + second + '秒'
        if (hour > 24) {
          hour = parseInt(parseInt(second_time / 60) / 60) % 24
          const day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
          time = day + '天' + hour + '小时' + min + '分' + second + '秒'
        }
      }
    }
    return time
  }

  static cloneDeep(obj) {
    return cloneDeep(obj)
  }

  helper(code, msg) {
    this.code = code
    this.msg = msg
    return this
  }

  // static formatOpt(arr, val, lab) {
  //   const result = []
  //   arr.forEach(item => {
  //     const newArr = item.map(i => {
  //       const {
  //         [val]: value,
  //         [lab]: label,
  //         ...rest
  //       } = i
  //       const newObj = {
  //         value,
  //         label
  //       }
  //       return { ...rest, ...newObj }
  //     })
  //     result.push(newArr)
  //   })
  //   return result
  // }

  initError(code, errMsg, statusCode) {
    this.helper(code, errMsg)
    this.statusCode = statusCode
    return this
  }

  error(errMsg, statusCode) {
    this.initError(200, errMsg, statusCode)
    const error = new Error(this.message)
    error.code = this.code
    error.statusCode = this.statusCode
    error.isManaged_Flag = true
    return error
  }

  /**
   * 从预置错误中获取错误内容
   * @param {Object} source 预置错误信息
   */
  errFrom(source) {
    return this.error(source.msg, source.code)
  }

  /**
   * 从预置错误中获取code，但使用自定义文案作为错误message
   * @param {String} text 错误信息
   * @param {Object} source 预置错误信息
   */
  errReplaceFrom(text, source) {
    return this.error(text, source.code)
  }

  toObj(errFlag) {
    var _target = {}
    _target.code = this.code
    _target.error = errFlag ? true : (this.code >= 400)
    _target.msg = this.msg
    if (this.statusCode) {
      _target.statusCode = this.statusCode
    }

    if (this.hasOwnProperty('data')) {
      _target.data = this.data
    }
    return _target
  }

  success(str, data) {
    this.helper(200, str || '操作成功')
    if (data != undefined) {
      this.setData(data)
    }
    this.statusCode = 200000
    return this
  }

  msg(str) {
    return this.helper(399, str)
  }

  setData(data) {
    if (data != undefined) {
      this.data = data
    } else {
      delete this.data
    }
    return this
  }
}

function omitDeepLodash(input, props) {
  let p = props

  function omitDeepOnOwnProps(obj) {
    if (!isArray(obj) && !isObject(obj)) {
      return obj
    }

    if (isArray(obj)) {
      return omitDeepLodash(obj, p)
    }

    const o = {}
    forOwn(obj, (value, key) => {
      o[key] = omitDeepLodash(value, p)
    })

    return omit(o, p)
  }

  if (arguments.length > 2) {
    p = Array.prototype.slice.call(arguments).slice(1)
  }

  if (typeof input === 'undefined') {
    // return {}
    return undefined
  }

  if (isArray(input)) {
    return input.map(omitDeepOnOwnProps)
  }

  return omitDeepOnOwnProps(input)
}

export default Tools
// module.exports = Tools

<template>
  <div className="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <HTable
      :is-handle="true"
      :is-index="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
    />
    <OnlineLearningDialog :flag-visible.sync="dialogShow" :stu-info="stuInfo" />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
// 学习详情弹框
import OnlineLearningDialog from '../components/onlineDetail'

import { COURSE, JOB_POSITION, ORG_USER_TYPE, RATE_LEARNING, TRAINNING_TYPE, USER_TYPE } from '@/const/sys'
import Tools from '@/utils/tools'
import { getOrgLearTableData } from '@/api/company/learnning'
import { mapState } from 'vuex'
import { getUserInfo } from '@/utils/auth'

const Interpretor = require('@/utils/interpretor')
const Q = require('q')

export default {
  name: 'OnlineLearningManagementComp',
  components: {
    HSearch,
    HTable,
    OnlineLearningDialog,
  },
  data() {
    return {
      activeName: 'studying',
      COURSE,
      // 表格选中数据
      tableActiveList: [],
      // 单个学员信息
      stuInfo: {},
      that: this,
      tableLoading: false,
      // 学习详情弹框显示影藏
      dialogShow: false,
      changeUploadShow: false,
      searchData: {
        realName: '',
        phone: '',
        trainningName: '', // 培训计划名称
        cateName: '', // 培训课程
        orgName: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'parkName',
          placeholder: '请输入所属园区',
          isClearable: true,
          isShow: () => getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_province_admin.val,
        },
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'orgName',
          placeholder: '请输入所属企业',
          isClearable: true,
          isShow: () => getUserInfo().userType === USER_TYPE.government.val,
        },
        {
          type: 'input',
          label: '',
          prop: 'realName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'phone',
          placeholder: '请输入手机号',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'trainningName',
          placeholder: '请输入所属培训计划',
          isClearable: true,
        },
        {
          type: 'cascader',
          label: '',
          prop: 'learningProcess',
          placeholder: '请选择学习进度',
          width: '200px',
          showAllLevels: true,
          props: { multiple: true, expandTrigger: 'hover' },
          options: RATE_LEARNING,
          isClearable: true,
          isCollapseTags: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'cateName',
          placeholder: '请输入培训课程',
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '所属园区',
          prop: 'parkName',
          isShow: () => getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_province_admin.val,
        },
        {
          label: '所属企业',
          prop: 'orgName',
          isShow: () => getUserInfo().userType === USER_TYPE.government.val,
        },
        {
          label: '所属培训计划',
          prop: 'trainningName',
        },
        {
          label: '培训方式',
          formatter: () => '线上培训',
        },
        {
          label: '培训课程',
          prop: 'cateName',
        },
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '手机号',
          prop: 'phone',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        {
          label: '学习进度',
          prop: 'learningProcess',
        },
        {
          label: '操作列',
          type: 'button',
          btnList: [
            {
              label: '学习详情',
              type: 'text',
              handle: (that, row) => that.openDetail(row),
            },
          ],
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          that.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          that.getTableData()
        },
      },
      tableHandles: [],
    }
  },
  computed: {
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.getTableData()
  },
  methods: {
    fomatCategory(row) {
      const _names = Tools.listStrToName(row.categoryChildId, this.categoryAllMap)
      return _names.toString()
    },
    // 获取表格数据
    async getTableData() {
      const query = {
        pageCondition: { ...this.tablePage },
        bizCondition: { ...this.searchData },
      }
      this.tableLoading = true
      query.bizCondition = this.conversion(query.bizCondition) // conversion数组转化与去重
      query.bizCondition.trainningType = TRAINNING_TYPE['online-study'].val
      query.bizCondition.learningProcessList = query.bizCondition.learningProcess
      delete query.bizCondition.learningProcess
      const { data } = await getOrgLearTableData(query)

      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 多维数组转一维数组并去除指定元素
    conversion(query) {
      if (query.learningProcess && query.learningProcess.length) {
        query.learningProcess = query.learningProcess.toString().split(',').filter(f => {
          return f !== RATE_LEARNING[0].value
        })
      }
      return query
    },
    // 查询按钮
    searchFormClick() {
      this.getTableData()
    },
    // 重置按钮
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 查看详情按钮
    openDetail(row) {
      this.stuInfo = row
      this.dialogShow = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../components/styles/index";
</style>

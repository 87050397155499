<template>
  <el-dialog
    :destroy-on-close="true"
    :modal="false"
    :visible.sync="dialogVisible"
    title="学习详情"
    width="1366px"
    @close="close"
  >
    <div>
      <div class="box-top">
        <div class="box-top-first">
          <img v-if="stuDetails.headImg" :src="stuDetails.headImg" alt="">
          <img v-else src="~@/assets/images/no-data.png">
          <div class="detail">
            <div>
              <span>学员姓名：<strong>{{ stuDetails.realName }}</strong></span>
            </div>
            <div>
              <span>手机号：<strong>{{ stuDetails.phone }}</strong></span>
            </div>
            <div>
              身份证号：<strong>{{ stuDetails.idCard }}</strong>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div style="display: flex">
            <span style="width: 100px">身份证人像面：</span>
            <el-image
              v-if="stuDetails.frontOfIdCard"
              :preview-src-list="[stuDetails.frontOfIdCard]"
              :src="stuDetails.frontOfIdCard"
              style="width: 140px; "
            />
            <img v-else src="~@/assets/images/no-data.png" style="width: 140px; ">
          </div>
          <div style="display: flex">
            <span style="width: 100px;margin-left: 20px">身份证国徽面：</span>
            <el-image
              v-if="stuDetails.obverseOfIdCard"
              :preview-src-list="[stuDetails.obverseOfIdCard]"
              :src="stuDetails.obverseOfIdCard"
              style="width: 140px;"
            />
            <img v-else src="~@/assets/images/no-data.png" style="width: 140px; ">
          </div>
        </div>
      </div>
      <h-table
        v-if="tabActiveName===TAB_TYPE.theory.val"
        :is-handle="true"
        :is-index="true"
        :is-pagination="false"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-handles="tableHandles"
        :that="that"
      />
    </div>
  </el-dialog>
</template>

<script>
import HTable from '@/components/MyTable'
import {AUDIT, Course_type, LEARNING_PROCESS, TAB_TYPE} from '@/const/sys'
import Tools from '@/utils/tools'
import {
  getOrgRecordDetails,
} from '@/api/company/learnning'

export default {
  name: 'OnlineLearningDialog',
  components: {
    HTable,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    stuInfo: {
      type: Object,
      default: null,
    },
    isShowFun: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      TAB_TYPE,
      tabActiveName: 'theory',
      bizLine: '',
      LEARNING_PROCESS,
      AUDIT,
      flag: false,
      isShow: false,
      tableLoading: false,
      dialogVisible: false,
      that: this,
      // 学员学习详情
      stuDetails: {},
      // 一键审核按钮
      isExamine: 0,
      tableActiveList: [], // 选中的表格
      tableData: [],
      tableCols: [
        {
          label: '课程课件名称',
          prop: 'courseWareName',
        },
        {
          label: '学习时间',
          prop: 'updateTime',
        },
        {
          label: '学习时长',
          prop: 'recordTime',
          formatter: row => parseInt(row.recordTime) ? Tools.formatTime(row.recordTime) : (parseInt(row.recordTime) === 0 ? '0秒' : ''),
        },
        {
          label: '人脸抓拍1',
          prop: 'snapShot1',
          type: 'image',
        },
        {
          label: '人脸抓拍2',
          prop: 'snapShot2',
          type: 'image',
        },
        {
          label: '学习状态',
          width: '100px',
          prop: 'recordStatus',
          formatter: row => Tools.formatDict(LEARNING_PROCESS, row.recordStatus),
        },
      ],
      tableHandles: [],
    }
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
        this.bizLine = this.stuInfo.bizLine
        this.getStuDetail(this.stuInfo.courseId, this.stuInfo.enrollId)
      },
    },
  },
  methods: {
    // 关闭弹框
    close() {
      this.dialogVisible = false
      this.tableActiveList = []
      this.$emit('update:flagVisible', false)
    },
    // 获取学习详情数据
    getStuDetail(courseId, enrollId) {
      this.tableLoading = true
      getOrgRecordDetails(courseId, enrollId).then(res => {
        if (res.success) {
          this.tableLoading = false
          const _majorList = []
          const _publicList = []
          res.data.res.map(item => {
            item.snapShot1 = this.getSnapShotImg(item.snapShotList, 0)
            item.snapShot2 = this.getSnapShotImg(item.snapShotList, 1)
            if (item.courseAttr === Course_type.major.val || item.courseAttr === Course_type.addMajor.val || item.courseAttr === Course_type.mainMajor.val) {
              _majorList.push(item)
            } else if (item.courseAttr === Course_type.public.val || item.courseAttr === Course_type.addPublic.val || item.courseAttr === Course_type.mainPublic.val) {
              _publicList.push(item)
            }
            return item
          })
          this.stuDetails = res.data
          this.tableData = _publicList.concat(_majorList)
        } else {
          this.$message.warning('数据获取失败')
        }
      })
    },
    // 获取抓拍照片
    getSnapShotImg(snapShotList, index) {
      if (snapShotList && snapShotList[index]) {
        return snapShotList[index]
      } else {
        return ''
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.box-top {
  width: 100%;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  margin: 16px 12px;

  .isApproval {
    display: flex;
  }

  .box-top-first {
    width: 40%;
    display: flex;
    align-items: center;
    padding-left: 18px;

    img {
      width: 92px;
      height: 123px;
    }

    .detail {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #555;
      margin-left: 10px;
      line-height: 30px;

      div {
        margin: 5px;

        &:first-of-type {
          display: flex;

          span {
            &:first-of-type {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

.table-empty {
  min-height: 60px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebeef5;

  .table-empty-text {
    line-height: 60px;
    width: 50%;
    color: #909399;
  }
}

// 标签的样式
.tab-box-hasSwitch {
  // margin-bottom: 20px;
  float: right;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  //margin-bottom: 0;
  display: flex;
  justify-content: flex-end;

  ::v-deep.el-tabs__item {
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    border: 1px solid #DCDFE6 !important;
    margin-top: 0;
    margin-left: 0 !important;
    border-radius: 4px;
    margin-right: 10px;
    display: inline-block
  }

  ::v-deep.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #0067C0;
    border: 1px solid #0067C0 !important;
  }

  ::v-deep.el-tabs--border-card {
    box-shadow: none;
    border: none;
  }

  ::v-deep.el-tabs__header {
    background-color: #fff;
    border: none;
  }

  ::v-deep.el-tabs__item.is-active {
    color: #0067C0;
    border: 1px solid #0067C0 !important;
  }

  ::v-deep.el-tabs__nav.is-top {
    //border-top: 1px solid #DCDFE6;
  }
}

</style>

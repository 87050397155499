const state = {
  // 计划ID
  planId: null,
  bizLine: null,
  saveExamSessionsId: null,
}
const mutations = {
  savePlanId: (state, planId) => {
    state.planId = planId
  },
  saveExamSessionsId: (state, examSessionsId) => {
    state.examSessionsId = examSessionsId
  },
  saveExamSessionAddr: (state, examSessionAddr) => {
    state.examSessionAddr = examSessionAddr
  },
  saveBizLine: (state, bizLine) => {
    state.bizLine = bizLine
  },
}
const getter = {
  count: state => state,
}
export default {
  namespaced: true,
  state,
  mutations,
  getter,
}

<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <svg-icon v-if="gov" class-name="switch-style" icon-class="largeScreen" @click="goFront" />
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="hover">
        <el-button icon="el-icon-user-solid" style="color: #B5B5B6" type="text">{{
          `你好 ${dropdownMenus.titleName}`
        }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in dropdownMenus.menus"
            :key="index"
            @click.native="item.click"
          >
            {{ item.title }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <edit-password-modal :flag-visible="isEditPassword" @closeEditPassword="closeEditPassword" />
    <edit-student-info :flag-visible="isEditStudentInfo" @closeEditStudentInfo="closeEditStudentInfo" />
    <!--    <certificate-modal :flag-visible="isCertificate" @closeCertificate="closeCertificate" />-->
    <auth-modal :auth-qrcode="authQrcode" :flag-visible="isAuth" @closeAuth="closeAuth" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import dropDownList from '@/mixins/dropDownList'
import EditPasswordModal from '@/views/components/dialog/EditPasswordModal'
import EditStudentInfo from '@/views/components/dialog/EditStudentInfo'
import AuthModal from '@/views/components/dialog/AuthModal'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    EditPasswordModal,
    EditStudentInfo,
    AuthModal,
  },
  mixins: [dropDownList],
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
    ]),
  },
  methods: {
    goFront() {
      this.$router.replace({ path: '/statistic' })
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    closeEditPassword() {
      this.isEditPassword = false
    },
    closeEditStudentInfo() {
      this.isEditStudentInfo = false
    },
    closeCertificate() {
      this.isCertificate = false
    },
    closeAuth() {
      this.isAuth = false
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  position: relative;
  height: 50px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    float: left;
    height: 100%;
    line-height: 46px;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      height: 100%;
      padding: 0 8px;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        margin-top: 5px;

        .user-avatar {
          width: 40px;
          height: 40px;
          cursor: pointer;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          position: absolute;
          top: 25px;
          right: -20px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.switch-style {
  color: #7B7B7B;
  width: 26px !important;
  height: 26px !important;
  cursor: pointer;
  margin-right: 20px;
}
</style>

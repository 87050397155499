<template>
  <div class="app-container">
    <div class="wrap">
      <!--返回按钮-->
      <div>
        <el-button @click="goBack">返回</el-button>
      </div>
      <!--课程信息-->
      <div class="course-detail">
        <!--课程图片-->
        <div class="left-img" />
        <!--课程信息-->
        <div class="course-info">
          <p>化工专业</p>
          <p style="color:#0799C5">{{ courseName }}</p>
          <p>学习进度：{{ learningProcess }}</p>
        </div>
      </div>
      <!--课程列表-->
      <div class="course-list">
        <div class="tab-box-hasSwitch">
          <el-tabs v-model="activeName" type="border-card" @tab-click="handleTabsClick">
            <el-tab-pane label="课程列表" name="1" />
            <el-tab-pane label="学习详情" name="2" />
          </el-tabs>
        </div>
        <!--课程列表-->
        <div v-if="activeName === '1'" class="course-name">
          <div style="font-weight: 600; height: 30px">公共课</div>
          <div
            v-if="publicList.length === 0"
            style="background-color: #f1f1f1; text-align: center;font-size: 14px; height: 25px;line-height: 25px;margin-top: 10px"
          >暂无公共课
          </div>
          <ul v-else style="background-color: #f1f1f1 ; padding: 0 10px">
            <li v-for="(item, index) in publicList" :key="index" @click="goVideo(item)">
              <div style="width: 85%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                <span style="margin-right: 10px">{{ index + 1 }}</span>
                <span>{{ item.courseWareName }}</span>
              </div>
              <div style="width: 15%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                <span style="margin-right: 10px">
                  <i class="el-icon-time" />
                  <span style="margin-left: 10px">{{ $formatTimeDate.getTime(item.longTime) }}</span>
                </span>
                <span style="font-size: 16px">
                  <i
                    v-if="item.recordStatus === LEARNING_PROCESS.finish.val"
                    class="el-icon-circle-check"
                    style="color:#20cb49;"
                  />
                  <i v-else class="el-icon-s-data" style="color:#42ace2;" />
                </span>
              </div>
            </li>
          </ul>
          <div style="font-weight: 600 ; margin-top: 20px;">专业课</div>
          <div
            v-if="majorList.length === 0"
            style="background-color: #f1f1f1; text-align: center;font-size: 14px; height: 25px;line-height: 25px;margin-top: 10px"
          >暂无专业课
          </div>
          <ul style="background-color: #f1f1f1; padding: 0 10px">
            <li v-for="(item, index) in majorList" :key="index" @click="goVideo(item)">
              <div style="width: 85%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                <span style="margin-right: 10px">{{ index + 1 + publicList.length }}</span>
                <span>{{ item.courseWareName }}</span>
              </div>
              <div style="width: 15%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                <span style="margin-right: 10px">
                  <i class="el-icon-time" />
                  <span style="margin-left: 10px">{{ $formatTimeDate.getTime(item.longTime) }}</span>
                </span>
                <span style="font-size: 16px">
                  <i
                    v-if="item.recordStatus === LEARNING_PROCESS.finish.val"
                    class="el-icon-circle-check"
                    style="color:#20cb49;"
                  />
                  <i v-else class="el-icon-s-data" style="color:#42ace2;" />
                </span>
              </div>
            </li>
          </ul>
        </div>
        <!--学习详情-->
        <div v-if="activeName === '2'">
          <HTable
            :is-handle="true"
            :is-index="true"
            :loading="tableLoading"
            :table-cols="tableCols"
            :table-data="tableData"
            :table-handles="tableHandles"
            :table-page="tablePage"
            :that="that"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HTable from '@/components/MyTable'
import { mapState } from 'vuex'
import { BIZLINE, Course_type, LEARNING_PROCESS } from '@/const/sys'
import Tools from '@/utils/tools'
import { getCourseWareDetails, getOrgRecordDetails } from '@/api/student/course'

export default {
  name: 'StuCourseDetail',
  components: {
    HTable,
  },
  data() {
    return {
      LEARNING_PROCESS: LEARNING_PROCESS,
      // 学员报名信息
      stuInfo: {},
      // 视屏列表
      videoList: [],
      // 专业课
      majorList: [],
      // 公共课
      publicList: [],
      bizLine: '',
      // 标签页
      activeName: '1',
      that: this,
      tableLoading: false,
      // 报名详情弹框显示影藏
      InfoDialogShow: false,
      // 弹框标题
      Title: '',
      tableData: [
        {
          realName: 'zxx',
        },
      ],
      tableCols: [
        {
          label: '课件名称',
          prop: 'courseWareName',
          width: '250px',
        },
        {
          label: '学习时间',
          prop: 'updateTime',
          width: '160px',
        },
        {
          label: '人脸照片1',
          type: 'image',
          prop: 'snapShot1',
        },
        {
          label: '人脸照片2',
          type: 'image',
          prop: 'snapShot2',
        },
        {
          label: '学习状态',
          prop: 'recordStatus',
          width: '90px',
          formatter: row => Tools.formatDict(LEARNING_PROCESS, row.recordStatus),
        },
      ],
      tablePage: {
        size: 50,
        total: 0,
        current: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.current = val
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.size = val
        },
      },
      tableHandles: [],
    }
  },
  computed: {
    ...mapState('stuCourse', {
      courseName: state => state.courseName,
      expiredTime: state => state.expiredTime,
      learningProcess: state => state.learningProcess,
      courseId: state => state.courseId,
      enrollId: state => state.enrollId,
      dicKey: state => BIZLINE[state.dicKey].txt,
      categoryId: state => state.categoryId,
    }),
  },
  created() {
    this.getVideoList()
  },
  methods: {
    // 点击返回 返回课程列表页
    goBack() {
      this.$emit('goListPage')
    },
    // 标签页切换
    handleTabsClick(tab, event) {
      if (tab.name === '2') {
        // 获取学习详情信息
        this.getStuDetail()
      }
    },
    // 获取视屏列表数据
    async getVideoList() {
      const _majorList = []
      const _publicList = []
      const { data } = await getCourseWareDetails(this.enrollId, this.courseId)
      this.videoList = data
      this.videoList.forEach(item => {
        if (item.courseAttr === Course_type.major.val || item.courseAttr === Course_type.addMajor.val || item.courseAttr === Course_type.mainMajor.val) {
          _majorList.push(item)
        } else if (item.courseAttr === Course_type.public.val || item.courseAttr === Course_type.addPublic.val || item.courseAttr === Course_type.mainPublic.val) {
          _publicList.push(item)
        }
      })
      this.majorList = _majorList
      this.publicList = _publicList
    },
    // 进入视屏播放页
    goVideo(courseInfo) {
      courseInfo.enrollId = this.enrollId
      courseInfo.categoryId = this.categoryId
      this.$emit('goVideoPage', courseInfo)
    },
    // 获取学习详情数据
    async getStuDetail() {
      this.tableLoading = true
      const { data } = await getOrgRecordDetails(this.courseId, this.enrollId)
      this.tableLoading = false
      const _publicList = [] // 公开课课件
      const _majorList = [] // 专业课课件
      data.res.forEach(item => {
        item.snapShot1 = this.getSnapShotImg(item.snapShotList, 0)
        item.snapShot2 = this.getSnapShotImg(item.snapShotList, 1)
        if (item.courseAttr.indexOf('public') >= 0) {
          _publicList.push(item)
        } else {
          _majorList.push(item)
        }
      })
      this.tableData = _publicList.concat(_majorList)
    },

    // 获取抓拍照片
    getSnapShotImg(snapShotList, index) {
      if (snapShotList && snapShotList[index]) {
        return snapShotList[index]
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../components/styles/student";
@import "../../../../components/styles/stu-learning-center";

.tab-box-hasSwitch {
  margin-top: -12px;
}

.app-container {
  background-color: #efefef;

  .course-detail {
    display: flex;
    width: 100%;
    height: 170px;
    background-color: #fff;
    margin-top: 20px;
    padding: 19px 30px;

    .left-img {
      width: 260px;
      height: 133px;
      background: url("~@/assets/images/course_bg.png") 0 0 no-repeat;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .course-info {
      margin-left: 43px;

      p {
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }

  .course-list {
    width: 100%;
    background-color: #fff;
    margin-top: 17px;
    padding: 12px 20% 56px 27px;

    .course-name {
      ul {
        // width: 65%;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          //justify-content: space-between;
          list-style: none;
          height: 30px;
          line-height: 30px;
          border-bottom: 1px solid #e4e4e4;
          font-size: 12px;
          margin: 10px 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 企业端 外部学员管理
const externalStudentsManagementRouter = {
  path: '/external/students',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '',
    icon: null,
    roles: [USER_TYPE.organization.val],
  },
  // alwaysShow: true,
  children: [
    {
      path: '',
      component: () => import('@/views/company/externalStudents'),
      name: 'externalStudents',
      meta: { title: '外部人员管理', icon: null },
    },
    {
      path: 'add',
      component: () => import('@/views/company/externalStudents/add'),
      name: 'externalStudentsAdd',
      hidden: true,
      meta: { title: '添加外部人员', icon: null, noCache: true, activeMenu: '/external/students' },
    },
    {
      path: 'detail',
      component: () => import('@/views/company/externalStudents/detail'),
      name: 'externalStudentsDetail',
      hidden: true,
      meta: { title: '外部人员管理', icon: null, noCache: true, activeMenu: '/external/students' },
    },
  ],
}

export default externalStudentsManagementRouter

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.toBack } },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          class:
            _vm.textResult.result === _vm.EXAM.fail.val
              ? _vm.EXAM.fail.val
              : _vm.EXAM.pass.val,
        },
        [
          _vm._v(
            _vm._s(
              _vm.textResult.result === _vm.EXAM.fail.val
                ? "很遗憾，测试结果不通过"
                : "恭喜，您通过了测试！"
            )
          ),
        ]
      ),
      _c("div", { staticClass: "second" }, [
        _c("div", [_vm._v("测试课程：" + _vm._s(_vm.textResult.testName))]),
        _c("div", [_vm._v("测试时间：" + _vm._s(_vm.textResult.finishTime))]),
        _c("div", [_vm._v("测试分数：" + _vm._s(_vm.textResult.score))]),
      ]),
      _c("ul", [
        _vm._m(0),
        _c("li", [
          _c("div", { staticClass: "title" }, [_vm._v("单选题")]),
          _c(
            "ul",
            _vm._l(_vm.singleChoiceList, function (item, index) {
              return _c("li", { key: index, class: item.result_type }, [
                _vm._v(_vm._s(index + 1)),
              ])
            }),
            0
          ),
        ]),
        _c("li", [
          _c("div", { staticClass: "title" }, [_vm._v("判断")]),
          _c(
            "ul",
            _vm._l(_vm.TrueFalseList, function (item, index) {
              return _c("li", { key: index, class: item.result_type }, [
                _vm._v(_vm._s(index + 1)),
              ])
            }),
            0
          ),
        ]),
        _c("li", [
          _c("div", { staticClass: "title" }, [_vm._v("多选题")]),
          _c(
            "ul",
            _vm._l(_vm.MultiChoiceList, function (item, index) {
              return _c("li", { key: index, class: item.result_type }, [
                _vm._v(_vm._s(index + 1)),
              ])
            }),
            0
          ),
        ]),
        _c("li", [
          _c("div", { staticClass: "title" }, [_vm._v("案例题")]),
          _c(
            "ul",
            _vm._l(_vm.exampleList, function (item, index) {
              return _c("li", { key: index, class: item.result_type }, [
                _vm._v(_vm._s(index + 1)),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "title" }, [_vm._v("测试详情")]),
      _c("div", { staticClass: "example" }, [
        _c("div", { staticClass: "example1" }, [
          _c("div"),
          _c("div", [_vm._v("正确")]),
        ]),
        _c("div", { staticClass: "example2" }, [
          _c("div"),
          _c("div", [_vm._v("错误")]),
        ]),
        _c("div", { staticClass: "example3" }, [
          _c("div"),
          _c("div", [_vm._v("未答")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
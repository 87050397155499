module.exports = {
  jwt: {
    jwt_secret: 'zxjy2024',
    jwt_expires: 60,
  },
  defaultImgPath: 'chemical/custom/', // 默认图片地址
  ImgHost: 'https://res.cyedtech.cn/',
  // 学员端图片存储路径
  studentImgPath: {
    headImgPath: 'chemical/stu/headImg/', // 头像存储
    idCardImgPath: 'chemical/stu/idCard/', // 身份证存储
    otherImgPath: 'chemical/stu/other/', // 其他照片存储地址
    videoImgPath: 'chemical/stu/videoImg/', // 视频图片存储
  },
  org: {
    attachPath: 'chemical/organization/attach/', // 机构附件
    examAttachPath: 'chemical/examResult/attach/', // 考试结果附件
  },
  gov: {
    organizationFile: 'chemical/organization/file/', // 机构端 新增机构文件地址
    examinerHeadImg: 'chemical/examiner/headImg/', // 考评员证件照图片地址
    examinerAttachImg: 'chemical/examiner/attachImg/', // 考评员附件图片地址
    supervisorHeadImg: 'chemical/supervisor/headImg/', // 督导员证件照图片地址
    supervisorAttachImg: 'chemical/supervisor/attachImg/', // 督导员附件图片地址
  },
}

<template>
  <div class="wrap-border flex flex-row  align-center">
    <div
      v-for="(item,index_3) in selectList"
      :key="index_3"
      :class="[item.checked?'select-bg c-white':'',index_3===0?'':'special-border']"
      class="pointer text-center flex-1 fs12 item"
      @click="handleClick(item)"
    >
      {{ item.txt }}
    </div>
  </div>
</template>

<script>
import { TIME_SELECTION } from '@/const/sys'

export default {
  name: 'SelectWeekMonthYear',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectList: [],
    }
  },
  created() {
    this.selectList = JSON.parse(JSON.stringify(Object.values(TIME_SELECTION)))
    console.log()
  },
  methods: {
    handleClick(item) {
      this.selectList.forEach(i => {
        i.checked = item.val === i.val
      })
      const _obj = {
        val: item.val,
        timeRange: item.dateTime,
        id: this.id,
      }
      this.$emit('changeSelect', _obj)
    },
  },
}
</script>

<style lang="scss" scoped>
.special-border {
  border-left: 1px solid #1A3C82;
}

.wrap-border {
  width: 204px;
  height: 21px;
  border: 1px solid #1A3C82
}

.item {
  height: 21px;
  line-height: 21px;
}

.select-bg {
  background-color: #1A3C82;;
}
</style>

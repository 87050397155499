var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "destroy-on-close": true,
        visible: _vm.dialogVisible,
        title: "添加员工",
        width: "1300px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
        open: _vm.open,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "600px" } },
        [
          _c("HSearch", {
            ref: "searchForm",
            attrs: {
              "search-data": _vm.searchData,
              "search-form": _vm.searchForm,
              "search-handle": _vm.searchHandle,
              "search-rules": _vm.searchRules,
              that: _vm.that,
              "label-width": "80px",
              size: "medium",
            },
            on: { selectionChange: _vm.selectionChange },
          }),
          _c("HTable", {
            staticClass: "el_tables",
            attrs: {
              "is-handle": true,
              "is-index": true,
              "is-selection": true,
              loading: _vm.tableLoading,
              "table-cols": _vm.tableCols,
              "table-data": _vm.tableData,
              "table-page": _vm.tablePage,
              that: _vm.that,
            },
            on: { selectionChange: _vm.selectionChange },
          }),
          _c("div", { staticClass: "buttons" }, [
            _c("div", { staticClass: "buttons-right" }, [
              _c(
                "div",
                { staticClass: "buttons-right-center" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitBtn },
                    },
                    [_vm._v("确认添加")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// 学员获取已报名课程表格信息
import request from '@/utils/request'

export function queryStudentCourseDetails(data) {
  return request({
    url: '/record/queryStudentCourseDetails',
    method: 'get',
    params: {
      'bizLine': data.bizLine,
      'expiredType': data.expiredType,
      'module': 'pc',
    },
  })
}

// 学生端 获取报名课件列表数据
export function getCourseWareDetails(enrollId, courseId) {
  return request({
    url: '/record/courseWareDetails',
    method: 'get',
    params: {
      enrollId,
      courseId,
    },
  })
}

// 机构端 学习管理 学习详情
export function getOrgRecordDetails(courseId, enrollId) {
  return request({
    url: `/record/orgRecordDetails`,
    method: 'get',
    params: {
      courseId,
      enrollId,
    },
  })
}

// 学生端 获取单个课件详情
export function getCourseWareDetail(enrollId, courseProfileId) {
  const _data = {
    enrollId: enrollId,
    courseProfileId: courseProfileId,
    area: process.env.VUE_APP_AREA,
  }
  return request({
    url: '/record/getCourseWareDetail',
    method: 'post',
    data: _data,
  })
}

/**
 * 更新学习记录
 * @param  data
 * @returns {AxiosPromise}
 */
export function updateRecord(data) {
  return request({
    url: '/record/updateRecord',
    method: 'post',
    data,
  })
}

// 初始化视频记录
export function setInit(data) {
  return request({
    url: `/record/initRecord?courseProfileId=${data.courseProfileId}&enrollId=${data.enrollId}`,
    method: 'get',
    data,
  })
}

// 添加抓拍照片
export function sendPhoto(data) {
  return request({
    url: '/record/addSnapShot',
    method: 'post',
    data,
  })
}

// 当前视频学习结束
export function endSendTime(data) {
  return request({
    url: '/record/completeRecord',
    method: 'post',
    data,
  })
}

// 检查是否实名通过
export function checkVerifyResult(data) {
  return request({
    url: `/face/checkVerifyResult?stuId=${data}`,
    method: 'get',
  })
}

// 获取实名认证二维码
export function getAuthQrcode(stuId) {
  return request({
    url: `/face/qrcode?stuId=${stuId}`,
    responseType: 'arraybuffer',
    method: 'get',
  })
}

<template>
  <div>
    <div id="container" :style="{ height: height, width: width }" class="map">
      <div :style="{ height: height, width: width }" class="mask" />
    </div>
  </div>
</template>

<script>

import AMap from 'AMap'
import largeScreen from '@/styles/largeScreen.scss'
import { centerMapInfo } from '@/api/common'
import { MapLevel, ORG_USER_TYPE } from '@/const/sys'
import axios from 'axios'
import { getUserInfo } from '@/utils/auth'

export default {
  name: 'GaoDeMap',
  props: {
    id: {
      type: String,
      default: 'customMap',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },

  },
  data() {
    return {
      map: null,
      count: 0,
      clusterIndexSet: MapLevel,
      citys: {},
      parkList: [],
    }
  },
  computed: {
    largeScreen() {
      return largeScreen
    },
  },
  created() {
    const _provinceCode = '420000' // 湖北省code
    const mapAxios = axios({
      method: 'get',
      url: 'https://build-file.hbzdyx.com/scripts/echart/province/' + _provinceCode + '.json',
    })
    this.init(mapAxios)
  },
  methods: {
    init(mapAxios) {
      const _this = this
      const _center = [112.271301, 30.987527] // 湖北省正中心点经纬度
      // const _center = [114.298572, 30.584355] // 湖北省中心点经纬度
      const _zoom = 7.2 // 湖北省地图视距
      const _dataAxios = centerMapInfo()
      Promise.all([mapAxios, _dataAxios]).then(rst => {
        this.map = new AMap.Map('container', {
          zoom: getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_park_admin.val ? MapLevel.orgName.minZoom : _zoom,
          animateEnable: true,
          mapStyle: 'amap://styles/darkblue',
          center: _center,
          zooms: [3, 18],
        })
        if (getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_park_admin.val) {
          // 园区端做特殊处理
          this.map.on('complete', function() {
            const _bounds = _this.boundsFun(rst[1].data)
            _this.map.setBounds(_bounds)
            _this.map.setStatus({
              zoomEnable: false,
              dragEnable: false,
            })
          })
        }
        const _point = rst[1].data
        this.count = _point.length
        const { data } = rst[0]
        const _citys = {}
        data.features.forEach(item => {
          let _coordinate = []
          if (item.properties.centroid) {
            _coordinate = [item.properties.centroid[0], item.properties.centroid[1]]
          } else if (item.properties.center) {
            _coordinate = [item.properties.center[0], item.properties.center[1]]
          }
          if (_coordinate.length > 0) {
            _citys[item.properties.name] = {
              adcode: item.properties.adcode,
              center: _coordinate,
            }
          }
        })
        this.citys = _citys
        this.parkList = _point

        new AMap.IndexCluster(this.map, _point, {
          renderClusterMarker: this._renderClusterMarker,
          clusterIndexSet: this.clusterIndexSet,
        })
      })
    },
    _renderClusterMarker(context) {
      const _this = this
      const clusterData = context.clusterData // 聚合中包含数据
      const index = context.index // 聚合的条件
      const count = context.count // 聚合中点的总数
      const marker = context.marker // 聚合点标记对象
      const styleObj = this.getStyle(context)
      const div = document.createElement('div')
      // 自定义点标记样式
      if (index.mainKey !== MapLevel.orgName.val) {
        div.style.height = (index.mainKey === MapLevel.city.val ? styleObj.size : '30') + 'px'
        // 自定义点击事件
        context.marker.on('click', function(e) {
          const curZoom = _this.map.getZoom() + 3
          const arr = []
          const uniqueParksCount = new Set(context.clusterData.map(i => i.park)).size
          if (uniqueParksCount === 1 && index.mainKey === MapLevel.city.val) {
            const _centerPoint = _this.getCenterPoint(context.clusterData)
            _this.map.setZoomAndCenter(curZoom, _centerPoint)
          } else {
            const _bounds = _this.boundsFun(context.clusterData)
            _this.map.setBounds(_bounds)
          }
        })
      } else {
        context.marker.on('click', function(e) {
          console.log('点击的公司级别')
        })
      }
      div.className = 'amap-cluster'
      div.style.backgroundColor = styleObj.bgColor
      div.style.width = styleObj.size + 'px'
      div.style.border = 'solid 1px ' + styleObj.borderColor
      div.style.borderRadius = styleObj.size + 'px'
      div.innerHTML = styleObj.text
      div.style.color = styleObj.color
      div.style.textAlign = styleObj.textAlign
      div.style.boxShadow = styleObj.boxShadow
      const im = document.createElement('img') // 创建图片
      // 图片设置成和div一样大小
      im.style.width = '23px'
      im.style.height = '30px'
      if (index.mainKey === MapLevel.orgName.val || index.mainKey === MapLevel.park.val) {
        div.innerHTML = '<div id="kkkk">' + styleObj.text + '</div>'
        const _img = index.mainKey === MapLevel.orgName.val ? 'park' : 'orgName'
        im.src = 'https://res.cyedtech.cn/images/' + _img + '.png'
        div.insertBefore(im, div.children[0])
        div.style.backgroundColor = ''
        div.style.border = ''
        div.style.borderRadius = ''
        div.style.boxShadow = ''
        div.className = 'amap-clusters'
        div.style.maxWidth = styleObj.size + 100 + 'px'
      }
      context.marker.setContent(div)
      // 自定义聚合点标记显示位置
      const position = this.getPosition(context)
      if (position) {
        context.marker.setPosition(position)
      }
      context.marker.setAnchor('bottom-left')
    },
    boundsFun(arr) {
      const { maxLng, minLng, maxLat, minLat } = arr.reduce((acc, { lnglat: { lng, lat }}) => ({
        maxLng: Math.max(acc.maxLng, lng),
        minLng: acc.minLng === undefined ? lng : Math.min(acc.minLng, lng),
        maxLat: Math.max(acc.maxLat, lat),
        minLat: acc.minLat === undefined ? lat : Math.min(acc.minLat, lat),
      }), { maxLng: Number.NEGATIVE_INFINITY, minLng: undefined, maxLat: Number.NEGATIVE_INFINITY, minLat: undefined })
      const lngGap = (maxLng - minLng) / 4
      const latGap = (maxLat - minLat) / 4
      const min = new AMap.LngLat(minLng - lngGap, minLat - latGap)
      const max = new AMap.LngLat(maxLng + lngGap, maxLat + latGap)
      const bounds = new AMap.Bounds(min, max)
      return bounds
    },
    getCenterPoint(arr) {
      const points = arr.map(i => `${i.lnglat.lat},${i.lnglat.lng}`)
      const tmp_center = this.getPointsCenter(points)
      const default_point = new AMap.LngLat(tmp_center[1], tmp_center[0])
      return default_point
    },
    getPointsCenter(points) {
      const pointsFiltered = points.filter(point => point)
      const point_num = pointsFiltered.length
      const [X, Y, Z] = pointsFiltered.reduce(
        ([xAcc, yAcc, zAcc], point) => {
          const [lat, lng] = point.split(',').map(Number).map(coord => coord * Math.PI / 180)
          return [
            xAcc + Math.cos(lat) * Math.cos(lng),
            yAcc + Math.cos(lat) * Math.sin(lng),
            zAcc + Math.sin(lat),
          ]
        },
        [0, 0, 0],
      ).map(coord => coord / point_num)
      const tmp_lng = Math.atan2(Y, X)
      const tmp_lat = Math.atan2(Z, Math.sqrt(X ** 2 + Y ** 2))
      return [tmp_lat * 180 / Math.PI, tmp_lng * 180 / Math.PI]
    },
    getPosition(context) {
      const key = context.index.mainKey
      const dataItem = context.clusterData && context.clusterData[0]
      const districtName = dataItem[key]
      if (!this.citys[districtName]) {
        return null
      }
      const center = this.citys[districtName].center
      const centerLnglat = new AMap.LngLat(center[0], center[1])
      return centerLnglat
    },
    getStyle(context) {
      const clusterData = context.clusterData // 聚合中包含数据
      const index = context.index // 聚合的条件
      const count = context.count // 聚合中点的总数
      const marker = context.marker // 聚合绘制点 Marker 对象
      let text = clusterData[0][index['mainKey']]
      let size = Math.round(30 + Math.pow(count / this.parkList.length, 1 / 5) * 70)
      //  只有市级别显示下面有几个园区，园区级别不显示下面有几个园区
      if (index.mainKey === MapLevel.city.val) {
        const uniqueParksCount = new Set(context.clusterData.map(i => i.park)).size
        const extra = '<span class="showCount">' + uniqueParksCount + '个</span>'
        text = '<span class="showName">' + text + '</span>'
        text += extra
      } else {
        size = 12 * text.length + 20
      }
      const style = {
        bgColor: 'rgba(' + MapLevel[index.mainKey].color + ',.8)',
        borderColor: 'rgba(' + MapLevel[index.mainKey].color + ',1)',
        text: text,
        size: size,
        // index: i,
        color: '#ffffff',
        textAlign: 'center',
        boxShadow: '0px 0px 5px rgba(0,0,0,0.8)',
      }
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.map {
  background-color: transparent !important;
  background-image: none !important;
}

.mask {
  z-index: 1;
  position: absolute;
  pointer-events: none;
  box-shadow: inset 5px 5px 150px 5px #000;
}
</style>

import FrontsLayout from '@/layout/fronts'

const statisticRouter = {
  path: '/statistic',
  component: FrontsLayout,
  hidden: true,
  children: [
    {
      path: '/',
      component: () => import('@/views/statistic/index'),
      name: 'statistic',
      meta: { title: '' },
    },
  ],
}

export default statisticRouter

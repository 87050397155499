var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap-border flex flex-row  align-center" },
    _vm._l(_vm.selectList, function (item, index_3) {
      return _c(
        "div",
        {
          key: index_3,
          staticClass: "pointer text-center flex-1 fs12 item",
          class: [
            item.checked ? "select-bg c-white" : "",
            index_3 === 0 ? "" : "special-border",
          ],
          on: {
            click: function ($event) {
              return _vm.handleClick(item)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.txt) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slider-check-box" }, [
    _c(
      "div",
      { staticClass: "slider-check", class: _vm.rangeStatus ? "success" : "" },
      [
        _c("i", {
          class: _vm.rangeStatus ? _vm.successIcon : _vm.startIcon,
          on: { mousedown: _vm.rangeMove },
        }),
        _vm._v(
          " " + _vm._s(_vm.rangeStatus ? _vm.successText : _vm.startText) + " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center footer" }, [
    _vm._v("Copyright © 2021 Zhongde Inc. 保留所有权利。鄂ICP备2021018676号-1"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
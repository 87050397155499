<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    title="添加考生"
    width="1300px"
    @close="close"
    @open="open"
  >
    <div style="height: 600px">
      <HSearch
        ref="searchForm"
        :search-data="searchData"
        :search-form="searchForm"
        :search-handle="searchHandle"
        :search-rules="searchRules"
        :that="that"
        label-width="80px"
        size="medium"
        @selectionChange="selectionChange"
      />
      <HTable
        :is-handle="true"
        :is-index="true"
        :is-selection="true"
        :loading="tableLoading"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-page="tablePage"
        :that="that"
        class="el_tables"
        @selectionChange="selectionChange"
      />
      <OrgLearningDialog :flag-visible.sync="dialogShow" :stu-info="stuInfo" />
      <div class="buttons">
        <div class="buttons-right">
          <div class="buttons-right-center">
            <el-button class="mr10" type="primary" @click="submitBtn">确认分配</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
// 学习详情弹框
import OrgLearningDialog from '@/views/components/studyDetails'
import Tools from '@/utils/tools'
import { mapState } from 'vuex'
import { enrollExamBind, queryAssignStu } from '@/api/company/examPlan'
import { JOB_POSITION, STU_TYPE } from '@/const/sys'

export default {
  name: 'AllotStuDialog',
  components: {
    HSearch,
    HTable,
    OrgLearningDialog,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    planInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    const _JOB_POSITION = Tools.mapToSelctList(JOB_POSITION)
    return {
      // 单个学生信息
      stuInfo: {},
      // 表格选中数据
      tableActiveList: [],
      dialogVisible: false,
      that: this,
      tableLoading: false,
      // 学习详情弹框显示影藏
      dialogShow: false,
      selectArr: [],
      searchData: {
        trainningName: '',
        stuName: '',
        phone: '',
        job: '',
        organization: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'trainningName',
          placeholder: '请输入所属培训计划',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'stuName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'phone',
          placeholder: '请输入手机号',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'job',
          placeholder: '请选择工作岗位',
          options: _JOB_POSITION,
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'organization',
          placeholder: '请输入所属单位',
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '所属培训计划',
          prop: 'trainningName',
        },
        {
          label: '培训课程',
          prop: 'cateName',
        },
        {
          label: '人员类别',
          prop: 'stuType',
          formatter: row => Tools.formatDict(STU_TYPE, row.stuType),
        },
        {
          label: '姓名',
          prop: 'stuName',
        },
        {
          label: '身份证号',
          prop: 'idCard',
        },
        {
          label: '手机号',
          prop: 'phone',
        },
        {
          label: '所属单位',
          prop: 'organization',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
      ],
      tablePage: {
        pageSize: 50,
        size: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
    }
  },
  computed: {
    ...mapState('plan', {
      examSessionsId: state => state.examSessionsId,
      examSessionAddr: state => state.examSessionAddr,
    }),
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
  },
  created() {
  },
  methods: {
    // 获取可分配学员数据
    async getTableData() {
      this.tableLoading = true
      const _searchData = JSON.parse(JSON.stringify(this.searchData))
      const query = {
        bizCondition: _searchData,
        pageCondition: this.tablePage,
      }
      query.bizCondition.examSessionsId = this.examSessionsId
      const { data } = await queryAssignStu(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 打开弹窗时
    async open() {
      try {
        await this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    // 关闭弹窗
    close() {
      this.dialogVisible = false
      this.$emit('update:flagVisible', false)
    },
    // 搜索
    searchFormClick() {
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 表格勾选中
    selectionChange(selection) {
      this.tableActiveList = selection
    },
    // 确认分配按钮
    async submitBtn() {
      const list = this.tableActiveList
      const stuInfo = {}
      const stuNoList = []
      if (list.length === 0) {
        return this.$alert('请选择分配学员', '提示', {
          confirmButtonText: '确定',
        })
      } else {
        const _data = {
          examSessionAddr: this.examSessionAddr,
          examSessionsId: this.examSessionsId,
          enrollSubmissionVoList: this.tableActiveList,
        }
        const data = await enrollExamBind(_data)
        this.$message.success('操作成功')
        await this.getTableData()
        await this.$parent.getTableData()
        this.close()
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@import "../../../components/styles/index";

.el_tables {
  max-height: 450px;
  overflow-y: scroll;
}

.buttons {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0
}

.buttons-right {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

/deep/ .ces-pagination {
  margin-bottom: 0;
}

/deep/ .ces-handle {
  margin: 0;
  padding: 0;
}
</style>

// 设置文件

const state = {
  courseId: null,
  enrollId: null,
  // 试卷编号
  paperNo: null,
  // 试卷类型
  paperType: null,
  stuNo: null,
  orgId: null,
  sheetNo: null,
  // 考试内容
  paperInfo: null,
  // 考试结果
  textResult: null,
}
const mutations = {
  saveCourseId: (state, courseId) => {
    state.courseId = courseId
    // console.log(count)
  },
  saveEnrollId: (state, enrollId) => {
    state.enrollId = enrollId
    // console.log(count)
  },
  saveCourseName: (state, courseName) => {
    state.courseName = courseName
  },
  savePaperNo: (state, paperNo) => {
    state.paperNo = paperNo
    // console.log(paperNo)
  },
  savePaperType: (state, paperType) => {
    state.paperType = paperType
  },
  saveStuNo: (state, stuNo) => {
    state.stuNo = stuNo
  },
  saveOrgId: (state, orgId) => {
    state.orgId = orgId
  },
  saveSheetNo: (state, sheetNo) => {
    state.sheetNo = sheetNo
  },
  saveTextResult: (state, textResult) => {
    state.textResult = textResult
  },
  savePaperInfo: (state, paperInfo) => {
    state.paperInfo = paperInfo
  },
  saveDicKey: (state, dictKey) => {
    state.dictKey = dictKey
  },
}
const getter = {
  count: state => state,
}

export default {
  namespaced: true,
  state,
  mutations,
  getter,
}


import moment from '@/utils/moment'
// 课程状态
export const COURSE = {
  finish: {
    val: 'finish',
    txt: '已完成',
  },
  init: {
    val: 'init',
    txt: '未激活',
  },
  active: {
    val: 'active',
    txt: '已激活',
  },
  lock: {
    val: 'lock',
    txt: '禁用',
  },
}
// 培训状态
export const ACTIVE_STATUS = {
  init: {
    val: 'init',
    txt: '待激活',
  },
  active: {
    val: 'active',
    txt: '已激活',
  },
  lock: {
    val: 'lock',
    txt: '冻结',
  },
  finish: {
    val: 'finish',
    txt: '学习完成',
  },
}
// 新学习进度
export const RATE_LEARNING = [
  {
    'value': 'notFinish',
    'label': '未完成',
    'children': [
      {
        'value': 'init',
        'label': '未开始',
      },
      {
        'value': 'studying',
        'label': '学习中',
      },
      // {
      //   'value': 'error',
      //   'label': '异常',
      // },
    ],
  },
  {
    'value': 'finish',
    'label': '已完成',
  },
]

// 学习进度
export const LEARNING_PROCESS = {
  init: {
    val: 'init',
    txt: '未开始',
  },
  studying: {
    val: 'studying',
    txt: '学习中',
  },
  finish: {
    val: 'finish',
    txt: '已完成',
  },
  error: {
    val: 'error',
    txt: '异常',
  },
}
// 审核状态
export const AUDIT = {
  init: {
    val: 'init',
    txt: '未审核',
  },
  org_audited: {
    val: 'org_audited',
    txt: '待复审',
  },
  auditing: {
    val: 'auditing',
    txt: '待复审',
  },
  normal: {
    val: 'normal',
    txt: '正常',
  },
  error: {
    val: 'error',
    txt: '异常',
  },
}

// 审核状态(去重，将多个待复审状态调整为一个，供下拉框查询使用)
export const AUDIT_DISTINCT = {
  init: {
    val: 'init',
    txt: '未审核',
  },
  normal: {
    val: 'normal',
    txt: '正常',
  },
  error: {
    val: 'error',
    txt: '异常',
  },
}
// 监管端审核状态
export const examStatus = {
  init: {
    val: 'init',
    txt: '未发布',
  },
  active: {
    val: 'active',
    txt: '已发布',
  },
}

// 监管端审核状态
export const GovAuditStatus = {
  DEFAULT: {
    val: 'null',
    txt: '未提交',
  },
  init: {
    val: 'init',
    txt: '待审核',
  },
  pass: {
    val: 'pass',
    txt: '审核通过',
  },
  fail: {
    val: 'fail',
    txt: '审核不通过',
  },
}

// 培训计划状态
export const TRAINNING_STATUS = {
  draft: {
    val: 'draft',
    txt: '未发布',
  },
  commit: {
    val: 'commit',
    txt: '已发布',
  },
}

// 课程配置类型 排序涉及企业端home页面大类展示顺序
export const BIZLINE = {
  three_types: {
    val: 'three_types',
    txt: '三类人员安全培训',
  },
  third_training: {
    val: 'third_training',
    txt: '三级安全培训',
  },
  opt_training: {
    val: 'opt_training',
    txt: '危险化学品操作证培训',
  },
  chemical_specialty: {
    val: 'chemical_specialty',
    txt: '危化专业培训',
  },
  special_worker: {
    val: 'special_worker',
    txt: '应急特种作业工种',
  },
  other_training: {
    val: 'other_training',
    txt: '其他培训',
  },
}

// 新继类型
export const NEWCON = {
  seven: {
    val: 'seven',
    txt: '施工现场专业人员',
  },
  special: {
    val: 'special',
    txt: '特种作业人员',
  },
  safety: {
    val: 'safety',
    txt: '建筑施工安管人员',
  },
}

// 用户类型
export const USER_TYPE = {
  government: {
    val: 'government',
    txt: '监管',
  },
  organization: {
    val: 'organization',
    txt: '机构',
  },
  student: {
    val: 'student',
    txt: '学员',
  },
  exam: {
    val: 'exam',
    txt: '考核点',
  },
}
// 机构类型
export const ORG_TYPE = {
  organization: {
    val: 'organization',
    txt: '培训机构',
  },
  government_city: {
    val: 'government_city',
    txt: '市级监管单位',
  },
  government_province: {
    val: 'government_province',
    txt: '省级监管单位',
  },
  government_admin: {
    val: 'government_admin',
    txt: '超级监管单位',
  },
  exam: {
    val: 'exam',
    txt: '考核点端',
  },
}
// 机构用户类型
export const ORG_USER_TYPE = {
  admin: {
    val: 'admin',
    txt: '机构负责人',
  },
  teacher: {
    val: 'teacher',
    txt: '教务老师',
  },
  teacherAdmin: {
    val: 'teacherAdmin',
    txt: '教务负责人',
  },
  gov_province_admin: {
    val: 'gov_province_admin',
    txt: '省监管负责人',
  },
  gov_city_admin: {
    val: 'gov_city_admin',
    txt: '市监管端负责人',
  },
  gov_park_admin: {
    val: 'gov_park_admin',
    txt: '园区端负责人',
  },
  gov_province_audit: {
    val: 'gov_province_audit',
    txt: '省监管审核员',
  },
  gov_city_audit: {
    val: 'gov_city_audit',
    txt: '市监管审核员',
  },
  gov_city_examiner: {
    val: 'gov_city_examiner',
    txt: '考评员',
  },
  gov_city_supervisor: {
    val: 'gov_city_supervisor',
    txt: '督导员',
  },
  gov_admin: {
    val: 'gov_admin',
    txt: '管理员',
  },
  exam_admin: {
    val: 'exam_admin',
    txt: '考核点负责人',
  },
  exam: {
    val: 'exam',
    txt: '考核',
  },
}
//
export const ORG_USER_STATUS = {
  active: {
    val: 'active',
    txt: '正常',
  },
  lock: {
    val: 'lock',
    txt: '锁定',
  },
  restricted: {
    val: 'restricted',
    txt: '受限',
  },
  cancelled: {
    val: 'cancelled',
    txt: '注销',
  },
  DEFAULT: {
    val: 'active',
    txt: '正常',
  },
}
// 考场状态
export const EXAM_ROOM_STATUS = {
  active: {
    val: 'active',
    txt: '正常',
  },
  lock: {
    val: 'lock',
    txt: '禁用',
  },
}
export const USER_STATUS = {
  active: {
    val: 'active',
    txt: '正常',
  },
  lock: {
    val: 'lock',
    txt: '锁定',
  },
  restricted: {
    val: 'restricted',
    txt: '受限',
  },
  cancelled: {
    val: 'cancelled',
    txt: '注销',
  },
  DEFAULT: {
    val: 'active',
    txt: '正常',
  },
}
// 支付方式
export const PAY_TYPE = {
  init: {
    val: 'init',
    txt: '待支付',
  },
  online: {
    val: 'online',
    txt: '已线上支付',
  },
  offline: {
    val: 'offline',
    txt: '线下支付',
  },
}

export const GENDER = {
  0: {
    val: '0',
    txt: '女',
  },
  1: {
    val: '1',
    txt: '男',
  },
}
export const DOC_TYPE = {
  qualified: {
    val: 'qualified',
    txt: '合格证',
  },
  certificate: {
    val: 'certificate',
    txt: '准考证',
  },
}
// 培训状态
export const TRAINNING_TYPE = {
  'online-study': {
    val: 'online-study',
    txt: '线上培训',
  },
  offline: {
    val: 'offline',
    txt: '线下培训',
  },
  // 'online-study_online-exam': {
  //   val: 'online-study_online-exam',
  //   txt: '在线学习+在线测试',
  // },
}
export const ORG_APPPROVE_STATUS = {
  DEFAULT: {
    val: 'null',
    txt: '未上报',
  },
  init: {
    val: 'init',
    txt: '待审核',
  },
  success: {
    val: 'success',
    txt: '已备案',
  },
  fail: {
    val: 'fail',
    txt: '审核不通过',
  },
}
// 审核状态
export const EXAM = {
  init: {
    val: 'init',
    txt: '待审核',
  },
  pass: {
    val: 'pass',
    txt: '审核通过',
  },
  fail: {
    val: 'fail',
    txt: '审核未通过',
  },
}
// 试卷题目类型
export const QUESTION_TYPE = {
  SingleChoice: {
    val: 'SingleChoice',
    txt: '单选题',
  },
  MultiChoice: {
    val: 'MultiChoice',
    txt: '多选题',
  },
  TrueFalse: {
    val: 'TrueFalse',
    txt: '判断题',
  },
  example: {
    val: 'example',
    txt: '案例题',
  },
  Completion: {
    val: 'Completion',
    txt: '问答题',
  },
  SingleChoiceWithPic: {
    val: 'SingleChoiceWithPic',
    txt: '单选图片题',
  },
  MultiChoiceWithPic: {
    val: 'MultiChoiceWithPic',
    txt: '多选图片题',
  },
}
// 试卷类型
export const PAPER_TYPE = {
  practice: {
    val: 'practice',
    txt: '练习',
  },
  mock: {
    val: 'mock',
    txt: '模拟',
  },
}

// 答题结果类型
export const ANSWER_TYPE = {
  noanswer: {
    val: 'noanswer',
    txt: '未答',
  },
  right: {
    val: 'right',
    txt: '正确',
  },
  wrong: {
    val: 'wrong',
    txt: '错误',
  },
}

// 计划状态
export const PLAN_STATES = {
  draft: {
    val: 'draft',
    txt: '未提交',
  },
  commit: {
    val: 'commit',
    txt: '已提交',
  },
}

// 学历
export const EDUCATION = {
  junior: {
    label: '初中',
    value: '初中',
  },
  vocational: {
    label: '中职(含技工学校)',
    value: '中职(含技工学校)',
  },
  specialist: {
    label: '专科(含高职和技师学院)',
    value: '专科(含高职和技师学院)',
  },
  regular: {
    label: '本科及以上学历',
    value: '本科及以上学历',
  },
  other: {
    label: '其他',
    value: '其他',
  },
}

export const Course_type = {
  major: {
    val: 'major',
    txt: '专业课',
  },
  addMajor: {
    val: 'addMajor',
    txt: '专业课',
  },
  mainMajor: {
    val: 'mainMajor',
    txt: '专业课',
  },
  public: {
    val: 'public',
    txt: '公共课',
  },
  addPublic: {
    val: 'addPublic',
    txt: '公共课',
  },
  mainPublic: {
    val: 'mainPublic',
    txt: '公共课',
  },
}

// 上报状态
export const CUSTOM_STATUS = {
  init: {
    val: 'init',
    txt: '未上报',
  },
  upload: {
    val: 'upload',
    txt: '已上报',
  },
}

export const USERINFO = 'userInfo'

export const SYS = {
  token: 'mytoken', // 令牌
  userInfo: 'userInfo', // 用户信息
}
//  排序
export const SORTTIME = {
  createTime: {
    val: 'createTime',
    txt: '按课程报名日期排序',
    sortRule: 'desc',
  },
  expiredTime: {
    val: 'expiredTime',
    txt: '按课程截至日期排序',
    sortRule: 'desc',
  },
}

//  考试类型
export const EXAM_TYPE = {
  'theory': {
    val: 'theory',
    txt: '理论考试',
  },
  'operation': {
    val: 'operation',
    txt: '实操考试',
  },
}
//  考试结果类型
export const EXAM_RESULT_TYPE = {
  'waitExam': {
    val: 'waitExam',
    txt: '待考',
  },
  'giveupExam': {
    val: 'giveupExam',
    txt: '弃考',
  },
  'pass': {
    val: 'pass',
    txt: '合格',
  },
  'fail': {
    val: 'fail',
    txt: '不合格',
  },
}
//  考试类型
export const TAB_TYPE = {
  'theory': {
    val: 'theory',
    txt: '理论',
  },
  'operation': {
    val: 'operation',
    txt: '实操',
  },
}
//  考试结果/状态
export const EXAM_RESULT_STATUS = {
  'init': {
    val: 'init',
    txt: '-',
  },
  'pass': {
    val: 'pass',
    txt: '合格',
  },
  'fail': {
    val: 'fail',
    txt: '不合格',
  },
}
//  证书状态类型
export const CERTIFICATE_STATUS = {
  'init': {
    val: 'init',
    txt: '未发证',
  },
  'active': {
    val: 'active',
    txt: '已发证',
  },
  'cancel': {
    val: 'cancel',
    txt: '注销',
  },
}
//  考试结果类型
export const TESE_RESULT_STATUS = {
  '1': {
    val: '1',
    txt: '不合格',
  },
  '2': {
    val: '2',
    txt: '合格',
  },
}
//  考试进行类型
export const TESE_SCHEDULE_STATUS = {
  '1': {
    val: '1',
    txt: '未开始',
  },
  '2': {
    val: '2',
    txt: '进行中',
  },
  '3': {
    val: '3',
    txt: '已完成',
  },
}
// 用户登录
export const LOGIN_TYPE = {
  ORGANIZATION: {
    val: 'organization',
    txt: '企业端',
  },
  STUDENT: {
    val: 'student',
    txt: '学员端',
  },
  // EXAM: {
  //   val: 'exam',
  //   txt: '监管端',
  // },
}
// 考官类型
export const EXAMINER_TYPE = {
  examiner: {
    val: 'examiner',
    txt: '考评员',
  },
  supervisor: {
    val: 'supervisor',
    txt: '督导员',
  },
}
// 监管端用户登录
export const LOGIN_TYPE_GOV = {
  GOVERNMENT: {
    val: 'government',
    txt: '监管端',
  },
}

export const JOB_POSITION = {
  0: {
    val: '0',
    txt: '企业主要负责人',
  },
  1: {
    val: '1',
    txt: '专职安全管理人员',
  },
  2: {
    val: '2',
    txt: '特种作业人员',
  },
  3: {
    val: '3',
    txt: '班组长',
  },
  4: {
    val: '4',
    txt: '外部人员',
  },
  5: {
    val: '5',
    txt: '其他人员',
  },
}
export const DEP = {
  生产管理部: {
    val: '生产管理部',
    txt: '生产管理部',
  },
  研发中心: {
    val: '研发中心',
    txt: '研发中心',
  },
  采购管理部: {
    val: '采购管理部',
    txt: '采购管理部',
  },
  商务部: {
    val: '商务部',
    txt: '商务部',
  },
  其他部门: {
    val: '其他部门',
    txt: '其他部门',
  },
}
export const STU_TYPE = {
  1: {
    val: '1',
    txt: '内部人员',
  },
  2: {
    val: '2',
    txt: '外部人员',
  },
}

// 证书类型
export const CERTIFICATE_TYPE = {
  1: {
    val: '1',
    txt: '注册安全工程师',
  },
  2: {
    val: '2',
    txt: '安全管理资格证',
  },
  3: {
    val: '3',
    txt: '注册化工工程师',
  },
  4: {
    val: '4',
    txt: '化学检验工证书',
  },
  5: {
    val: '5',
    txt: '技师或高级技师',
  },
  6: {
    val: '6',
    txt: '化学制药工程师',
  },
  7: {
    val: '7',
    txt: '化学分析工程师',
  },
  8: {
    val: '8',
    txt: '注册环评工程师',
  },
}
// 年月日选择tab
export const TIME_SELECTION = {
  thisYear: {
    val: 'thisYear',
    txt: '今年',
    dateTime: {
      startTime: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    },
    checked: true,
  },
  threeMonths: {
    val: 'threeMonths',
    txt: '近3个月',
    dateTime: {
      startTime: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    },
    checked: false,
  },
  halfAYear: {
    val: 'halfAYear',
    txt: '近半年',
    dateTime: {
      startTime: moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    },
    checked: false,
  },
}
//
export const homeworkViolationsMap = {
  企业主要负责人: {
    val: '企业主要负责人',
    txt: '企业主要负责人',
    color: '#3779FD',
  },
  专职安全管理人员: {
    val: '专职安全管理人员',
    txt: '专职安全管理人员',
    color: '#00FFC3',
  },
  班组长: {
    val: '班组长',
    txt: '班组长',
    color: '#E64B5D',
  },
  特种作业人员: {
    val: '特种作业人员',
    txt: '特种作业人员',
    color: '#FE840F',
  },
  外部人员: {
    val: '外部人员',
    txt: '外部人员',
    color: '#8993D4',
  },
  其他人员: {
    val: '其他人员',
    txt: '其他人员',
    color: '#FECB0F',
  },
}
export const TRAINING_STATUS = {
  finish: {
    txt: '完成培训',
    val: 'finish',
    cnt: 'finishCnt',
  },
  training: {
    txt: '正在培训',
    val: 'training',
    cnt: 'trainingCnt',
  },
  untrained: {
    txt: '未培训',
    val: 'untrained',
    cnt: 'untrainedCnt',
  },
}
// 总体情况
export const ALL_TOTAL = {
  parkCnt: {
    val: 'parkCnt',
    txt: '园区',
    unit: '个',
    num: '',
  },
  companyCnt: {
    val: 'companyCnt',
    txt: '企业',
    unit: '个',
    num: '',
  },
  stuCnt: {
    val: 'stuCnt',
    txt: '人员',
    unit: '人',
    maxUnit: '万人',
    num: '',
  },
  trainningPlanCnt: {
    val: 'trainningPlanCnt',
    txt: '培训人次',
    unit: '次',
    maxUnit: '万次',
    num: '',
  },
}
export const AgeLevel = {
  age20lt: {
    val: 'age20lt',
    txt: '20岁以下',
    color: '#3779FD',
  },
  age20gte: {
    val: 'age20gte',
    txt: '20-29',
    color: '#8993D4',
  },
  age30gte: {
    val: 'age30gte',
    txt: '30-39',
    color: '#764F97',
  },
  age40gte: {
    val: 'age40gte',
    txt: '40-49',
    color: '#E64B5D',
  },
  age50gte: {
    val: 'age50gte',
    txt: '50-59',
    color: '#FE840F',
  },
  age60gte: {
    val: 'age60gte',
    txt: '60以上',
    color: '#FECB0F',
  },
}

export const Gender = {
  0: {
    val: '0',
    txt: '女',
    color: '#E64B5D',
  },
  1: {
    val: '1',
    txt: '男',
    color: '#3779FD',
  },
}

// 地图层级
export const MapLevel = {
  city: {
    val: 'city',
    txt: '市',
    minZoom: 2,
    maxZoom: 8.2,
    color: '55,121,253',
  },
  park: {
    val: 'park',
    txt: '园区',
    minZoom: 8.2,
    maxZoom: 11,
    color: '107,174,214',
  },
  orgName: {
    val: 'orgName',
    txt: '企业',
    minZoom: 11,
    maxZoom: 25,
    color: '107,174,214',
  },
}
// 监管端大屏首页展示课程
export const SUPERVISE_STATISTIC_COURSE = {
  three_types: {
    val: 'three_types',
    txt: '三类人员安全培训',
    children: [
      {
        name: '企业主要负责人初训/复训',
        imgUrl: 'three_types-0',
      },
      {
        name: '专职安全生产管理人员初训/复训',
        imgUrl: 'three_types-1',
      }, {
        name: '班组长初训/复训',
        imgUrl: 'three_types-2',
      },
    ],
  },
  third_training: {
    val: 'third_training',
    txt: '三级安全培训',
    children: [
      {
        name: '危化企业工厂级',
        imgUrl: 'third_training-0',
      },
      {
        name: '危化企业车间级',
        imgUrl: 'third_training-1',
      }, {
        name: '危化企业车间级',
        imgUrl: 'third_training-2',
      },
    ],
  },
  opt_training: {
    val: 'opt_training',
    txt: '危险化学品操作证培训',
    children: [
      {
        name: '胺基化工艺作业',
        imgUrl: 'opt_training-0',
      },
      {
        name: '氟化工艺作业',
        imgUrl: 'opt_training-1',
      }, {
        name: '光气及光气化工艺作业等多种操作证',
        imgUrl: 'opt_training-2',
      },
    ],
  },
}

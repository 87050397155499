var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "wrap" }, [
      _vm.examList.length === 0
        ? _c(
            "div",
            {
              staticClass:
                "empty-page  flex flex-column align-center justify-center",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../../assets/images/no-data-2.png"),
                },
              }),
              _c("div", { staticClass: "f-bold fs16 pt20" }, [
                _vm._v("暂无数据"),
              ]),
            ]
          )
        : _c("div", { staticClass: "have-course" }, [
            _c(
              "div",
              { staticClass: "course-list" },
              _vm._l(_vm.examList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "course-item flex flex-row align-center",
                  },
                  [
                    _vm._m(0, true),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column justify-between course-item-right flex-1",
                      },
                      [
                        _c("div", { staticClass: "course-name fs14" }, [
                          _c("div", [_vm._v(_vm._s(item.courseName))]),
                          _c("div", { staticClass: "pt6 c-7b7b7b" }, [
                            _vm._v(_vm._s(item.dicKeyStr)),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "text-right fs12 c-1082E3 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.goExam(item)
                              },
                            },
                          },
                          [_vm._v(" 进入练习 > ")]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img_wrap flex align-center justify-center" },
      [
        _c("img", {
          attrs: {
            src: require("../../../../assets/images/practice-icon.png"),
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
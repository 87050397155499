<template>
  <div>
    <div class="login-wrap flex align-center">
      <div class="home-center flex flex-row align-center justify-between">
        <div class="flex align-center c-white fs60">
          <div class="title-content ">信息化</div>
          <div class="title-content ">智慧化</div>
          <div class="title-content">科技化</div>
        </div>
        <div class="login bgc-white pt20 pl26 pr26 pb40">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            autocomplete="on"
            class="login-form"
            label-position="left"
          >
            <div class="form-content">
              <el-tabs v-model="activeName" :stretch="true" @tab-click="handleTabsClick">
                <el-tab-pane :name="LOGIN_TYPE_GOV.GOVERNMENT.val" label="监管端" />
              </el-tabs>
              <el-form-item class="input-bg" prop="username">
                <div class="svg-container">
                  <svg-icon class-name="icon-style" icon-class="user" />
                </div>
                <el-input
                  ref="username"
                  v-model="loginForm.username"
                  autocomplete="on"
                  name="username"
                  placeholder="用户名"
                  tabindex="1"
                  type="text"
                />
              </el-form-item>
              <el-tooltip v-model="capsTooltip" content="Caps lock is On" manual placement="right">
                <el-form-item class="input-bg" prop="password">
                  <div class="svg-container">
                    <svg-icon class-name="icon-style" icon-class="password" />
                  </div>
                  <el-input
                    :key="passwordType"
                    ref="password"
                    v-model="loginForm.password"
                    :type="passwordType"
                    autocomplete="on"
                    name="password"
                    placeholder="密码"
                    tabindex="2"
                    @blur="capsTooltip = false"
                    @keyup.native="checkCapslock"
                    @keyup.enter.native="handleLogin"
                  />
                  <span class="show-pwd" @click="showPwd">
                    <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" class-name="icon-style" />
                  </span>
                </el-form-item>
              </el-tooltip>
              <SliderCheck :error-fun="handleErrorFun" :success-fun="handleSuccessFun" />
              <el-button
                :loading="loading"
                style="width:100%;background-color: #0486FF;margin-top: 25px"
                type="primary"
                @click.native.prevent="handleLogin"
              >登录
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="home-center">
      <div>
        <div class="mb30 fs20 txt-wrap pb10 mt30">
          <span class="txt-wrap-content pb10 f-bold fs20">
            要闻资讯
          </span>
        </div>
        <div class="flex justify-between">
          <div class="new-img"><img src="../../assets/supervise_statistic/news.png"></div>
          <div class="flex flex-column justify-around" style="flex-shrink:1">
            <div class="fs16 new-txt">应急管理部举办学习宣传贯彻党的二十届三中全会精神宣讲报告会</div>
            <div class="fs16 new-txt">应急管理部公布一批特种作业领域资格证书涉假典型案例</div>
            <div class="fs16 new-txt">应急管理部举办应急管理“大讲堂”本期主题为“贯彻实施修订后的突发事件应对法提高突发事件应对工作法治...</div>
            <div class="fs16 new-txt">台风“普拉桑”将给华东地区带来强降雨国家防总办公室、应急管理部部署防范叠加灾害风险</div>
            <div class="fs16 new-txt">中蒙边境森林草原火灾联防年度工作会议在内蒙古召开</div>
            <div class="fs16">国新办举行“推动高质量发展”系列应急管理部专场新闻发布会</div>
          </div>
        </div>
      </div>
      <div class="fs30 f-bold text-center mb60 mt60">专业培训平台服务</div>
      <div class="mb60">
        <div v-for="(item,index) in Object.values(SUPERVISE_STATISTIC_COURSE)" :key="index">
          <div class="mb30 fs20 txt-wrap pb10 mt30">
            <span class="txt-wrap-content pb10 f-w500">
              {{ item.txt }}
            </span>
          </div>
          <div class="flex align-center justify-between">
            <div v-for="(children,index_2) in item.children" :key="index_2" class="children-model">
              <div>
                <img :src="require(`../../assets/supervise_statistic/${children.imgUrl}.png`)" style="width: 100%">
              </div>
              <div class="fs20 pl20 pt14">{{ children.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderCheck from '@/components/SliderCheck'
import { removeCustomStorage } from '@/utils/auth'
import { LOGIN_TYPE_GOV, SUPERVISE_STATISTIC_COURSE } from '@/const/sys'

export default {
  name: 'Home',
  components: {
    SliderCheck,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      SUPERVISE_STATISTIC_COURSE,
      passwordType: 'password',
      capsTooltip: false,
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
      },
      activeName: LOGIN_TYPE_GOV.GOVERNMENT.val,
      LOGIN_TYPE_GOV,
      loading: false,
      isRest: false, // 忘记密码弹框
      isSignUp: false, // 注册弹框
      loginStatus: false,
    }
  },
  created() {
    console.log(Object.values(SUPERVISE_STATISTIC_COURSE))
  },
  methods: {
    gogogo() {
      this.$router.push({ path: '/index' })
    },
    handleTabsClick(tab, event) {
      this.activeName = tab.name
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    handleLogin() {
      removeCustomStorage()
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          if (this.loginStatus) {
            this.loading = true
            this.$store.dispatch('user/login', { ...this.loginForm, ...{ userType: this.activeName }})
              .then(() => {
                // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                this.$router.push({ path: '/statistic' })
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            this.$message.warning('请拖动滑块完成验证')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 滑块验证成功回调
    handleSuccessFun() {
      this.loginStatus = true
    },
    // 滑块验证失败回调
    handleErrorFun() {
    },
    // 个人注册
    openRest() {
      this.$message.info('此功能正在开发中。。。')
      return
      this.isSignUp = true
    },
    // 忘记密码
    openRestPassword() {
      this.isRest = true
    },
  },
}
</script>
<style lang="scss">
.new-img {
  width: 570px;
  height: 378px;
}

.txt-wrap-content {
  border-bottom: 3px solid #1C54C2
}

.new-txt {
  padding-bottom: 14px;
  border-bottom: 1px dashed #d9d9d9;
}

.txt-wrap {
  border-bottom: 1px solid #d9d9d9;
}

.children-model {
  width: 432px;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
}

.title-content {
  letter-spacing: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
}

.icon-style {
  color: #7b7b7b;
  //padding-left: 10px;
}

.svg-container {
  display: inline-block;
  padding-left: 20px;
}

.input-bg {
  background-color: #F5F6F8;
  margin-bottom: 30px;
}

.login .el-tabs__item {
  padding: 0 !important;
}

.login .el-tabs__item.is-active {
  color: #0486FF !important;
}

.login .el-tabs__active-bar {
  background-color: #0486FF !important;
}

.login .el-input--medium {
  width: 240px;
  text-align: right;

  .el-input__inner {
    font-size: 14px;
    border: none;
    background-color: transparent;
  }
}

.login-wrap {
  height: 400px;
  background: url("~@/assets/images/banner-1.jpg");
  background-size: 100% 100%;
}

.login {
  width: 360px;
  height: 340px;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.home-center {
  width: 1400px;
  margin: 0 auto;
}

.vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%) !important;
}
</style>


import request from '@/utils/request'

// 更新学生信息
export function saveStudentInfo(data) {
  return request({
    url: '/hbworkersys-biz/student/upStudent',
    method: 'post',
    data,
  })
}

// 更新学生头像
export function updateStudentHeadImg(data) {
  return request({
    url: '/hbworkersys-biz/student/upStudentHeadImg',
    method: 'post',
    data,
  })
}

// 获取学生名下证书
export function getCertificateList(params) {
  return request({
    url: '/hbworkersys-biz/UserCertificate/queryTheCertificateList',
    method: 'get',
    params,
  })
}

// 根据业务线获取证书
export function getCertificateListByBizLine(params) {
  return request({
    url: '/hbworkersys-biz/course/queryCategoryInfo',
    method: 'get',
    params,
  })
}

// 新增证书
export function addCertificate(data) {
  return request({
    url: '/hbworkersys-biz/UserCertificate/AddTheCertificate',
    method: 'post',
    data,
  })
}

// 学员获取已报名课程表格信息
export function queryStudentCourseDetails(data) {
  return request({
    url: '/hbworkersys-biz/record/queryStudentCourseDetails',
    method: 'get',
    params: {
      'bizLine': data.bizLine,
      'expiredType': data.expiredType,
    },
  })
}

// 学生端 获取我的练习考卷列表数据
export function getPaperInfoListByBizLine(params) {
  return request({
    url: '/hbworkersys-biz/exam/getPaperInfoListByBizLine',
    method: 'get',
    params,
  })
}


import request from '@/utils/request'

// 学生端考试模块的接口

// 初始化答题卡
export function createAnswerSheet(data) {
  return request({
    url: `/hbworkersys-biz/exam/createAnswerSheet`,
    method: 'post',
    data,
  })
}

// 测试交卷
export function batchSubmitItem(data) {
  return request({
    url: `/hbworkersys-biz/exam/batchSubmitItem`,
    method: 'post',
    data,
  })
}

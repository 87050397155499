// import Cookies from 'js-cookie'
import { _session } from '@/utils/local'

const TokenKey = 'hgpxUserToken'
const USERINFO = 'hgpxUserInfo'
const USERRIGHT = 'hgpxUserRight'
const RANDOMNUM = 'randomNum'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }
//
// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }
//
// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

export function setToken(token) {
  return _session.set(TokenKey, token)
}

export function getToken() {
  return _session.get(TokenKey)
}

export function removeToken() {
  return _session.remove(TokenKey)
}

export function getUserInfo() {
  return _session.get(USERINFO)
}

export function setUserInfo(data) {
  return _session.set(USERINFO, data)
}

export function removeUserInfo() {
  return _session.remove(USERINFO)
}

export function getUserRight() {
  return _session.get(USERRIGHT)
}

export function setUserRight(data) {
  return _session.set(USERRIGHT, data)
}

export function removeUserRight() {
  return _session.remove(USERRIGHT)
}

export function setRandomNum(data) {
  return _session.set(RANDOMNUM, data)
}

export function getRandomNum() {
  return _session.get(RANDOMNUM)
}

export function removeRandomNum() {
  return _session.remove(RANDOMNUM)
}

// 移除所有自定义缓存信息
export function removeCustomStorage() {
  removeToken()
  removeUserInfo()
  removeUserRight()
  removeRandomNum()
}

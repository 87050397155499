<template>
  <div class="result">
    <div class="header">
      <el-button size="mini" @click="toBack">返回</el-button>
    </div>
    <div class="content">
      <div :class="textResult.result === EXAM.fail.val ? EXAM.fail.val : EXAM.pass.val">{{ textResult.result === EXAM.fail.val ? '很遗憾，测试结果不通过' : '恭喜，您通过了测试！' }}</div>
      <div class="second">
        <div>测试课程：{{ textResult.testName }}</div>
        <div>测试时间：{{ textResult.finishTime }}</div>
        <div>测试分数：{{ textResult.score }}</div>
      </div>
      <ul>
        <li>
          <div class="title">测试详情</div>
          <div class="example">
            <div class="example1">
              <div />
              <div>正确</div>
            </div>
            <div class="example2">
              <div />
              <div>错误</div>
            </div>
            <div class="example3">
              <div />
              <div>未答</div>
            </div>
          </div>
        </li>
        <li>
          <div class="title">单选题</div>
          <ul>
            <li v-for="(item, index) in singleChoiceList" :key="index" :class="item.result_type">{{ index + 1 }}</li>
          </ul>
        </li>
        <li>
          <div class="title">判断</div>
          <ul>
            <li v-for="(item, index) in TrueFalseList" :key="index" :class="item.result_type">{{ index + 1 }}</li>
          </ul>
        </li>
        <li>
          <div class="title">多选题</div>
          <ul>
            <li v-for="(item, index) in MultiChoiceList" :key="index" :class="item.result_type">{{ index + 1 }}</li>
          </ul>
        </li>
        <li>
          <div class="title">案例题</div>
          <ul>
            <li v-for="(item, index) in exampleList" :key="index" :class="item.result_type">{{ index + 1 }}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { QUESTION_TYPE, ANSWER_TYPE, EXAM } from '@/const/sys'
export default {
  name: 'ExaminationResultTab',
  props: {
    // bizLine: {
    //   required: true,
    //   type: String,
    // },
  },
  data() {
    return {
      formData: {},
      // 单选题列表
      singleChoiceList: [],
      // 多选列表
      MultiChoiceList: [],
      // 判断列表
      TrueFalseList: [],
      // 案例题列表
      exampleList: [],
      EXAM: null,
    }
  },
  computed: {
    ...mapState('stuExam', {
      paperNo: state => state.paperNo,
      paperType: state => state.paperType,
      stuNo: state => state.stuNo,
      orgId: state => state.orgId,
      sheetNo: state => state.sheetNo,
      textResult: state => state.textResult,
    }),
  },
  created() {
    this.EXAM = EXAM
    this.textResult.testInfo.forEach(item => {
      if (item.questionType === QUESTION_TYPE.SingleChoice.val) {
        const obj = {}
        if (item.userAnswer === '') {
          obj.result_type = ANSWER_TYPE.noanswer.val
        } else if (item.userAnswer === item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.right.val
        } else if (item.userAnswer !== item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.wrong.val
        }
        this.singleChoiceList.push(obj)
      } else if (item.questionType === QUESTION_TYPE.MultiChoice.val) {
        const obj = {}
        if (item.userAnswer === '') {
          obj.result_type = ANSWER_TYPE.noanswer.val
        } else if (item.userAnswer === item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.right.val
        } else if (item.userAnswer !== item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.wrong.val
        }
        this.MultiChoiceList.push(obj)
      } else if (item.questionType === QUESTION_TYPE.TrueFalse.val) {
        const obj = {}
        if (item.userAnswer === '') {
          obj.result_type = ANSWER_TYPE.noanswer.val
        } else if (item.userAnswer === item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.right.val
        } else if (item.userAnswer !== item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.wrong.val
        }
        this.TrueFalseList.push(obj)
      } else if (item.questionType === QUESTION_TYPE.example.val) {
        const obj = {}
        if (item.userAnswer === '') {
          obj.result_type = ANSWER_TYPE.noanswer.val
        } else if (item.userAnswer === item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.right.val
        } else if (item.userAnswer !== item.expectedAnswer) {
          obj.result_type = ANSWER_TYPE.wrong.val
        }
        this.exampleList.push(obj)
        // obj.children = item.child
      }
    })
  },
  mounted() {
  },
  methods: {
    toBack() {
      this.$emit('toList')
    },
  },
}
</script>

<style lang="scss" scoped>
.result {
  width: 100%;
  .content {
    padding: 20px;
    box-sizing: border-box;
    .fail {
      font-size: 28px;
      color: rgb(232, 22, 22);
    }
    .pass {
      font-size: 28px;
      color: rgb(3, 208, 134);
    }
    .second {
      font-size: 18px;
      display: flex;
      line-height: 50px;
      div {
        margin-left: 20px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    ul {
      li {
        .title {
          font-size: 16px;
          line-height: 30px;
          list-style: none;
        }
        .example {
          width: 100%;
          display: flex;
          .example1 {
            display: flex;
            div {
              &:first-of-type {
                width: 35px;
                height: 35px;
                border: 1px solid #10dd9f;
                background-color: #10dd9f;
                line-height: 35px;
                text-align: center;
                margin-right: 10px;
              }
              &:last-of-type {
                color: #10dd9f;
                line-height: 35px;
              }
            }
          }
          .example2 {
            margin: 0 20px;
            display: flex;
            div {
              &:first-of-type {
                width: 35px;
                height: 35px;
                border: 1px solid #dd1010;
                background-color: #dd1010;
                line-height: 35px;
                text-align: center;
                margin-right: 10px;
              }
              &:last-of-type {
                color: #dd1010;
                line-height: 35px;
              }
            }
          }
          .example3 {
            display: flex;
            div {
              &:first-of-type {
                width: 35px;
                height: 35px;
                border: 1px solid #ccc;
                line-height: 35px;
                text-align: center;
                margin-right: 10px;
              }
              &:last-of-type {
                color: #ccc;
                line-height: 35px;
              }
            }
          }
        }
        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          li {
            width: 35px;
            height: 35px;
            border: 1px solid #cccccc;
            line-height: 35px;
            text-align: center;
            margin: 5px;
            color: #000;
            list-style: none;
            &:hover {
              cursor: pointer;
            }
            &.noanswer {
              color: #000;
              background-color: #fff;
            }
            &.right {
              color: #fff;
              background-color: #10dd9f;
            }
            &.wrong {
              color: #fff;
              background-color: #dd1010;
            }
          }
        }
      }
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isOrg
        ? _c(
            "div",
            {
              staticClass: "flex flex-column justify-center align-center wrap",
            },
            [
              _c("img", {
                staticClass: "img",
                attrs: { src: require("../../assets/images/index-logo.png") },
              }),
              _c("div", { staticClass: "c-7b7b7b fs30 mt30" }, [
                _vm._v("欢迎使用化工培训管理系统"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isOrg
        ? _c(
            "div",
            { staticClass: "org-wrap" },
            _vm._l(_vm.courseObj, function (item, key, index) {
              return _c("div", { key: index, staticClass: "mb12 bgc-white" }, [
                _c("div", { staticClass: "c-181818 fs20 title" }, [
                  _vm._v(_vm._s(_vm.BIZLINE[key].txt)),
                ]),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  _vm._l(item, function (item_2, index_2) {
                    return _c(
                      "div",
                      {
                        key: index_2,
                        staticClass: "flex item pointer",
                        on: {
                          click: function ($event) {
                            return _vm.clickFun(item_2)
                          },
                        },
                      },
                      [
                        _vm._m(0, true),
                        _c("div", { staticClass: "fs14" }, [
                          _vm._v(_vm._s(item_2.courseName)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("AddTrainPlanDialog", {
        attrs: {
          "course-id": _vm.courseId,
          "flag-visible": _vm.dialogShow,
          title: _vm.dialogTitle,
        },
        on: {
          "update:flagVisible": function ($event) {
            _vm.dialogShow = $event
          },
          "update:flag-visible": function ($event) {
            _vm.dialogShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img_wrap flex align-center justify-center" },
      [
        _c("img", {
          attrs: { src: require("../../assets/images/curriculum-icon.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
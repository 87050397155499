var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "header-box flex flex-row align-center justify-between",
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.edit ? "编辑" : "添加外部人员")),
          ]),
          _c("div", { staticClass: "mouse-style", on: { click: _vm.goBack } }, [
            _vm._v("返回"),
          ]),
        ]
      ),
      _c("MyForm", {
        ref: "editForm",
        attrs: {
          "double-row-style": true,
          "edit-cfg": _vm.editForm,
          "edit-data": _vm.editData,
          "edit-rules": _vm.editRules,
          that: _vm.that,
        },
      }),
      _c("div", { staticClass: "buttons" }, [
        _c("div", { staticClass: "buttons-right" }, [
          _c(
            "div",
            { staticClass: "buttons-right-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary" },
                  on: { click: _vm.formValidate },
                },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "我的信息",
        width: "800px",
        visible: _vm.dialogVisible,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
        open: _vm.open,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "0 20px" } },
        [
          _c("h-form", {
            ref: "editForm",
            attrs: {
              that: _vm.that,
              "edit-cfg": _vm.editForm,
              "edit-data": _vm.editData,
              "edit-rules": _vm.editRules,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.saveClick },
            },
            [_vm._v("保 存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
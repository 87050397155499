import FrontLayout from '@/layout/front'

const frontRouter = {
  path: '',
  component: FrontLayout,
  hidden: true,
  children: [
    {
      path: '/index',
      component: () => import('@/views/index'),
      name: 'index',
      meta: { title: '登录' },
    },
    // 监管端登录
    {
      path: '/admin/index',
      name: 'admin/index',
      component: () => import('@/views/admin'),
      hidden: true,
    },
  ],
}

export default frontRouter

import request from '@/utils/request'

// 学生端 获取我的练习考卷列表数据
export function getPaperInfoListByBizLine(params) {
  return request({
    url: '/exam/getPaperInfoListByBizLine',
    method: 'get',
    params,
  })
}

// 初始化答题卡
export function createAnswerSheet(params) {
  return request({
    url: `/exam/createAnswerSheet`,
    method: 'get',
    params,
  })
}

// 测试交卷
export function batchSubmitItem(data) {
  return request({
    url: `/exam/batchSubmitItem`,
    method: 'post',
    data,
  })
}

// 获取证书信息
export function getCertificate(stuNo) {
  return request({
    url: `/record/queryCertificateInfo?stuNo=` + stuNo,
    method: 'get',
  })
}

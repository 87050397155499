<template>
  <Chart
    :id="id"
    :height="height"
    :options="options"
    :width="width"
  />
</template>

<script>
import Chart from '@/components/Charts/common'
import { homeworkViolationsMap } from '@/const/sys'

export default {
  name: 'LineChart',
  components: {
    Chart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cdata: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: '400px',
    },
    height: {
      type: String,
      default: '200px',
    },
  },
  data() {
    return {
      options: {},
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        const { xAxisData, yAxisData, data } = newData
        if (!data) return
        const _yAxis = []
        const _legends = []
        const _series = []
        yAxisData.forEach((item, index) => {
          const _axis = {
            // name: item.name,
            // nameTextStyle: {
            //   padding: [0, 0, 15, -40],
            // },
            type: 'value',
            minInterval: 1, // 只显示整数
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: ['#b2b2b2'],
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#7B7B7B',
                type: 'dashed',
              },
            },
          }
          if (item.max) {
            _axis.max = item.max
          }
          _yAxis.push(_axis)
        })
        data.forEach((item, index) => {
          _legends.push(item.name)
          const _config = {
            name: item.name,
            type: 'line',
            data: item.data,
            animationDuration: 2800,
            smooth: false,
            symbol: 'none',
            animationEasing: 'quadraticOut',
            // yAxisIndex: index, // 双Y轴用的
          }
          if (item.name) {
            const _color = homeworkViolationsMap[item.name].color
            // 如果传入的是颜色key，则获取配置项中的颜色值，否则则读取自定义颜色值
            // if (chart_color[item.color]) {
            //   _color = chart_color[item.color]
            // }
            _config.itemStyle = {
              normal: {
                color: _color,
                lineStyle: {
                  color: _color,
                  width: 2,
                },
              },
            }
          }
          _series.push(_config)
        })
        this.options = {
          xAxis: {
            type: 'category',
            data: xAxisData,
            axisLine: {
              lineStyle: {
                color: ['#7B7B7B'],
                type: 'solid',
              },
            },
            axisLabel: {
              textStyle: {
                color: ['#b2b2b2'],
              },
            },
          },
          yAxis: _yAxis,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
            },
            // transitionDuration: 0.4,
            padding: [5, 10],
            className: 'echarts-tooltip echarts-tooltip-white',
            // formatter: _formatFun,
          },
          legend: {
            icon: 'circle',
            top: 0,
            left: 20,
            data: _legends,
            // itemGap: 30,
            formatter: ['{a|{name}}'].join(),
            textStyle: {
              rich: {
                a: {
                  width: 110,
                  fontSize: 12,
                  lineHeight: 12,
                },
              },
            },
          },
          series: _series,
          grid: {
            top: 70,
            left: 0,
            right: 0,
            bottom: 0,
            containLabel: true,
          },
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style>

</style>

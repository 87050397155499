import request from '@/utils/request'

// 机构端查询培训计划列表
export function orgTrainPlanHubeiQuery(data) {
  return request({
    url: '/trainningPlan/orgOrGovTrainPlanHubeiQuery',
    method: 'post',
    data,
  })
}

// 机构端提交培训计划
export function trainningPlanReport(planId) {
  return request({
    url: '/trainningPlan/comMintPlan?planId=' + planId,
    method: 'post',
  })
}

// 机构端删除培训计划
export function deleteTrainningPlan(planId) {
  return request({
    url: '/trainningPlan/removePlanId?planId=' + planId,
    method: 'post',
  })
}

// 机构端新增培训计划
export function addTrainningPlan(data) {
  return request({
    url: '/trainningPlan/savePlanCourse',
    method: 'post',
    data,
  })
}

// 机构端编辑时获取单条培训计划信息
export function trainningPlanInfo(planId) {
  return request({
    url: '/trainningPlan/selectPlanById',
    method: 'get',
    params: {
      planId,
    },
  })
}

// 机构端修改培训计划
export function upDateTrainningPlan(data) {
  return request({
    url: '/trainningPlan/updateTrainningPlan',
    method: 'post',
    data,
  })
}

// 监管端培训计划审批
export function auditMintPlan(data) {
  return request({
    url: '/trainningPlan/auditMintPlan',
    method: 'post',
    data,
  })
}

// 计划管理 培训计划详情 批量添加学员
export function saveTrainningEnroll(data) {
  return request({
    url: '/trainningenroll/saveTrainningEnrollStuDto',
    method: 'post',
    data,
  })
}

// 获取未分配学员
export function getTrainningUnEnroll(data) {
  return request({
    url: '/plan/training/detail',
    method: 'post',
    data,
  })
}

// 计划管理 培训计划详情 批量移除学员
export function deleteTrainningEnrollIds(data) {
  return request({
    url: '/trainningenroll/removeTrainningEnroll',
    method: 'post',
    data,
  })
}

// 机构端计划管理 查看计划详情
export function getPlanInfo(planId) {
  return request({
    url: `/trainningPlan/selectPlanById`,
    method: 'get',
    params: {
      planId,
    },
  })
}

// 机构端计划管理 查看计划学员信息
export function getListTrainningEnroll(data) {
  return request({
    url: `/trainningenroll/listTrainningEnroll`,
    method: 'post',
    data,
  })
}

// 机构端 添加培训计划 查询培训课程
export function getListTrainningCourse(data) {
  return request({
    url: `/course/queryCourseInfo`,
    method: 'post',
    data,
  })
}


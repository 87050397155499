<template>
  <div id="index">
    <div class="flex align-center justify-between">
      <dv-decoration-8
        :color="[variables.bigScreenBorderPrimarySimple, variables.bigScreenBorderPrimarySimple]"
        style="width:20%;height:50px;"
      />
      <div class="title flex-1" style="margin: 0 3%;position:relative;height: 90px">
        <div class="title-text">{{ title }}</div>
        <div style="position: absolute;top: -18px;left: 0;width: 100%">
          <svg-icon class-name="title-style" icon-class="title" />
        </div>
      </div>
      <dv-decoration-8
        :color="[variables.bigScreenBorderPrimarySimple, variables.bigScreenBorderPrimarySimple]"
        :reverse="true"
        style="width:20%;height:50px;"
      />
    </div>
    <div class="flex justify-end">
      <el-dropdown trigger="hover">
        <div class="pr20">
          <svg-icon class-name="menu-style" icon-class="menu" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="goBackstage"><i class="el-icon-sort rotate-style" />切换后台</el-dropdown-item>
          <el-dropdown-item @click.native="logout"><i class="el-icon-switch-button" />退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'
import { getUserInfo } from '@/utils/auth'
import { ORG_USER_TYPE, USER_TYPE } from '@/const/sys'
// import ChinaJson from '@/const/echart/china'

export default {
  name: 'Header',
  data() {
    return {
      title: '众得应急安全教育专业平台',
      isLogin: false,
    }
  },
  computed: {
    variables() {
      return variables
    },
  },
  created() {
    if (getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_park_admin.val) {
      this.title = getUserInfo().govInfo.name
    }
  },
  methods: {

    // 退出登录
    async logout() {
      const userInfo = getUserInfo()
      await this.$store.dispatch('user/logout')
      if (userInfo.userType === USER_TYPE.government.val) {
        this.$router.push(`/admin/index?redirect=${this.$route.fullPath}`)
      } else {
        this.$router.push(`/?redirect=${this.$route.fullPath}`)
      }
    },
    // 切换后台
    goBackstage() {
      this.$router.replace('/admin/home')
    },
  },
}
</script>

<style lang="scss" scoped>
.title-style {
  width: 100% !important;
  height: 100% !important;
}

.menu-style {
  width: 18px !important;
  height: 14px !important;
}

.rotate-style {
  transform: rotate(90deg);
}

.el-popper {
  background-color: #082D59;
}

.el-dropdown-menu--medium {
  padding: 0;
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
  padding: 0 10px;
}

::v-deep.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #082D59 !important;
}

::v-deep.el-popper .popper__arrow::after {
  border-bottom-color: #082D59 !important;
}

.el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus {
  background-color: #0087CB;
  color: white;
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
  color: white;
}

.el-dropdown-menu {
  border: 1px solid #082D59;
}

#index {
  position: relative;
}

.back {
  //position: absolute;
  //bottom: 10px;
  //right: 10px;
  margin: 5px 10px;
}

.toAdmin {
  //position: absolute;
  //bottom: 10px;
  //right: 20px;
  margin: 5px 10px;
}

.title-wrapper {
  //padding-top: 10px;
}

.title-text {
  color: white;
  top: 10px;
  font-size: 32px;
  font-weight: 700;
  //color: ;
  position: absolute;
  //bottom: 0;
  //margin-right: -108px;
  left: 50%;
  transform: translate(-50%);
}

.title {
  position: relative;
  //width:40%;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;

  .title-bototm {
    position: absolute;
    //bottom: -0.375rem;
    right: 20%;
    //transform: translate(-50%);
  }
}
</style>

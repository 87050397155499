<template>
  <div class="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <h-table
      :is-handle="true"
      :is-index="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
    />
    <addContractor
      :flag-visible.sync="dialogContractor"
      :info="info"
    />
  </div>
</template>

<script>
import HTable from '@/components/MyTable'
import HSearch from '@/components/MySearch'
import { contractorList } from '@/api/company/students'
import addContractor from './components/addContractor'
import moment from '@/utils/moment'

export default {
  name: 'Contractor',
  components: {
    HTable,
    HSearch,
    addContractor,
  },
  data() {
    return {
      info: {},
      that: this,
      tableLoading: false,
      dialogContractor: false,
      searchData: {
        contractorName: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'contractorName',
          placeholder: '请输入承包商名称',
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableCols: [
        {
          label: '承包商',
          prop: 'contractorName',
        },
        {
          label: '建立日期',
          prop: 'createTime',
          formatter: row => row.createTime ? moment(row.createTime).format('YYYY-MM-DD') : '',
        },
        {
          label: '操作列',
          type: 'button',
          btnList: [
            {
              label: '编辑',
              type: 'text',
              prop: 'detail',
              handle: (that, row) => that.addOrEditRecords('dialogContractor', row, true),
            },
          ],
        },
      ],
      tableData: [],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          that.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          that.getTableData()
        },
      },
      tableHandles: [
        {
          label: '添加承包商',
          // icon: 'el-icon-plus',
          // plain: true,
          type: 'primary',
          handle: that => that.addOrEditRecords('dialogContractor'),
        },
      ],
    }
  },
  computed: {},
  created() {
    this.getTableData()
  },
  methods: {
    addOrEditRecords(path, row, type) {
      if (type) {
        this.info = row
        this.info.edit = true
      } else {
        this.info = {}
      }
      this[path] = true
    },
    async getTableData() {
      const query = { ...this.tablePage, ...this.searchData }
      const { pageNum, pageSize } = query
      this.tableLoading = true
      const _data = {
        pageCondition: {
          pageNum,
          pageSize,
        },
        bizCondition: this.searchData,
      }
      const { data } = await contractorList(_data)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 搜索
    searchFormClick() {
      this.tablePage.pageNum = 1
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
  },
}
</script>

<style>

</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-box" }, [
    _vm.textResult.result === _vm.EXAM.pass.val
      ? _c("div", { staticClass: "pass-title" }, [
          _vm._v("恭喜，您的练习结果为通过 "),
          _c("i", {
            staticClass: "el-icon-check",
            staticStyle: { "font-size": "44px" },
          }),
        ])
      : _vm._e(),
    _vm.textResult.result === _vm.EXAM.fail.val
      ? _c("div", { staticClass: "fail-title" }, [
          _vm._v("抱歉，您没有通过考试 "),
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: { "font-size": "44px" },
          }),
        ])
      : _vm._e(),
    _c("div", { staticClass: "score-box" }, [
      _c("div", { staticClass: "score" }, [
        _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
          _vm._v("试卷得分"),
        ]),
        _c("div", [
          _c("span", { staticClass: "score-num" }, [
            _vm._v(_vm._s(_vm.textResult.score)),
          ]),
          _c("span", [_vm._v("分")]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "result" }, [
      _c("div", [
        _vm._m(0),
        _c("span", [_vm._v(_vm._s(_vm.rightList.length) + "题")]),
      ]),
      _c("div", { staticStyle: { margin: "0 100px" } }, [
        _vm._m(1),
        _c("span", [_vm._v(_vm._s(_vm.errorList.length) + "题")]),
      ]),
      _c("div", [
        _c("span", [_vm._v("未答：")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.noAnswerList.length) + "题")]),
      ]),
    ]),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            staticStyle: { width: "15%", "margin-right": "20px" },
            attrs: { round: "", type: "warning" },
            on: { click: _vm.toExit },
          },
          [_vm._v("退出考试")]
        ),
        _vm.paperType === _vm.PAPER_TYPE.practice.val
          ? _c(
              "el-button",
              {
                staticStyle: { width: "15%" },
                attrs: { round: "", type: "primary" },
                on: { click: _vm.toBack },
              },
              [_vm._v(" 重新考试 ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", {
        staticClass: "el-icon-check",
        staticStyle: {
          color: "#e61d1d",
          "font-size": "20px",
          "font-weight": "600",
        },
      }),
      _vm._v("答对："),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", {
        staticClass: "el-icon-close",
        staticStyle: {
          color: "#e61d1d",
          "font-size": "20px",
          "font-weight": "600",
        },
      }),
      _vm._v("答错："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
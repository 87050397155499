import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 企业端 外部学员管理
const contractorManagementRouter = {
  path: '/contractor',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '',
    icon: null,
    roles: [USER_TYPE.organization.val],
  },
  // alwaysShow: true,
  children: [
    {
      path: '',
      component: () => import('@/views/company/contractor'),
      name: 'contractor',
      meta: { title: '承包商管理', icon: null },
    },
  ],
}

export default contractorManagementRouter

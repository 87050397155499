import request from '@/utils/request'
import moment from '@/utils/moment'

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/user/upPassword',
    method: 'post',
    data,
  })
}

// 近半年园区各岗位培训情况
export function jobTraining() {
  return request({
    url: '/statistics/jobTraining',
    method: 'get',
  })
}

// 近半年园区各岗位培训情况
export function trainingNum() {
  return request({
    url: '/statistics/trainingNum',
    method: 'get',
  })
}

// 三级安全培训等完成情况
export function thirdSafetyTrain(data) {
  return request({
    url: '/statistics/thirdSafetyTrain',
    method: 'post',
    data,
  })
}

// 总体情况
export function overviewCnt() {
  return request({
    url: '/statistics/overviewCnt',
    method: 'get',
  })
}

// 培训人员情况
export function studentCnt() {
  return request({
    url: '/statistics/studentCnt',
    method: 'get',
  })
}

// 三类人员培训完成情况
export function jobTrainFinishList(obj) {
  return request({
    url: '/statistics/jobTrainFinishList?endTime=' + obj.endTime + '&startTime=' + obj.startTime,
    method: 'get',
  })
}

// 三类人员培训完成情况
export function trainningPlanTrend(obj) {
  return request({
    url: '/statistics/trainningPlanTrend?endTime=' + moment(obj.endTime).format('YYYY-MM-DD') + '&startTime=' + moment(obj.startTime).format('YYYY-MM-DD'),
    method: 'get',
  })
}

// 获取中心地图数据
export function centerMapInfo() {
  return request({
    url: '/statistics/centerMapInfo',
    method: 'get',
  })
}

// 企业端首页获取所有课程分类
export function listGroupByDicKey() {
  return request({
    url: '/category/listGroupByDicKey',
    method: 'get',
  })
}

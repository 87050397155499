var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "index" } }, [
    _c(
      "div",
      { staticClass: "flex align-center justify-between" },
      [
        _c("dv-decoration-8", {
          staticStyle: { width: "20%", height: "50px" },
          attrs: {
            color: [
              _vm.variables.bigScreenBorderPrimarySimple,
              _vm.variables.bigScreenBorderPrimarySimple,
            ],
          },
        }),
        _c(
          "div",
          {
            staticClass: "title flex-1",
            staticStyle: {
              margin: "0 3%",
              position: "relative",
              height: "90px",
            },
          },
          [
            _c("div", { staticClass: "title-text" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  top: "-18px",
                  left: "0",
                  width: "100%",
                },
              },
              [
                _c("svg-icon", {
                  attrs: { "class-name": "title-style", "icon-class": "title" },
                }),
              ],
              1
            ),
          ]
        ),
        _c("dv-decoration-8", {
          staticStyle: { width: "20%", height: "50px" },
          attrs: {
            color: [
              _vm.variables.bigScreenBorderPrimarySimple,
              _vm.variables.bigScreenBorderPrimarySimple,
            ],
            reverse: true,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex justify-end" },
      [
        _c(
          "el-dropdown",
          { attrs: { trigger: "hover" } },
          [
            _c(
              "div",
              { staticClass: "pr20" },
              [
                _c("svg-icon", {
                  attrs: { "class-name": "menu-style", "icon-class": "menu" },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goBackstage($event)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-sort rotate-style" }),
                    _vm._v("切换后台"),
                  ]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout($event)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-switch-button" }),
                    _vm._v("退出"),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
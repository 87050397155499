var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scale-box", style: _vm.comStyle },
    [_c("Header"), _c("router-view", { key: _vm.key })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
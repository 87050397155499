<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }"></div>
</template>

<script>
import tdTheme from './theme.json' // 引入默认主题
// import resizeMixins from "@/utils/resizeMixins";
// import '../map/fujian.js'

export default {
  name: 'EChart',
  // mixins: [resizeMixins],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '2.5rem',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    options: {
      handler(options) {
        // this.$nextTick(() => {
        //   this.chart.setOption(options, true)
        // })
        this.chart.setOption(options, true)
        // 设置true清空echart缓存
      },
      deep: true,
      // immediate: true,
    },
  },
  mounted() {
    this.$echarts.registerTheme('tdTheme', tdTheme) // 覆盖默认主题
    this.initChart()
  },
  methods: {
    initChart() {
      // 初始化echart
      this.chart = this.$echarts.init(this.$el, 'tdTheme')
      this.chart.setOption(this.options, true)
    },
  },
}
</script>

<style>
</style>

<template>
  <div class="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <h-table
      :is-handle="true"
      :is-index="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
    />
  </div>
</template>

<script>
import HTable from '@/components/MyTable'
import HSearch from '@/components/MySearch'
import Tools from '@/utils/tools'
import { DEP, JOB_POSITION, STU_TYPE } from '@/const/sys'
import { getStudentList } from '@/api/company/students'
import moment from '@/utils/moment'

export default {
  name: 'StudentsList',
  components: {
    HTable,
    HSearch,
  },
  data() {
    const _JOB_POSITION = Tools.mapToSelctList(JOB_POSITION)
    const _DEP = Tools.mapToSelctList(DEP)
    return {
      that: this,
      tableLoading: false,
      searchData: {
        realName: '',
        job: '',
        department: '',
        stuType: STU_TYPE['1'].val,
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          prop: 'realName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'job',
          placeholder: '请选择工作岗位',
          options: _JOB_POSITION,
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'department',
          placeholder: '请选择所属部门',
          options: _DEP,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableCols: [
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '所属部门',
          prop: 'department',
          formatter: row => Tools.formatDict(DEP, row.department),
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        {
          label: '学历',
          prop: 'education',
        },
        {
          label: '建立日期',
          prop: 'createTime',
          formatter: row => row.createTime ? moment(row.createTime).format('YYYY-MM-DD') : '',
        },
        {
          label: '操作列',
          type: 'button',
          btnList: [
            {
              label: '详情',
              type: 'text',
              prop: 'detail',
              handle: (that, row) => that.handleDetail(row),
            },
          ],
        },
      ],
      tableData: [],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          that.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          that.getTableData()
        },
      },
      tableHandles: [
        {
          label: '添加员工',
          // icon: 'el-icon-plus',
          // plain: true,
          type: 'primary',
          handle: that => that.handleAdd(),
          // show: () => getUserInfo().govInfo.orgType === ORG_TYPE.government_city.val,
        },
      ],
    }
  },
  computed: {},
  created() {
    this.getTableData()
  },
  methods: {
    fomatCategory(row) {
      const _names = Tools.listStrToName(row.categoryId, this.categoryParentMap)
      return _names.toString()
    },
    async getTableData() {
      const query = { ...this.tablePage, ...this.searchData }
      const { pageNum, pageSize } = query
      this.tableLoading = true
      const _data = {
        pageCondition: {
          pageNum,
          pageSize,
        },
        bizCondition: this.searchData,
      }
      _data.bizCondition.stuType = STU_TYPE['1'].val
      const { data } = await getStudentList(_data)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    handleAdd() {
      this.$router.push({ path: '/students/add' })
    },
    handleDetail(row) {
      this.$router.push({
        path: '/students/detail',
        query: { stuId: row.stuId },
      })
    },
    // 搜索
    searchFormClick() {
      this.tablePage.pageNum = 1
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
  },
}
</script>

<style>

</style>

<template>
  <div class="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <HTable
      :is-handle="true"
      :is-index="true"
      :is-selection="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
      @selectionChange="selectionChange"
    />
    <OfflineLearningDialog :flag-visible.sync="dialogShow" :stu-info="stuInfo" />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
// 学习详情弹框
import OfflineLearningDialog from '../components/offlineDetail'
import {
  COURSE,
  EXAM_RESULT_STATUS,
  JOB_POSITION,
  ORG_USER_TYPE,
  RATE_LEARNING,
  TRAINNING_TYPE,
  USER_TYPE,
} from '@/const/sys'
import Tools from '@/utils/tools'
import { batchUpdateExamResult, getOrgLearTableData } from '@/api/company/learnning'
import { mapState } from 'vuex'
import { getUserInfo } from '@/utils/auth'

const Interpretor = require('@/utils/interpretor')
const Q = require('q')

export default {
  name: 'OfflineLearningManagementComp',
  components: {
    HSearch,
    HTable,
    OfflineLearningDialog,
  },
  data() {
    const _EXAM_RESULT_STATUS = Tools.mapToSelctList(EXAM_RESULT_STATUS)
    return {
      userInfo: {},
      activeName: 'studying',
      COURSE,
      // 表格选中数据
      tableActiveList: [],
      // 单个学员信息
      stuInfo: {},
      that: this,
      tableLoading: false,
      // 学习详情弹框显示影藏
      dialogShow: false,
      changeUploadShow: false,
      searchData: {
        realName: '',
        phone: '',
        trainningName: '',
        cateName: '',
        testResult: '', // 考试结果
        orgName: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'parkName',
          placeholder: '请输入所属园区',
          isClearable: true,
          isShow: () => getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_province_admin.val,
        },
        {
          type: 'input',
          label: '',
          width: '260px',
          prop: 'orgName',
          placeholder: '请输入所属企业',
          isClearable: true,
          isShow: () => getUserInfo().userType === USER_TYPE.government.val,
        },
        {
          type: 'input',
          label: '',
          prop: 'realName',
          placeholder: '请输入姓名',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'phone',
          placeholder: '请输入手机号',
          isClearable: true,
        },
        {
          type: 'input',
          label: '',
          prop: 'trainningName',
          placeholder: '请输入所属培训计划',
          isClearable: true,
        },
        // {
        //   type: 'cascader',
        //   label: '',
        //   prop: 'learningProcess',
        //   placeholder: '请输入学习进度',
        //   width: '200px',
        //   showAllLevels: true,
        //   props: {multiple: true, expandTrigger: 'hover'},
        //   options: RATE_LEARNING,
        //   isClearable: true,
        //   isCollapseTags: true,
        // },
        {
          type: 'input',
          label: '',
          prop: 'cateName',
          placeholder: '请输入培训课程',
          isClearable: true,
        },
        {
          type: 'select',
          label: '',
          prop: 'testResult',
          width: '260px',
          placeholder: '请选择考试状态',
          options: _EXAM_RESULT_STATUS,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '所属园区',
          prop: 'parkName',
          isShow: () => getUserInfo().govInfo && getUserInfo().govInfo.orgUserType === ORG_USER_TYPE.gov_province_admin.val,
        },
        {
          label: '所属企业',
          prop: 'orgName',
          isShow: () => getUserInfo().userType === USER_TYPE.government.val,
        },
        {
          label: '所属培训计划',
          prop: 'trainningName',
        },
        {
          label: '培训方式',
          formatter: () => '线下培训',
        },
        {
          label: '培训课程',
          prop: 'cateName',
        },
        {
          label: '姓名',
          prop: 'realName',
        },
        {
          label: '手机号',
          prop: 'phone',
        },
        {
          label: '工作岗位',
          prop: 'job',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        // {
        //   label: '学习进度',
        //   prop: 'learningProcess',
        // },
        {
          label: '考试状态',
          prop: 'testResult',
          formatter: row => Tools.formatDict(EXAM_RESULT_STATUS, row.testResult),
        },
        {
          label: '操作列',
          type: 'button',
          btnList: [
            {
              label: '学习详情',
              type: 'text',
              handle: (that, row) => that.openDetail(row),
            },
          ],
        },
      ],
      tablePage: {
        pageSize: 50,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          that.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          that.getTableData()
        },
      },
      tableHandles: [
        {
          label: '批量考试合格',
          type: 'primary',
          handle: () => this.changeExamResultClick(EXAM_RESULT_STATUS.pass.val),
          show: () => this.userInfo.userType === USER_TYPE.organization.val,
        },
        {
          label: '批量考试不合格',
          type: 'primary',
          handle: () => this.changeExamResultClick(EXAM_RESULT_STATUS.fail.val),
          show: () => this.userInfo.userType === USER_TYPE.organization.val,
        },
      ],
    }
  },
  computed: {
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  created() {
    this.userInfo = getUserInfo()
    this.getTableData()
  },
  methods: {
    fomatCategory(row) {
      const _names = Tools.listStrToName(row.categoryChildId, this.categoryAllMap)
      return _names.toString()
    },
    // 获取表格数据
    async getTableData() {
      const query = {
        pageCondition: { ...this.tablePage },
        bizCondition: { ...this.searchData },
      }
      this.tableLoading = true
      // query.bizCondition = this.conversion(query.bizCondition) // conversion数组转化与去重
      query.bizCondition.trainningType = TRAINNING_TYPE.offline.val
      // query.bizCondition.learningProcessList = query.bizCondition.learningProcess
      // delete query.bizCondition.learningProcess
      const { data } = await getOrgLearTableData(query)

      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 多维数组转一维数组并去除指定元素
    conversion(query) {
      if (query.learningProcess && query.learningProcess.length) {
        query.learningProcess = query.learningProcess.toString().split(',').filter(f => {
          return f !== RATE_LEARNING[0].value
        })
      }
      return query
    },
    // 查询按钮
    searchFormClick() {
      this.getTableData()
    },
    // 重置按钮
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 查看详情按钮
    openDetail(row) {
      this.stuInfo = row
      this.dialogShow = true
    },
    // 表格勾选中
    selectionChange(selection) {
      this.tableActiveList = selection
    },

    // 改变考试结果状态
    changeExamResultClick(resVal) {
      const list = this.tableActiveList
      const stuNoList = []
      if (list.length === 0) {
        return this.$alert('请选择考试学员', '提示', {
          confirmButtonText: '确定',
        })
      } else {
        list.forEach((item, index) => {
          stuNoList.push(item.enrollId)
        })
        const statusText = resVal === EXAM_RESULT_STATUS.pass.val ? '合格' : '不合格'
        this.$confirm('是否将' + stuNoList.length + '条记录审批为考试' + statusText + '?', '确认信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async() => {
          const _data = { 'enrollIdList': stuNoList, 'examResult': resVal }
          const { success, data } = await batchUpdateExamResult(_data)
          if (success) {
            this.getTableData()
            this.$message({
              type: 'success',
              message: '修改成功',
            })
          } else {
            this.$message({
              type: 'info',
              message: '修改失败',
            })
            this.$message.error('修改失败')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../components/styles/index";
</style>

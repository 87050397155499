<template>
  <el-dialog
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :title="title"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    @open="open"
  >
    <MyForm
      ref="editForm"
      :edit-cfg="editForm"
      :edit-data="editData"
      :edit-rules="editRules"
      :that="that"
    />
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitBnt">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MyForm from '@/components/MyForm'
import { createExam, examListDetail, papaerListAll, updateExam } from '@/api/company/examPlan'
import Tools from '@/utils/tools'
import moment from '@/utils/moment'

export default {
  name: 'AddPlanDialog',
  components: {
    MyForm,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    planInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      categoryList: [],
      dialogVisible: false,
      that: this,
      editForm: [
        {
          label: '计划名称',
          prop: 'examSessionsName',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          label: '考试开始时间',
          prop: 'examStartTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm:ss',
          placeholder: '请选择日期',
          options: [],
          isClearable: true,
          change: val => this.startTimeChange(val),
        },
        {
          label: '考试结束时间',
          prop: 'examEndTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm:ss',
          placeholder: '请选择日期',
          options: [],
          isClearable: true,
          change: val => this.endTimeChange(val),
        },
        {
          label: '考试试卷',
          prop: 'paperTitle',
          type: 'select',
          options: [],
          placeholder: '请输入考卷名称，至少输入二个字以上',
          isClearable: true,
          filterable: true,
          isRemote: true,
          reserveKeyword: true,
          loading: false,
          loadingText: '加载中',
          remoteMethod: query => this.searchItem(query),
          change: (that, val) => this.selectedItems(that, val),
        },
        {
          label: '考试地点',
          prop: 'examSessionAddr',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
      ],
      editData: {
        paperTitle: '',
        examSessionsName: '',
        examStartTime: '',
        examEndTime: '',
        examSessionAddr: '',
      },
      editRules: {
        examSessionsName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        paperTitle: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        examStartTime: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        examEndTime: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        examSubject: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        examSessionAddr: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      isEdit: false,
      paperList: [],
    }
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
    title: {
      handler(newValue, oldValue) {
        this.title = newValue
      },
    },
  },
  methods: {
    // 获取可选项目列表
    async searchItem(query) {
      const selects = Tools.findSelect(['paperTitle'], this.editForm)
      selects[0].loading = true
      if (query && query.length >= 2) {
        selects[0].loadingText = '加载中'
        this.paperList = []
        const { data } = await papaerListAll(query)
        this.paperList = data
        selects[0].loading = false
        const arr = []
        if (data.length) {
          data.map(item => {
            arr.push({ label: item.paperTitle, value: item.paperId })
          })
          Tools.assignValue(selects, [arr])
        } else {
          Tools.assignValue(selects, [])
        }
      } else {
        selects[0].loadingText = '考卷名称，至少输入二个字以上'
        Tools.assignValue(selects, [])
      }
    },
    // 选择项目
    selectedItems(that, val) {
      const _obj = this.paperList.find(f => {
        return f.paperId === val
      })
      this.editData.paperId = _obj.paperId
      this.editData.paperTitle = _obj.paperTitle
    },
    // 打开弹窗时
    async open() {
      try {
        if (this.planInfo && this.planInfo.examSessionsId) {
          this.isEdit = true
          await this.getPlanInfo()
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 关闭弹窗
    close() {
      this.editData = {}
      this.dialogVisible = false
      this.$emit('update:flagVisible', false)
    },
    // 如果是编辑计划 打开弹框获取当前计划的信息
    async getPlanInfo() {
      const { data } = await examListDetail(this.planInfo.examSessionsId)
      data.examStartTime = moment(data.examStartTime).format('YYYY-MM-DD HH:mm:ss')
      data.examEndTime = moment(data.examEndTime).format('YYYY-MM-DD HH:mm:ss')
      this.editData = data
    },
    // 新增、编辑计划提交按钮
    submitBnt() {
      const _this = this
      const _editData = JSON.parse(JSON.stringify(this.editData))
      _editData.examStartTime = moment(_editData.examStartTime).format('YYYY-MM-DD HH:mm:ss')
      _editData.examEndTime = moment(_editData.examEndTime).format('YYYY-MM-DD HH:mm:ss')
      this.$refs.editForm.$refs.editForm.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            _editData.trainningId = this.planInfo.trainningId
            const { success, data } = await updateExam(_editData)
            _this.$parent.getPlanData()
            _this.$parent.getTableData()
          } else {
            const { success, data } = await createExam(_editData)
            _this.$parent.getTableData()
          }
          this.$message.success('操作成功')
          this.close()
        }
      })
    },
    // 开始时间不晚于结束时间
    startTimeChange(val) {
      if (this.editData.examEndTime) {
        if (new Date(val).getTime() > new Date(this.editData.examEndTime).getTime()) {
          this.$alert('开始时间不可晚于结束时间', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.editData.examStartTime = ''
              return false
            },
          })
        }
      }
    },
    // 结束时间切换 不早于开始时间
    endTimeChange(val) {
      if (this.editData.examStartTime) {
        if (new Date(val).getTime() < new Date(this.editData.examStartTime).getTime()) {
          this.$alert('结束时间不可早于开始时间', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.editData.examEndTime = ''
              return false
            },
          })
        }
      }
    },
  },
}
</script>

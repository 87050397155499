<template>
  <div class="app-container">
    <div class="header-box flex flex-row align-center justify-between">
      <div class="title">详情</div>
      <div class="mouse-style" @click="goBack">返回</div>
    </div>
    <myDetail :list="list" :list-config="listConfig" :list-handles="listHandles" :that="that" />
    <div class="mt12 ml12 mr12 bgc-white br2 pt12 pb12 pl16 pr16">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="培训记录" name="1">
          <HTable
            ref="multipleTable"
            :is-handle="false"
            :is-pagination="isPagination"
            :loading="tableLoading"
            :table-cols="tableColsTrain"
            :table-data="tableDataTrain"
            :that="that"
            class="el_table"
          />
        </el-tab-pane>
        <el-tab-pane label="证书记录" name="2">
          <el-button class="mr10" type="primary" @click="addOrEditRecords('dialogCertificate')">添加</el-button>
          <HTable
            ref="multipleTable"
            :is-handle="true"
            :is-pagination="isPagination"
            :loading="tableLoading"
            :table-cols="tableColsCertificate"
            :table-data="tableDataCertificate"
            :that="that"
            class="el_table"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <addCertificate
      :flag-visible.sync="dialogCertificate"
      :info="info"
    />
  </div>
</template>

<script>
import myDetail from '@/components/MyDetail'
import HTable from '@/components/MyTable'
import { getStudentByIds, getTrainningList, queryTheCertificateList, upStudent } from '@/api/company/students'
import Tools from '@/utils/tools'
import { CERTIFICATE_TYPE, EDUCATION, GENDER, JOB_POSITION, STU_TYPE, TRAINNING_TYPE } from '@/const/sys'
import addCertificate from '../../components/addCertificate'

export default {
  name: 'ExternalStudentsDetail',
  components: {
    myDetail,
    HTable,
    addCertificate,
  },
  data() {
    return {
      info: {},
      dialogCertificate: false,
      tableDataCertificate: [],
      tableColsCertificate: [
        {
          label: '类型',
          prop: 'certificateType',
          formatter: row => Tools.formatDict(CERTIFICATE_TYPE, row.certificateType),
        },
        {
          label: '证书编号',
          prop: 'certificateNo',
        },
        {
          label: '取证时间',
          prop: 'dateOfIssue',
        },
        {
          label: '截至时间',
          prop: 'certificateEffectiveTime',
        },
        {
          label: '操作',
          type: 'button',
          width: '100px',
          btnList: [
            {
              label: '编辑',
              type: 'text',
              handle: (that, row) => that.addOrEditRecords('dialogCertificate', row, true),
            },
          ],
        },
      ],
      tableDataTrain: [],
      tableColsTrain: [
        {
          label: '培训计划名称',
          prop: 'trainningName',
        },
        {
          label: '培训地点',
          prop: 'trainningAddress',
        },
        {
          label: '培训方式',
          prop: 'trainningType',
          formatter: row => Tools.formatDict(TRAINNING_TYPE, row.trainningType),
        },
        {
          label: '培训课程',
          prop: 'cateName',
        },
        {
          label: '报名时间',
          prop: 'planStartTime',
        },
      ],
      tableLoading: false,
      isPagination: false,
      activeName: '1',
      that: this,
      list: {},
      listHandles: [
        {
          label: '编辑',
          type: '',
          classStyle: 'c-0067C0',
          handle: that => that.editsClick(that),
        },
        {
          label: '删除',
          type: '',
          classStyle: 'c-F40606',
          handle: that => that.deleteClick(),
        },
      ],
      listConfig: [
        {
          prop: 'realName',
          placeholder: '姓名',
        },
        {
          prop: 'idCard',
          placeholder: '身份证号',
        },
        {
          prop: 'gender',
          placeholder: '性别',
          formatter: row => Tools.formatDict(GENDER, row.gender),
        },
        {
          prop: 'phone',
          placeholder: '手机号',
        },
        {
          prop: 'job',
          placeholder: '工作岗位',
          formatter: row => Tools.formatDict(JOB_POSITION, row.job),
        },
        {
          prop: 'education',
          placeholder: '学历',
          formatter: row => Tools.formatDict(EDUCATION, row.education),
        },
        {
          prop: 'speciality',
          placeholder: '所学专业',
        },
        {
          prop: 'stuType',
          placeholder: '人员类别',
          formatter: row => Tools.formatDict(STU_TYPE, row.stuType),
        },
        {
          prop: 'organization',
          placeholder: '所属单位',
        },
        {
          type: 'image',
          prop: 'headImg',
          placeholder: '证件照',
        },
        {
          type: 'image',
          prop: 'frontOfIdCard',
          placeholder: '身份证国徽面',
        },
        {
          type: 'image',
          prop: 'obverseOfIdCard',
          placeholder: '身份证人像面',
        },
      ],
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    addOrEditRecords(path, row, type) {
      if (type) {
        this.info = row
        this.info.edit = true
      } else {
        this.info = this.list
      }
      this[path] = true
    },
    handleClick(tab, event) {
      // console.log(this.activeName);
    },
    goBack() {
      this.$router.go(-1)
    },
    async getDetail() {
      const stuId = this.$route.query.stuId
      const { data: _list } = await getStudentByIds(stuId)
      this.list = _list
      const { data: _certificateList } = await queryTheCertificateList(stuId)
      this.tableDataCertificate = _certificateList
      const { data: _trainningList } = await getTrainningList(stuId)
      this.tableDataTrain = _trainningList
    },
    // 编辑
    editsClick() {
      const _stuId = this.$route.query.stuId
      this.$router.push({
        path: '/external/students/add',
        query: { stuId: _stuId },
      })
    },
    // 删除
    deleteClick() {
      this.$confirm('是否进行删除操作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async() => {
          const _info = JSON.parse(JSON.stringify(this.list))
          _info.isDel = 1
          const { success, msg } = await upStudent(_info)
          if (success) {
            this.$message.success('删除成功')
            this.$router.go(-1)
          } else {
            this.$message.error(msg)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom {
  .ces-handle {
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.item-style {
  width: 520px;
  margin-right: 25px;
}

.w-300 {
  width: 120px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.noPermission
      ? _c(
          "div",
          {
            staticClass:
              "flex flex-row align-center justify-center noData_model",
          },
          [
            _c("img", {
              staticClass: "login_img_noData",
              attrs: {
                src: require("../../assets/images/authLogin_noData.png"),
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
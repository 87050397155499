<template>
  <footer>
    <div class="footer-content flex flex-column align-center justify-center flex-1">
      <div class="fs30 pb10">众得应急安全教育专业平台</div>
      <div class="fs14">版权所有：众得应急安全教育专业平台</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
footer {
  background: #1C54C2;
  color: #fff;
  height: 150px;

  .footer-content {
    height: 150px;
    width: 1400px;
    margin: 0 auto;
  }

}
</style>

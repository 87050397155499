var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "destroy-on-close": "",
        title: "修改密码",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
        open: _vm.open,
      },
    },
    [
      _c(
        "div",
        { staticClass: "bgc-white pl44" },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                model: _vm.editForm,
                rules: _vm.editFormRules,
                "label-position": "left",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-row align-center pt20" },
                [
                  _c("div", { staticClass: "w-90" }, [_vm._v("旧密码：")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入旧密码", type: "password" },
                    model: {
                      value: _vm.editForm.oldPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "oldPwd", $$v)
                      },
                      expression: "editForm.oldPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-row align-center pt20" },
                [
                  _c("div", { staticClass: "w-90" }, [_vm._v("新密码：")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "请输入新密码（长度6-10位，不限字符）",
                      type: "password",
                    },
                    model: {
                      value: _vm.editForm.newPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "newPwd", $$v)
                      },
                      expression: "editForm.newPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-row align-center pt20" },
                [
                  _c("div", { staticClass: "w-90" }, [_vm._v("确认新密码：")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "请确认新密码（长度6-10位，不限字符）",
                      type: "password",
                    },
                    model: {
                      value: _vm.editForm.confirmPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "confirmPwd", $$v)
                      },
                      expression: "editForm.confirmPwd",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.subRestClick },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
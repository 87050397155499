<template>
  <Chart
    :id="id"
    :click="click"
    :height="height"
    :options="options"
    :width="width"
  />
</template>

<script>
import Chart from '@/components/Charts/common'

export default {
  name: 'LineChart',
  components: {
    Chart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cdata: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: '500px',
    },
    height: {
      type: String,
      default: '300px',
    },
    click: {
      type: Function,
    },
  },
  data() {
    return {
      options: {},
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        const { data, legendTop, seriesLeft, title, legendRight } = newData
        if (!data) return
        this.options = {
          title: {
            text: title,
            left: 'left',
            textStyle: {
              // 文字颜色
              color: '#f5f5f5',
              // 字体大小
              fontSize: 14,
              top: 0,
            },
            top: '0%',
          },
          tooltip: {
            trigger: 'item',
            confine: true,
            formatter: function(params) {
              const arr = params.name.split(' ')
              return arr[0] + '：' + params.value + '人，占比' + arr[1]
            },
          },
          legend: {
            top: legendTop || '0%',
            right: legendRight || '0%',
            itemHeight: 8,
            itemWidth: 10,
            icon: 'circle',
            itemGap: 8,
            orient: 'vertical',
          },
          series: [{
            type: 'pie',
            center: [seriesLeft || '25%', '60%'],
            radius: ['38%', '58%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '17',
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
          }],
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style>

</style>

<template>
  <el-dialog
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :title="title"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    @open="open"
  >
    <MyForm
      ref="editForm"
      :edit-cfg="editForm"
      :edit-data="editData"
      :edit-rules="editRules"
      :that="that"
    />
    <div slot="footer" class="dialog-footer">
      <el-button class="mr10" @click="close">取消</el-button>
      <el-button type="primary" @click="submitBnt">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MyForm from '@/components/MyForm/index.vue'
import {
  addTrainningPlan,
  getListTrainningCourse,
  trainningPlanInfo,
  upDateTrainningPlan,
} from '@/api/company/trainningPlan'
import { EXAM_ROOM_STATUS, TRAINNING_TYPE, USER_TYPE } from '@/const/sys'
import moment from '@/utils/moment'
import { mapState } from 'vuex'
import Tools from '@/utils/tools'
import { getUserInfo } from '@/utils/auth'

export default {
  name: 'AddTrainPlanDialog',
  components: {
    MyForm,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    courseId: {
      type: Number,
      default: 0,
    },
    planInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    const _TRAINNING_TYPE = Tools.mapToSelctList(TRAINNING_TYPE)
    return {
      categoryList: [],
      dialogVisible: false,
      courseItems: [], // 课程列表
      that: this,
      editForm: [
        {
          label: '培训计划名称',
          prop: 'trainningName',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          type: 'select',
          label: '培训方式',
          prop: 'trainningType',
          placeholder: '请选择',
          options: _TRAINNING_TYPE,
          isClearable: true,
        },
        {
          type: 'select',
          label: '培训课程',
          prop: 'courseId',
          placeholder: '请选择',
          options: [],
          isClearable: true,
        },
        {
          label: '培训开始时间',
          prop: 'startTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm',
          placeholder: '选择日期',
          options: [],
          isClearable: true,
        },
        {
          label: '培训结束时间',
          prop: 'endTime',
          type: 'dateTime',
          format: 'yyyy-MM-dd HH:mm',
          placeholder: '选择日期',
          options: [],
          isClearable: true,
        },
        {
          label: '培训地点',
          prop: 'trainningAddress',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          label: '授课人',
          prop: 'teacher',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
      ],
      editData: {
        trainningName: '',
        trainningType: '',
        courseId: '',
        startTime: '',
        endTime: '',
        trainningAddress: '',
        teacher: '',
      },
      editRules: {
        trainningName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        trainningType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        courseId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        startTime: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        trainningAddress: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        teacher: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      isEdit: false,
    }
  },
  computed: {
    ...mapState('classify', {
      categoryAllMap: state => state.categoryAllMap,
      categoryAllArr: state => state.categoryAllArr,
    }),
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
    title: {
      handler(newValue, oldValue) {
        this.title = newValue
      },
    },
    courseId: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.editData.courseId = newValue
          this.editData.trainningType = TRAINNING_TYPE['online-study'].val
        }
      },
    },
  },
  created() {
    this.getTrainCourseList()
  },
  methods: {
    // 打开弹窗时
    async open() {
      try {
        if (this.planInfo && this.planInfo.planId) {
          this.isEdit = true
          await this.getPlanInfo()
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 关闭弹窗
    close() {
      this.$parent.courseId = 0
      Tools.resetObject(this.editData)
      this.dialogVisible = false
      this.$emit('update:flagVisible', false)
    },
    async getTrainCourseList() {
      const selects = Tools.findSelect(['courseId'], this.editForm)
      const _userInfo = getUserInfo()
      const _whiteList = ['172619477834'] // 演示账号只放开默认课程
      const data = await getListTrainningCourse({ cateStatus: EXAM_ROOM_STATUS.active.val })
      this.courseItems = data.data
      let _data = data.data
      if (_userInfo.userType === USER_TYPE.organization.val && _whiteList.indexOf(_userInfo.orgInfo.orgNo) >= 0) {
        const _d = _data.find(m => m.courseId == 5)
        _data = [_d]
      }
      const options = Tools.formatOpt(
        [_data],
        [{ value: 'courseId', label: 'courseName' }],
      )
      Tools.assignValue(selects, options)
    },
    // 如果是编辑计划 打开弹框获取当前计划的信息
    async getPlanInfo() {
      const { data } = await trainningPlanInfo(this.planInfo.planId)
      data.startTime = moment(data.startTime).format('YYYY-MM-DD HH:mm')
      data.endTime = moment(data.endTime).format('YYYY-MM-DD HH:mm')
      this.editData = data
    },
    // 新增、编辑计划提交按钮
    submitBnt() {
      const _editData = JSON.parse(JSON.stringify(this.editData))
      _editData.startTime = moment(_editData.startTime).format('YYYY-MM-DD HH:mm')
      _editData.endTime = moment(_editData.endTime).format('YYYY-MM-DD HH:mm')
      if (!this.isEdit) {
        this.$refs.editForm.$refs.editForm.validate(async valid => {
          if (valid) {
            _editData.categoryId = this.courseItems.find(({ courseId }) => courseId === this.editData.courseId).categoryId
            const { success, data } = await addTrainningPlan(_editData)
            if (success) {
              this.$message.success(data)
            } else {
              this.$message.warning(data)
            }
            this.close()
            if (!this.courseId) {
              this.$parent.getTableData()
            }
          }
        })
      } else {
        this.$refs.editForm.$refs.editForm.validate(async valid => {
          if (valid) {
            _editData.trainningId = this.planInfo.trainningId
            _editData.categoryId = this.courseItems.find(({ courseId }) => courseId === this.editData.courseId).categoryId
            const { success, data } = await upDateTrainningPlan(_editData)
            if (success) {
              this.$message.success(data)
              this.close()
              this.$parent.getTableData()
              this.$parent.getPlanData()
            } else {
              this.$message.warning(data)
              this.close()
              this.$parent.getTableData()
              this.$parent.getPlanData()
            }
          }
        })
      }
    },
  },
}
</script>

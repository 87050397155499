const Q = require('q')
const _ = require('lodash')

/**
 * 处理递归顺序调用各类函数
 * */
class Interpretor {
  constructor(props) {
    this.processHandler = []
    this.processIndex = 0// 执行的索引
    this.listenHandler // 监听事件
    this.singleHandler
  }

  /**
   * 注册待处理函数
   * @param {Function} handler 整体处理逻辑
   * 可重复调用，则类似于pipeline，将多个处理逻辑依次推入数组。
   */
  registerHandler(handler) {
    if (handler && handler instanceof Function) {
      this.processHandler.push(handler)
    }
  }

  /**
   * 注册单待处理函数，递归执行时，都运行该函数
   * @param {Function} handler 整体处理逻辑
   */
  registerSingleHandler(handler) {
    if (handler && handler instanceof Function) {
      this.singleHandler = handler
    }
  }

  /**
   * 注册监听事件，注册后，每调用一次会执行一次该监听事件
   * @param handler
   */
  registerListenHandler(handler) {
    if (handler && handler instanceof Function) {
      this.listenHandler = handler
    }
  }

  /**
   * 对注册的函数进行递归调用
   * @param {arguments} arguments 允许传入任何数量和类型的参数
   * 将参数应用于已注册好的所有答题卡列表数据处理逻辑
   * 使用pipeline处理模式，前一步的输出为下一步的输入，最终计算出最后的输出
   *
   * 使用数组的reduce方法处理。处理时采用异步方式处理，故会返回Q;
   */
  process() {
    if (_.isArguments(arguments) || _.isArray(arguments)) {
      // 待执行的数据为数组，则进行递归调用
      if (arguments.length == 0) return Q({})// 返回空的q
      const _args = arguments[0] // 第一个参数即为待递归的数组对象
      console.log('开始执行')
      this.processIndex = 0 // 执行前，将processIndex重置为0
      return this.processStart(_args)
    } else {
      // 不是数组，则执行单条语句
      return this.singleHandler(arguments)
    }
  }

  /**
   * 开始递归执行
   * @param item 待执行的参数
   */
  processStart(items) {
    const _items = items
    const _item = _items[this.processIndex]
    return this.singleHandler(_item).then(rst => {
      if (_item instanceof Object) {
        _item.processSuccess = true
        _item.processResult = rst // 将返回的结果附加在每一个对象中
      }
      if (this.listenHandler) {
        this.listenHandler(_item, this.processIndex)
      }
      this.processIndex++
      if (this.processIndex === _items.length) {
        console.log('执行完毕111')
        // 执行完毕，退出递归
        return _items
      } else {
        return this.processStart(_items)
      }
    }).catch(err => {
      if (_item instanceof Object) {
        _item.processSuccess = false
        _item.err = err
      }
      // return _items
      // 处理失败，执行下一条数据
      this.processIndex++
      if (this.processIndex === _items.length) {
        console.log('执行完毕222')
        // 执行完毕，退出递归
        return _items
      } else {
        return this.processStart(_items)
      }
    })
  }
}

module.exports = Interpretor

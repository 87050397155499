var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showPage === "list"
        ? _c("StuCourseList", { on: { goDetailsPage: _vm.goDetailsPage } })
        : _vm._e(),
      _vm.showPage === "details"
        ? _c("StuCourseDetail", {
            on: { goListPage: _vm.goListPage, goVideoPage: _vm.goVideoPage },
          })
        : _vm._e(),
      _vm.showPage === "video"
        ? _c("StuCourseVideo", {
            attrs: { "video-info": _vm.selCourseInfo },
            on: { goDetailsPage: _vm.goDetailsPage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }